/**
 * Este modelo está basado en @grupomarea/bbdd-base
 *
 * Idealmente, en el futuro lo importaremos de ahí. Por el momento
 * no es posible hacerlo dado que dicho paquete no está testeado
 * browser-side.
 *
 * Estos casos de uso son una excelente oportunidad, y un excelente ejemplo,
 * de la necesidad de consolidar un API común para el modelado y la metainfo
 * que implica abstraer las operaciones CRUD, en este caso a través de un
 * driver que ataque a un API web en lugar de una base de datos.
 */

export default class AbstractModel {
  static toString () {
    return `<Model ${this.name}>`
  }

  static get pk () { return this.primary }
  static get primary () { throw new Error('primary should be string || array') }

  static async create () { throw new Error('No implementado') }
  static async update () { throw new Error('No implementado') }
  static async delete () { throw new Error('No implementado') }

  static async findAll () { throw new Error('No implementado') }

  static async findAny (...args) {
    const list = await this.findAll(...args)

    if (list.length < 1) throw new Error('Esperaba al menos 1 resultado')

    return list
  }

  static async findOne (...args) {
    const list = await this.findAll(...args)

    if (list.length !== 1) {
      throw new Error('Esperaba exactamente 1 resultado')
    }

    return list[0]
  }

  constructor (dto = {}) {
    if (typeof dto !== 'object' || dto === null) {
      throw new TypeError('Los modelos deben crearse con objetos no nulos')
    }
    const { primary } = this.constructor

    if (Array.isArray(primary)) {
      for (const key of primary) this[key] = dto[key]
      this[primary.join('@@')] = primary.map(k => this[k]).join('@@')
    } else {
      this[primary] = dto[primary]
    }
  }

  toString () {
    return `<ValueObject ${this.constructor.name}>`
  }

  get pk () {
    return this[this.constructor.primary]
  }
}
