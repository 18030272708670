// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import MuiButton from '@mui/material/Button'
import MuiIconButton from '@mui/material/IconButton'
import MuiButtonGroup from '@mui/material/ButtonGroup'
import MuiToggleButton from '@mui/material/ToggleButton'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import MuiTooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'

/* IMPORTANTE: No añadir iconos aquí. Poco a poco hay que migrarlo */

export { InfoIcon }

export { default as OnIcon } from '@mui/icons-material/ToggleOn'
export { default as OffIcon } from '@mui/icons-material/ToggleOff'
export { default as LeftIcon } from '@mui/icons-material/ChevronLeft'
export { default as RightIcon } from '@mui/icons-material/ChevronRight'
export { default as ClearIcon } from '@mui/icons-material/LayersClear'
export { default as CloseIcon } from '@mui/icons-material/Close'
export { default as HelpIcon } from '@mui/icons-material/HelpOutline'

export { default as DrawIcon } from '@mui/icons-material/Draw'
export { default as EditIcon } from '@mui/icons-material/EditNote'
export { default as PickIcon } from '@mui/icons-material/TouchApp'

export { default as OtherIcon } from '@mui/icons-material/Help'
export { default as CreateIcon } from '@mui/icons-material/AddLocation'
export { default as UpdateIcon } from '@mui/icons-material/RestorePage'
export { default as RemoveIcon } from '@mui/icons-material/Remove'
// Marosa usa para RemoveIcon '@mui/icons-material/DeleteOutline'
export { default as DeleteIcon } from '@mui/icons-material/DeleteForever'
export { default as ExpandIcon } from '@mui/icons-material/PlaylistAdd'
export { default as CollapseIcon } from '@mui/icons-material/ArrowBack'

export { default as DocumentIcon } from '@mui/icons-material/DescriptionOutlined'
export { default as PendingIcon } from '@mui/icons-material/PendingActions'

export { default as AddIcon } from '@mui/icons-material/Add'
// NOTA: Este nombre no está bien
export { ClearOutlinedIcon, ClearOutlinedIcon as DelIcon }
export { default as UploadIcon } from '@mui/icons-material/FileUpload'
export { default as DownloadIcon } from '@mui/icons-material/Download'
export { default as SearchIcon } from '@mui/icons-material/Search'

export { default as FullscreenIcon } from '@mui/icons-material/Fullscreen'
export { default as FullscreenExitIcon } from '@mui/icons-material/FullscreenExit'

export function Button ({ selected, onClick, sx, ...props }) {
  return (
    <MuiButton
      variant={selected ? 'contained' : 'outlined'}
      onClick={selected ? undefined : onClick}
      disableFocusRipple={selected}
      disableRipple={selected}
      sx={
       selected
         ? { pointerEvents: 'none', tabIndex: -1, ...sx }
         : sx
      }
      {...props}
    />
  )
}

export default Button

export function ButtonGroup ({ sx, ...props }) {
  return (
    <MuiButtonGroup
      variant='outlined'
      sx={{
        '& .MuiButtonBase-root[disabled]': { bg: 'red' },
        ...sx
      }}
      {...props}
    />
  )
}

export const IconButton = React.forwardRef(function IconButton (props, ref) {
  return (
    <MuiIconButton ref={ref} {...props} />
  )
})

export function ToggleButton (props) {
  return (
    <MuiToggleButton {...props} />
  )
}

export function ToggleButtonGroup ({ sx, ...props }) {
  return (
    <MuiToggleButtonGroup
      size='large'
      exclusive
      sx={{
        '& button.Mui-selected': { color: 'var(--marea-light)' },
        '& button:not(.Mui-selected)': { color: 'var(--marea-dark)' },
        ...sx
      }}
      {...props}
    />
  )
}

ToggleButtonGroup.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  exclusive: PropTypes.bool
}

export function InfoTooltip ({ children, sx, ...etc }) {
  return (
    <MuiTooltip
      title={children}
      arrow
      placement='top'
    >
      <IconButton
        size='small'
        sx={{ color: 'text', ...sx }}
        {...etc}
      >
        <InfoIcon fontSize='small' />
      </IconButton>
    </MuiTooltip>
  )
}
