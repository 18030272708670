// vim:ts=2:sw=2:et

import React from 'react'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Button from '@mui/material/Button'

// TODO puede ser necesario establecer valor inicial
export const QueryContext = React.createContext(null)

export function useQueryContext () {
  return React.useContext(QueryContext)
}

export function QueryProvider ({ query, children }) {
  return (
    <QueryContext.Provider value={query}>
      {children}
    </QueryContext.Provider>
  )
}

export function QueryError (props) {
  const {
    sx,
    title, // = 'Error al acceder a los datos',
    query: { isError, refetch, retry, reset, ...etc },
    children
  } = props

  if (!isError) return null

  if (refetch || retry || !reset) {
    // suponemos un useQuery
    return (
      <Alert
        severity='error'
        action={
          (refetch || retry)
            ? (
              <Button color='inherit' size='small' onClick={refetch || retry}>
                Reintentar
              </Button>
              )
            : undefined
        }
        sx={sx}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children || (
          title
            ? etc.error.message
            : <AlertTitle>{etc.error.message}</AlertTitle>
        )}
      </Alert>
    )
  }

  // suponemos un useMutation
  const { error } = etc

  return (
    <Alert
      severity={error.severity || 'error'}
      onClose={() => reset()}
      sx={sx}
    >
      {(title || children || error.body || error.title) && (
        <AlertTitle>{error.title || title || error.message}</AlertTitle>
      )}
      {error.status !== 422 && (
        children || error.body || error.message
      )}
      {error.status === 422 && (error.body || error.message)}
      {error.status === 422 && error.errors && (
        <dl>
          {error.errors.map((item) => {
            const { message, instancePath, schemaPath, params } = item
            if (!instancePath) {
              if (/required/.test(schemaPath)) {
                return (
                  <React.Fragment key={message}>
                    <dt>
                      {capitalize(params.missingProperty).replace('_', ' ')}:
                    </dt>
                    <dd>Este campo es obligatorio</dd>
                  </React.Fragment>
                )
              }
            }
            const name = instancePath ? instancePath.split('/').pop() : instancePath
            return (
              <React.Fragment key={message}>
                <dt>{capitalize(name.split('_').join(' '))}:</dt>
                <dd>{capitalize(message)}</dd>
              </React.Fragment>
            )
          })}
        </dl>
      )}
    </Alert>
  )
}

const capitalize = (str) => str.charAt(0).toLocaleUpperCase() + str.slice(1)
