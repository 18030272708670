import { assertDTO } from './format/XML2JSONFormat'

import LayerGroup from 'ol/layer/Group'
import TileLayer from 'ol/layer/Tile'
// import ImageLayer from 'ol/layer/Image'
import { Vector as VectorLayer } from 'ol/layer'

// import WMSCapabilities from 'ol/format/WMSCapabilities'
// import WMTSCapabilities from 'ol/format/WMTSCapabilities'

import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM'
import TileWMS from 'ol/source/TileWMS'
// import ImageWMS from 'ol/source/ImageWMS'
// import VectorTileSource from 'ol/source/VectorTile'
// import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS'

// import { buffer } from 'ol/extent'
import { transformExtent /* , getPointResolution */ } from 'ol/proj'

import { createStyleFromSLD, STYLES } from './style'

import {
  getMinZoomForMaxScale,
  getMaxZoomForMinScale
} from './scale'

import { createWFS as createWFSSource } from './source'

const assertArray = (thing) => {
  if (!Array.isArray(thing)) {
    console.error('Se esperaba un array', { 'se obtuvo': thing })
    throw new TypeError('Assertion Error: Se esperaba un array')
  }
}

function initLayerOptions (layer, conf) {
  assertDTO(layer)
  assertDTO(conf)

  const { EPSG, SCALES, MAX_ZOOM, MIN_ZOOM } = conf

  // Opciones comunes a cualquier capa
  const options = {
    title: layer.title,
    visible: layer.visible,
    opacity: layer.opacity,
    className: layer.name,
    properties: {
      pk: layer.pk,
      name: layer.name,
      isWFS: layer.isWFS,
      isGroup: layer.isGroup,
      projectUrl: layer.projectUrl,
      isQueryable: layer.isQueryable,
      description: layer.description,
      geometryType: layer.geometryType
      // ...layer
    }
  }

  if (typeof layer.bbox !== 'undefined') {
    // cuidado: options.extent marca un corte de renderizado y no se puede
    // indicar sin tener en cuenta "cuanto ocupan" los estilos
    options.properties.bbox = transformExtent(
      layer.bbox.extent, layer.bbox.crs, EPSG
    )
  }

  if (typeof layer.maxScale === 'number') {
    options.minZoom = getMinZoomForMaxScale(layer.maxScale, MIN_ZOOM, SCALES)
  }

  if (typeof layer.minScale === 'number') {
    options.maxZoom = getMaxZoomForMinScale(layer.minScale, MAX_ZOOM, SCALES)
  }

  /*
  if (options.minZoom || options.maxZoom) {
    console.info('Límites de zoom para', layer.name, {
      minimo: options.minZoom,
      maximo: options.maxZoom
    })
  }
  */

  return options
}

function calculateDrawOrder (order, layers, layerStyles) {
  // Generamos una copia para no alterar el array original
  order = order.slice(0)

  layers.filter(layer => layer.isGroup).forEach(group => {
    // Por convención, ocultar el grupo si el nombre acaba en guión bajo
    if (group.name.slice(-1) === '_') {
      // console.info('Ocultar el grupo', group.name)
      return // null
    }

    const _layers = group.layers.filter(
      layer => layer.isGroup || layer.geometryType !== 'NoGeometry'
    )

    if (!_layers.length) return // null

    const gName = group.name

    let first = -1
    let firstLayer = _layers[0]
    while (firstLayer.isGroup) {
      firstLayer = firstLayer.layers[0]
    }
    first = order.indexOf(firstLayer.name)

    let last = -1
    let lastLayer = _layers[_layers.length - 1]
    while (lastLayer.isGroup) {
      lastLayer = lastLayer.layers[lastLayer.layers.length - 1]
    }
    last = order.indexOf(lastLayer.name)

    if (first < 0 || last < 0) {
      console.warn('Problema al determinar la posición de', gName, {
        order, first, last, firstLayer, lastLayer
      })
      return // null
    }

    // OJO: Aquí mutamos el array "order" (splice)
    group.order = order.splice(last, first - last + 1, gName)

    group.layerStyles = group.order.reduce((acc, layerName) => {
      return { ...acc, [layerName]: layerStyles[layerName] }
    }, {})
  })

  return order
}

export async function createLayers (dto, conf) {
  assertDTO(dto)
  assertArray(dto.order)
  assertArray(dto.layers)
  assertDTO(dto.layerStyles)
  assertDTO(conf)
  assertDTO(conf.clusterize)
  assertArray(conf.decluttered)

  const order = calculateDrawOrder(dto.order, dto.layers, dto.layerStyles)

  return (
    await Promise.all(
      order.map((name) => {
        const layer = dto.layers.find(l => l.name === name)

        if (!layer) {
          console.warn('Falta la capa', { name, layers: dto.layers, dto })
          return null
        }

        const _conf = { ...conf }

        if (!layer.isGroup) {
          _conf.declutter = conf.decluttered.includes(name)

          if (conf.clusterize[name]) {
            _conf.clusterizer = conf.clusterize[name]
          }
        }

        return createLayerFromQGIS(layer, dto.layerStyles[layer.name], _conf)
      })
    )
  ).filter(layer => layer !== null)
}

export async function createLayerGroup (group, options, conf) {
  options.layers = await createLayers(group, conf)
  options.properties.mutuallyExclusive = group.mutuallyExclusive

  // https://openlayers.org/en/latest/apidoc/module-ol_layer_Group-LayerGroup.html
  return new LayerGroup(options)
}

// TODO el nombre de esta función da asco y verguenza
export async function createLayerFromQGIS (layer, styles, conf) {
  const options = initLayerOptions(layer, conf)

  if (layer.isGroup) {
    return await createLayerGroup(layer, options, conf)
  }

  if (layer.isBase) options.type = 'base'

  if (!layer.isWFS) {
    const { bus } = conf
    return await createNonWFSLayer(layer, options, bus)
  }

  return await createWFSLayer(layer, options, styles, conf)
}

async function createWFSLayer (layer, options, styles, conf) {
  assertDTO(layer)
  assertDTO(options)
  assertDTO(styles)

  // console.warn('WFS layer', { layer, options, styles })

  const { name, styles: _styles } = layer
  const { bus } = conf

  let sld = null

  if (_styles.length) {
    const sty = _styles[0].name
    sld = styles.find(sld => sld.name === sty)
  }

  if (!sld) {
    console.warn('Falta el estilo de la capa', {
      capa: layer,
      estilos: styles
    })
    options.style = STYLES.missing
  } else {
    layer.fields = options.properties.fields = `geometry,${layer.pk}`

    const [style, fields] = await createStyleFromSLD(sld, conf)

    if (fields.length) {
      // console.info('Campos necesarios para', name, fields.join(','))
      layer.fields = options.properties.fields += `,${fields.join(',')}`
    }

    options.style = style
  }

  const source = options.source = createWFSSource(layer, {
    overlaps: conf.overlaps(layer.name),
    firezneCompat: !!conf.firezneCompat
    // TODO strategy
  })

  const loadStart = () => bus.emit('source:loadstart', source)
  const loadEnd = () => bus.emit('source:loadend', source)
  const loadError = () => bus.emit('source:loaderror', source)

  source.on('featuresloadstart', loadStart)
  source.on('featuresloadend', loadEnd)
  source.on('featuresloaderror', loadError)

  if (conf.declutter) {
    console.info('Usar declutter para la capa', name)
    options.declutter = true
    // TODO existe la posibilidad de garantizar dibujos en capas con declutter,
    // usando style.geometry
    // Ver https://gis.stackexchange.com/a/351114/181317
  }

  if (conf.clusterizer) {
    console.info('Usar clusterizer para la capa', name)
    options.properties.clusterized = true

    const { style } = options
    const { clusterizer } = conf

    clusterizer.setLayerSLD(sld)

    const cluster = options.source = clusterizer.createCluster(source)

    options.style = (feature, resolution) => {
      const features = feature.get('features')

      clusterizer.calculateInfo(resolution, cluster.getFeatures())

      if (features.length === 1) {
        if (typeof style === 'function') {
          const originalFeature = feature.get('features')[0]
          return style(originalFeature, resolution)
        }
        return style
      }

      return clusterizer.createStyle(feature, resolution)
    }
  } else {
    // TODO el refresco da problemas con los clusters

    // console.info('Register source', source.constructor.name)
    const max = bus.getMaxListeners()
    if (bus.listenerCount('source:refresh') === max) {
      if (max > 29) {
        // NOTA @lorenzogrv: NUNCA desactivar este warning
        console.warn('Se aumenta el límite de listeners del bus a', max + 1)
      }
      bus.setMaxListeners(max + 1)
    }
    bus.on('source:refresh', () => source.refresh())
  }

  /*
   * Esto fué un intento de bufferear el extent de capa con el tamaño de los
   * estilos que tuve que dejar por falta de tiempo
  if (options.extent) {
    const pad = sld
      ? Math.max(...sld.opts.map(({ size, opts }) => Math.max(
        size || 0, opts.width || 0, opts.radius || 0, opts.radius2 || 0
      )))
      : 0
    if (pad) {
      const { extent, properties: { name } } = options
      const { EPSG, MIN_ZOOM, UNIT, SCALES } = conf
      // Hay que aplicar un buffer en "unidad del SRC",
      // y el "pad" lo tenemos en px
      // Esta conversión me la invento para salir del paso
      console.log('CAPA', name, {
        extent,
        MIN_ZOOM,
        leftBotRes: getPointResolution(EPSG, SCALES[MIN_ZOOM], extent.slice(0, 2), UNIT) / 4
      })
      options.properties.bbox = options.extent.slice(0)
      options.extent = buffer(
        options.extent, pad * 100000 / (MIN_ZOOM || 1) // conf.SCALES[conf.MIN_ZOOM] * pad
      )
    }
  } */

  return new VectorLayer(options)
}

const OSM_TILE_RE = /tile\.openstreetmap\.org/

async function createNonWFSLayer (layer, options, bus) {
  assertDTO(layer)
  assertDTO(options)

  // console.warn('non-WFS layer', { layer, options })

  if (layer.source === null) {
    console.info('No se dibujará la capa', layer.name, {
      motivo: 'La capa no tiene source',
      ux: 'Comprobar si está publicada por WFS o publicar el origen WMS'
    })
    return null
  }

  // TODO fetchCapabilities()

  if (OSM_TILE_RE.test(layer.source.url)) {
    options.source = new OSM()
  } else {
    switch (layer.source.tipo) {
      case 'WMTS':
        if (layer.source.type === 'xyz') {
          // console.warn('XYZ source', layer.source)
          options.source = new XYZ({
            url: layer.source.url,
            zmax: layer.source.zmax,
            zmin: layer.source.zmin,
            wrapX: false,
            projection: layer.source.crs,
            attributions: layer.attributions || layer.source.referer
          })
          break
        }
        console.log('WMTS non-xyz source', layer.source)
        console.log({
          attributions: layer.source.referer,
          zmax: layer.source.zmax,
          zmin: layer.source.zmin
        })
        break
      case 'WMS':
        // console.log('WMS source', layer.source)
        // options.projection = layer.source.crs
        options.source = new TileWMS({
          url: `${layer.source.url}?SERVICE=WMS&`,
          crossOrigin: 'anonymous',
          attributions: layer.attributions || layer.source.referer,
          projection: layer.source.crs,
          params: {
            // VERSION: '1.3.0',
            LAYERS: layer.source.layers,
            FORMAT: layer.source.format,
            TILED: true,
            CRS: layer.source.crs
          }
        })
        break
      default:
    }
  }

  const { source } = options

  if (!source) {
    console.warn('Falta el source de la capa', { layer, options })
    return null
  }

  // console.info('Register source', source.constructor.name)

  const loadStart = () => bus.emit('source:loadstart', source)
  const loadEnd = () => bus.emit('source:loadend', source)
  const loadError = () => bus.emit('source:loaderror', source)

  source.on('imageloadstart', loadStart)
  source.on('imageloadend', loadEnd)
  source.on('imageloaderror', loadError)

  source.on('tileloadstart', loadStart)
  source.on('tileloadend', loadEnd)
  source.on('tileloaderror', loadError)

  return new TileLayer(options)
}

/*
 * TODO Ejemplo de WMTS
  fetchCapabilities(IGNPNOA, WMTSparser).then(caps => {
    // https://openlayers.org/en/latest/apidoc/module-ol_source_WMTS.html#.optionsFromCapabilities
    // Teóricamente, este método lanza un error si no es compatible la PROYEC.
    const sourceOptions = optionsFromCapabilities(caps, {
      layer: 'OI.OrthoimageCoverage',
      format: 'image/png',
      matrixSet: EPSG_CODE
    })

    // console.log('opcionesAutomáticas', sourceOptions)

    const layer = new TileLayer({
      title: 'WMTS IGN PNOA',
      // type: 'base',
      opacity: 0.95,
      visible: false,
      // extent: getProjection(EPSG_CODE).getExtent(),
      source: new WMTS({
        ...sourceOptions,
        projection: EPSG_CODE,
        attributions: '© IGN España - CC BY 4.0'
      })
    })

    return layer
  }),
  */
