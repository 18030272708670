// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import Slide from '@mui/material/Slide'

import { ViewerContainer } from '../UtilsLayout'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/*
 * Este es el FeatureViewer por defecto para el "Modo Kiosko" (Modal)
 */
export default function ModalFeatureViewer ({
  // Las props que siguen son comunes a todos los FeatureViewer
  ViewerComponent,
  TitleComponent,
  loading,
  feature,
  // Las props que siguen son específicas de este FeatureViewer
  open,
  onClose,
  onTransitionEnd,
  children, // botones de acción
  ...props
}) {
  // TODO actualmente desmontamos a machete al recibir un false y no se muestra
  // bien la animación de salida
  return (
    <Dialog
      scroll='body'
      maxWidth='xl'
      fullWidth
      open={!!feature && open}
      // onClose={event, reason) => { reason !== 'backdropClick' && onClose() }
      keepMounted
      closeAfterTransition
      TransitionComponent={Transition}
      TransitionProps={{
        // mountOnEnter: true,
        // unmountOnExit: true,
        onExited: (node) => {
          onTransitionEnd && onTransitionEnd()
        }
      }}
    >
      {
        // <>
        //   <DialogTitle style={{ textAlign: 'center' }}>
        //     Cargando datos
        //   </DialogTitle>
        // </>
        TitleComponent === null
          ? <div style={{ height: '16px' }} />
          : (
            <DialogTitle style={{ textAlign: 'center' }}>
              <TitleComponent />
            </DialogTitle>
            )
      }
      <ViewerComponent {...props} />
      <ViewerContainer maxWidth='xl'>
        <DialogActions>
          {children}
        </DialogActions>
      </ViewerContainer>
    </Dialog>
  )
}

ModalFeatureViewer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTransitionEnd: PropTypes.func
}
