// vim:ts=2:sw=2:et

import React from 'react'

import ViewSwitchToolBar from '@grupomarea/roo-www-abcs/ViewSwitch'
// import TitleToolbar from '@grupomarea/roo-www-abcs/TitleToolbar'
import {
  // Skeleton
  Stack,
  VerticalStack,
  ViewerContainer
} from '@grupomarea/roo-www-abcs/UtilsLayout'

/**
 * Lo independicé para no enmierdar App.
 *
 * Empiezo a pensar que si es lo suficiente genérico,
 * debería estar en hapuna-www-base
 */
export default function CustomPanelViewer ({
  MenuComponent,
  TitleComponent,
  ViewerComponent,
  views: _views,
  viewsFilter,
  codename,
  bus,
  user,
  ViewComponent,
  handleChangeView,
  view,
  ...props
}) {
  const views = React.useMemo(() => {
    return (user.isSudo || !viewsFilter)
      ? _views
      : _views.filter(v => viewsFilter(v, user, bus))
  }, [user, _views, viewsFilter])

  return (
    <VerticalStack>
      <ViewerContainer>
        <ViewSwitchToolBar
          sx={{ my: '3.25px' }} // Este ajuste alinea con la AppBar principal
          views={views}
          view={view}
          onSelect={handleChangeView}
        >
          <span> {/* style={{ flexBasis: 'min-content' }} */}
            <TitleComponent />
          </span>
        </ViewSwitchToolBar>
        <hr
          style={{
            margin: '.3rem', color: 'transparent', borderColor: 'transparent'
          }}
        />
        <Stack
          direction='column'
          spacing={2}
          sx={{
            py: 1,
            alignItems: 'start',
            justifyContent: 'flex-end'
          }}
        >
          <MenuComponent
            view={view}
            setView={handleChangeView}
          />
        </Stack>
      </ViewerContainer>
      <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        {!props.loading && (
          <ViewerComponent
            view={view}
            Vista={ViewComponent}
            bus={bus}
            {...props}
          />
        )}
      </div>
    </VerticalStack>
  )
}
