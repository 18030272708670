// vim:ts=2:sw=2:et

import Skeleton from '@mui/material/Skeleton'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputAdornment from '@mui/material/InputAdornment'

import { GridContainer, GridItem } from './UtilsLayout'

export function FieldAlone ({
  skeleton,
  readOnly,
  value,
  select,
  onChange,
  ...props
}) {
  if (skeleton) {
    return (
      <Skeleton width='100%'>
        <FieldAlone {...props} />
      </Skeleton>
    )
  }
  const handleChange = (event, ...etc) => {
    // Debido al modo en que manejamos el estado a veces,
    // necesitamos este workaround para los acentos
    if (event.nativeEvent?.data === '´') return
    onChange(event, ...etc)
  }

  // Esto es workaround por el modo en que manejamos el estado
  const valProp = select ? { value } : { defaultValue: value || '' }

  return (
    <TextField
      size='small'
      margin='dense'
      variant='standard'
      fullWidth
      // Esto es workaround por el modo en que manejamos el estado
      select={select}
      {...valProp}
      onChange={handleChange}
      // helperText={props.helperText || ' '}
      {...props}
    />
  )
}

export function FieldContainer (props) {
  return (
    <GridContainer
      sx={{ pt: 2 }}
      rowSpacing={2}
      columnSpacing={1}
      {...props}
    />
  )
}

export { FieldContainer as FieldGrid }

export function FieldLike ({
  // sx,
  xs = true,
  // sm,
  // md,
  // lg,
  // xl,
  ...etc
}) {
  return (
    <GridItem xs={xs} {...etc} />
  )
}

export function Field ({
  // Específico de nuestro wrapper
  FieldComponent = FieldAlone,
  // Se reenvía al GridItem y al FieldComponent
  className,
  // Se reenvía únicamente al FieldLike/GridItem
  sx,
  xs = true,
  sm,
  md,
  lg,
  xl,
  // El resto se reenvían al FieldComponent
  ...other
}) {
  return (
    <FieldLike className={className} {...{ sx, xs, sm, md, lg, xl }}>
      <FieldComponent className={className} {...other} />
    </FieldLike>
  )
}

export function Option (props) {
  return <MenuItem {...props} />
}

export default Field

export function Adornment (props) {
  return <InputAdornment position='end' {...props} />
}
