import VectorSource from 'ol/source/Vector'

import { computeQueryString } from '../www'

/**
 * Wrapper de ol/source/Vector para implementar las especifidades relativas al
 * servicio WFS 1.x de qgis-server
 */
export default class QGISWFSSource extends VectorSource {
  constructor (qgsLayer, ...args) {
    super(...args)

    this.setProperties({
      qgsLayer,
      queryParams: {
        ...(qgsLayer.projectMap ? { map: qgsLayer.projectMap } : {}),
        service: 'WFS',
        version: '1.1.0', // QGIS aún no implementó ni 1.2 ni 2.0
        request: 'GetFeature',
        typename: qgsLayer.name,
        propertyname: qgsLayer.fields,
        outputFormat: 'application/vnd.geo+json',
        maxFeatures: 6000,
        startIndex: 0
      }
    }, true) // true indica "silent" (no emitir eventos de cambio)
  }

  setUrlParams (params = {}) {
    this.set('queryParams', {
      ...this.get('queryParams'),
      ...params
    })
  }

  getQgsUrl (extent, resolution, projection, offset) {
    const epsg = projection.getCode() // 'EPSG:example'

    const { projectUrl } = this.get('qgsLayer')

    return new URL(
      '?' + computeQueryString({
        ...Object.fromEntries(projectUrl.searchParams.entries()),
        ...this.get('queryParams'),
        startIndex: offset,
        // TIP: Antes de la v3.34, qgis-server ignoraba el SRS
        srsname: epsg,
        ...(
          // TIP: qgis-server resulta lentísimo al filtrar bbox
          // OJO: cuando no se definen adecuadamente índices en bbdd
          extent.includes(Infinity)
            ? {}
            : { bbox: `${extent.join(',')},${epsg}` }
        )
      }),
      projectUrl
    )
  }
}
