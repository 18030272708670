// vim:ts=2:sw=2:et

import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import RooTypes from '../prop-types.js'

// import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

import { ViewerContainer, Skeleton } from '../UtilsLayout'

import {
  DefaultFeatureProvider,
  DefaultFeatureViewer,
  DefaultFeatureTitle
} from './DefaultComponents'

import ModalFeatureView from './Modal'
import { useBus } from '@grupomarea/roo-www-abcs/bus-hooks'
import { useQueryClient } from '@tanstack/react-query'
import {
  listWFS3CollectionQueryKey, useWFS3Collection
} from '../service/wfs3-hooks-query'
import { QueryError } from '../UtilsQuery.jsx'

export { ViewerContainer }

export default function FeatureView ({
  Component = ModalFeatureView,
  fallback = (
    <ViewerContainer>
      <LinearProgress sx={{ mt: 10, mb: 10 }} />
    </ViewerContainer>
  ),
  children,
  ...props
}) {
  const { ProviderComponent, inject, ...components } = useFeatureComponents({
    fallback,
    ...props
  })

  // console.warn('Render FeatureView', inject)
  /* if (inject.loading) {
    console.log('TODO: El fallback no debe usarse para esto (usar skeleton)')
    return fallback // <Component skeleton />
  } */

  return (
    <ProviderComponent {...components} {...inject}>
      <Component {...props} {...components} {...inject}>
        {children}
      </Component>
    </ProviderComponent>
  )
}

FeatureView.propTypes = {
  Component: PropTypes.elementType,
  toCodename: PropTypes.func,
  getIdentifier: PropTypes.func,
  featureTypes: RooTypes.featureTypes,
  details: RooTypes.selectionDetailsOptionalFeature,
  fallback: PropTypes.node
}

/**
 * Esta es la función que se usa por defecto para convertir
 * el nombre de una capa (feature type) al "codename"
 */
export function toPascalCase (snakecase) {
  return snakecase.split('_').map(
    str => str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
  ).join('')
}

/**
 * Esta función obtiene el id de un feature a partir de su clave primaria
 * @param {string|string[]} pk: clave primaria
 * @param {object} feature
 * @returns {any} valor de la clave primaria para el feature dado
 */
export function getFeatureId (pk, feature) {
  // OJO con las capas que tienen claves primarias multi-field
  // qgis joinea los campos utilizando "@@"
  return Array.isArray(pk)
    ? pk.map(prop => feature[prop]).join('@@')
    : feature[pk]
}

/**
 * Esta lógica está diseñada para poder montar un FeatureView completamente
 * diferente, pero en principio no debería ser necesario hacerlo
 */
export function useFeatureComponents ({
  featureTypes = {},
  toCodename = toPascalCase,
  getIdentifier = getFeatureId,
  details,
  fallback
}) {
  const bus = useBus()
  const client = useQueryClient()
  const {
    // Las claves que siguen están "prohibidas" (se "tunean")
    Title, Viewer, Provider, Menu,
    // El resto de claves están permitidas
    ...etc
  } = React.useMemo(() => {
    const cname = toCodename(details.layer.name)
    const type = featureTypes[cname] || {}

    if (!featureTypes[cname]) {
      console.warn('El tipo', cname, 'no está definido', {
        'tipos definidos': Object.keys(featureTypes)
      })
    }

    return {
      ...type,
      Provider: type?.MenuProvider || DefaultFeatureProvider,
      Viewer: type?.Viewer || DefaultFeatureViewer,
      Title: type?.Titler || DefaultFeatureTitle,
      views: type?.views || [],
      Model: type?.Model || details.layer.name,
      codename: cname,
      fid: details.feature
        ? getIdentifier(details.layer.pk, details.feature)
        : null
    }
  }, [featureTypes, toCodename, getIdentifier, details])

  const filters = {
    ...(typeof etc.Model === 'string' ? { map: details.map } : {}),
    limit: 1,
    [details.layer.pk]: etc.fid,
    ...(
      // El filtro configurable añade condiciones, pero no substituye
      // OJO: Es absurdo invocarlo cuando no hay feature seleccionado
      details.feature && details.filter
        ? details.filter(details.feature, details.layer)
        : {}
    )
  }
  // TODO this.setFeatureLoading(true) tiene sentido?
  const query = useWFS3Collection(etc.Model, filters, {
    // suspense: true,
    // useErrorBoundary: false,
    // TODO → recibir opciones adicionales
    // TODO → En realidad debemos poder pinchar un loader distinto
    enabled: !!etc.fid,
    select: data => data.list[0] || null
  })

  React.useEffect(() => {
    if (query.isError) return
    if (!query.isLoading && !query.isFetching && !query.data) {
      console.info('FeatureViewer: se limpia selección (feature eliminado)')
      client.removeQueries(listWFS3CollectionQueryKey(etc.Model, filters))
      return bus.clearSelection()
    }
  }, [query.data, query.isError, query.isLoading, query.isFetching])

  // props "a mayores" que se injectan en el render
  const inject = React.useMemo(() => {
    return {
      feature: query.data,
      loading: query.isLoading,
      errored: query.isError,
      query,
      details,
      ...etc
    }
  }, [query, details, etc])

  return {
    ProviderComponent ({ loading, children, ...props }) {
      if (inject.errored) console.warn('Errored load')
      return (
        <Suspense fallback={fallback}>
          {
            loading
              ? children
              : <Provider {...props} {...inject}>{children}</Provider>
          }
        </Suspense>
      )
    },
    TitleComponent (props) {
      if (inject.errored) return 'Error al cargar datos'
      if (inject.loading || !inject.feature) {
        return (
          <>
            <Skeleton loading width={30} />
            <Skeleton loading width={90} />
          </>
        )
      }
      return typeof Title === 'string'
        ? (
            inject.feature[Title] ||
              <DefaultFeatureTitle {...props} {...inject} />
          )
        : <Title {...props} {...inject} />
    },
    ViewerComponent (props) {
      if (inject.errored) {
        return <QueryError query={query} />
      }
      return (
        <Suspense fallback={fallback}>
          <Viewer {...props} {...inject} />
        </Suspense>
      )
    },
    MenuComponent (props) {
      // Si el featuretype no define menu, no se hace nada
      if (!Menu || inject.errored) return null
      if (inject.loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton loading variant='rounded' height={40} sx={{ mr: 2, flexGrow: 1 }} />
            <Skeleton loading variant='rounded' height={40} width={90} />
          </div>
        )
      }
      return (
        // TODO creo que no tiene lógica suspender
        // <Suspense fallback={fallback}>
        <Menu {...props} {...inject} />
        // </Suspense>
      )
    },
    inject,
    ...etc
  }
}
