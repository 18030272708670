import React from 'react'

/**
 * `source` debe implementar la interfaz ".getLayers()"
 * Se puede usar un objeto Map o un layer/Group, por ejemplo
 */
const getLayersArray = (collection) => collection
  ? collection.getArray()
    .map(layer => ({ ...layer.getProperties(), layer }))
    .reverse()
  : []

export function useGetLayers (source) {
  const [layers, setLayers] = React.useState([])

  // const collection = React.useMemo(() => source?.getLayers(), [source])
  React.useEffect(() => {
    setLayers(getLayersArray(source?.getLayers()))
  }, [source])

  useOpenlayersEvents(source?.getLayers(), {
    'change:length' (event) {
      console.warn('Cambia la longitud del source de las capas')
      setLayers(getLayersArray(event.target))
    }
  }, [setLayers])

  return layers
}

export function useOpenlayersEvents (obj, _events = {}, deps = []) {
  const events = React.useMemo(() => Object.entries(_events), deps)

  React.useLayoutEffect(() => {
    if (!obj) return // console.warn('no se pueden bindear eventos a', obj)

    for (const [event, listener] of events) obj.on(event, listener)

    // Importante limpiar listeners al desmontar el efecto
    return () => {
      for (const [event, listener] of events) obj.un(event, listener)
    }
  }, [obj, events])
}
