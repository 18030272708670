import { parse as parseXML } from 'ol/xml'
import xml2json from '../vendor/xml2json'

export default class XML2JSONFormat {
  constructor (options = {}) {
    this.ROOT = options.ROOT
  }

  read (source) {
    if (typeof source !== 'string') {
      throw new TypeError('Source must be an string')
    }

    const xml = parseXML(source)
    const json = JSON.parse(xml2json(xml, '  '))

    const { [this.ROOT]: root } = json

    if (!root) {
      console.error({ source, xml, json })
      throw new TypeError(`Invalid ${this.ROOT} XML document`)
    }

    return root
  }
}

// utils
export function keyvalObjectReducer (acc, cur) {
  return { ...acc, [cur.key]: cur.val }
}

export function getDotted (target, path, { warn = true } = {}) {
  const parts = path.split('.')

  while (parts.length) {
    if (target === null || typeof target === 'undefined') {
      warn && console.warn('target is null or undefined', {
        target,
        path,
        next: parts[0]
      })
      break
    }
    target = target[parts.shift()]
  }

  return target
}

export function assertDTO (thing) {
  if (typeof thing !== 'object' || thing === null) {
    console.warn({ source: thing })
    throw new TypeError('source must be non-null object')
  }
}
