// vim:ts=2:sw=2:et

import React from 'react'
// import Toolbar from '@mui/material/Toolbar'

// import RooTable, { stringCol, numberCol } from '../Table'

import VirtualRooTable, { stringCol, numberCol } from '../VirtualTable'

import { ViewerContainer, Skeleton } from '../UtilsLayout'
import { QueryError } from '../UtilsQuery'

import { JSONView } from '../FeatureViewer/DefaultComponents'

import { useWFS3HugeCollection } from '../service/wfs3-hooks-query'

export function DefaultCollectionLoader ({
  Component,
  fallback,
  details,
  Model,
  limit = 50,
  ...etc
}) {
  /**
   * TODO COMO UNA CATEDRAL:
   * Estamos usando mal el infiniteQuery,
   * no tiene sentido manejar el offset desde aquí
   */
  const [offset, setOffset] = React.useState(0)

  // TODO no tiene demasiado sentido este uso de "map" que hacemos
  const qs = React.useMemo(() => {
    return typeof Model === 'string'
      ? { map: details.map, offset, limit }
      : { map: Model.project, offset, limit }
    // TODO details.params ??
  }, [Model, details, limit, offset])

  const query = useWFS3HugeCollection(Model, qs)

  // TODO esto lo adapté de lo que encontré en ListadoConstrucciones
  // La intuición me dice que tiene que haber una forma mejor de hacerlo
  React.useEffect(() => {
    if (query.isFetching) return
    if (limit >= query.data?.pages[0]?.total) return
    setOffset(offset => (query.data?.pages?.length || offset) * limit)
  }, [query, setOffset, limit])

  if (query.isInitialLoading) return fallback

  return <Component query={query} details={details} {...etc} />
}

/**
 * Este componente provee un layout por defecto para renderizar una colección
 */
export function DefaultCollectionLayout ({
  codename,
  CollectionComponent,
  query,
  ...etc
}) {
  // console.info('DefaultCollectionLayout', { etc })
  const count = React.useMemo(() => {
    return query.data?.pages[0].total || '...'
  }, [query.data])

  return (
    <>
      <h3>
        Lista de features de la capa <code>{codename}</code> ({count} elementos)
      </h3>
      <CollectionComponent query={query} {...etc} />
    </>
  )
  // <Toolbar>
  //   <MenuComponent />
  // </Toolbar>
  // <ViewerComponent {...props} />
}

/**
 * Este componente muestra una tabla con todos los atributos de cada elemento,
 * para cualquier feature collection.
 *
 * Por el momento es una implementación muy simplista, aunque incluye:
 * - formateo de números
 * - formateo de json (visor collapsible)
 * - detección de hiperenlaces
 */
export function DefaultFeatureCollection ({ query, details, ...props }) {
  // console.info('DefaultFeatureCollection', { details, query, props })

  if (query.isError) {
    return (
      <ViewerContainer>
        <QueryError title='Error al cargar la colección' query={query} />
      </ViewerContainer>
    )
  }

  // TODO: OJO, esto es sucio y error-prone
  const attrs = React.useMemo(() => {
    return (
      details.layer.clusterized
        ? details.layer.source.getSource()
        : details.layer.source
    ).get('qgsLayer').attributes
  }, [details.layer])
  // estos atributos son el "formulario de QGIS" y tienen {
  //   comment, editType, length, name, precision, type, typeName
  // }

  const columns = React.useMemo(() => attrs.map(attr => {
    const title = attr.name // TODO capitalizar ?
    const field = attr.name

    switch (attr.typeName) {
      case 'int8':
        return numberCol({
          title, field, format: v => v.toString()
        })
      case 'float8':
        return numberCol({
          title,
          field,
          format: v => (v.toPrecision ? v.toPrecision(6) : v).toString()
        })
      case 'int4':
        return numberCol({ title, field, places: 0 })
      case 'double':
        return numberCol({ title, field })
      case 'numeric':
        return numberCol({ title, field, places: Number(attr.precision) })
      case 'json':
      case 'jsonb':
        return {
          title,
          field,
          headProps: { width: '100%' },
          format: (v) => (
            <JSONView
              title={Array.isArray(v) ? `array (${v.length})` : typeof v}
              vo={v}
            />
          )
        }
      case 'bool':
        return stringCol({
          title,
          field,
          format: v => `${v}`
        })
      case '_text': // Esto es un Array?
        return stringCol({
          title,
          field,
          format: v => `${v.join(', ')}`
        })
      case 'text':
      case 'varchar':
        return stringCol({
          title,
          field,
          format: (v) => typeof v === 'string' && v.slice(0, 4) === 'http'
            ? <a href={v} target='_blank' rel='noopener noreferrer'>{v}</a>
            : `${v}`
        })
      case 'timestamptz':
        return stringCol({
          title,
          field,
          format: (v) => v && typeof v === 'string'
            ? new Date(v).toLocaleString()
            : v
        })
      default:
        switch (attr.editType) {
          case 'Enumeration':
            return stringCol({ title: `${title} (enum)`, field })
        }
        console.warn('Tipo de dato desconocido:', attr.typeName, attr)
        return stringCol({ title, field })
    }
  }), [attrs])

  if (query.isLoading) {
    // TODO esto obviamente no es definitivo
    return (
      <ViewerContainer>
        <VirtualRooTable
          total={10}
          columns={columns.map(c => ({
            ...c, format: () => <Skeleton loading height={30} />
          }))}
          values={Array(10).fill(null)}
        />
      </ViewerContainer>
    )
  }

  return (
    <ViewerContainer style={{ display: 'contents' }}>
      <VirtualRooTable
        columns={columns}
        values={query.data.pages.flatMap(page => page.list)}
        total={query.data.pages[0].total}
        query={query}
      />
    </ViewerContainer>
  )
}
