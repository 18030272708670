// vim:ts=2:sw=2:et

import { React, PropTypes, RooTypes } from '@grupomarea/roo-www-abcs'

// import useMediaQuery from '@mui/material/useMediaQuery'

// TODO Esto no debería requerirse aquí
// import { getCenter } from 'ol/extent'

import RooApp from '@grupomarea/roo-www-abcs/App'
import Header from '@grupomarea/roo-www-abcs/AppLayout/Header'
import Footer from '@grupomarea/roo-www-abcs/AppLayout/Footer'

import { Alert } from '@grupomarea/roo-www-abcs/UtilsNotify'
import { Button } from '@grupomarea/roo-www-abcs/MuiButton'

import FeatureView from '@grupomarea/roo-www-abcs/FeatureViewer'
import CollectionView from '@grupomarea/roo-www-abcs/CollectionView'

// OJO: falta refactor en el fichero destino
import Panel, { MenuPanel } from '@grupomarea/roo-www-abcs/Panel'
import Modal from '@grupomarea/roo-www-abcs/Modal'

import ErrorBoundary from '@grupomarea/roo-www-abcs/Error'

import {
  useBusGetter, useBusCache, useBusEvents
} from '@grupomarea/roo-www-abcs/bus-hooks'

import { useWindowSize } from '@grupomarea/roo-www-abcs/size-hooks'

import './Base.css'
import logo from '../assets/logo.png'

const isSideOpen = (cache) => !cache.get('kioskMode') && cache.get('sideOpen')
const isKioskOpen = (cache) => cache.get('kioskMode') && cache.get('sideOpen')

// wrappers para extraer
const MainPanel = (props) => <Panel anchor='left' {...props} />
const SidePanel = (props) => <Panel anchor='right' {...props} />

export default function HapunaLayout ({
  bus,
  user,
  HeaderComponent = Header,
  headerChildren,
  controlButtons,
  FooterComponent = Footer,
  footerChildren,
  MainPanelComponent = MainPanel,
  mainPanelChildren,
  SidePanelComponent = SidePanel,
  sidePanelChildren,
  MenuPanelComponent = MenuPanel,
  menuPanelChildren,
  ModalComponent = Modal,
  modalPanelChildren,
  //
  //
  // TODO: Hai que renombrar a "XXXLayoutComponent", porque presta a confusión
  // qué es lo que hace. En realidad es una prop para el Feature/Collection
  // viewer de turno y no el viewer de turno en sí
  //
  // TODO parecía lógico que definir aquí el por defecto no tiene sentido
  // pero al menos así se ve el pattern
  // Componente de control que maneja el estado de navegación tanto del viewer
  // en panel secundario como del viewer en modo kiosko
  ViewControlComponent = ({
    ControlledComponent, ...etc
  }) => <ControlledComponent {...etc} />,
  // La vista "Panel" por defecto se define en este mismo fichero (al final)
  PanelViewerComponent = SidePanelFeatureView,
  // La vista "Collection" por defecto se define en roo-www-abcs
  CollectionViewerComponent = undefined,
  // Si se desea, se puede proveer un modal customizado.
  // En caso contrario se utilizará el Modal por defecto de roo-www-abcs
  ModalViewerComponent = undefined,
  featureTypes = {},
  ...etc
}) {
  const [error, setError] = React.useState(null)

  useBusEvents(bus, {
    'map:init:error': (e) => { console.warn(e); setError(e) }
  })

  // Esto pertenece aquí por un motivo:
  // > En el futuro puede ser necesario el drawerWidth para alterar <Header>
  const { width } = useWindowSize() // window.visualViewport.width
  // Cuando se modifica a "tercio" no se piensa bien:
  // - Qué ocurre con los dos paneles abiertos en pantallas estrechas?
  // - Qué ocurre con el menú en pantallas estrechas?
  // → En cualquier caso, aún hay problemas también con los paneles
  const tercio = React.useMemo(() => {
    // usamos 1/3 de la pantalla, excepto si es menor de un tope
    const fraccion = width / 3
    return fraccion < 350 ? 350 : fraccion
  }, [width])
  // → Aquí falta poder comunicar adecuadamente el tamaño de los renders al bus,
  // de modo que se pueda ajustar el padding de la vista en consecuencia
  // @see https://gitlab.com/grupomarea/roo/marosa/-/commit/8b7719af45c5fd9ba02b83f1b05b59070f74700c

  const handleOpenMenu = () => { bus.toggleMenuPanel() }
  const handleOpenMain = () => { bus.toggleMainPanel() }
  const handleOpenSide = () => { bus.toggleSidePanel() }
  const handleCloseSide = () => { bus.toggleKiosk(true) }

  const details = useBusGetter(bus, 'view:details')

  return (
    <RooApp error={error} {...etc}>
      <BusOpenController
        bus={bus}
        selector={isSideOpen}
        ControlledComponent={HeaderComponent}
        // A partir de aquí son las props de <AppBarComponent>
        className='HapunaHeader'
        logo={logo}
        drawerWidth={tercio}
        controlButtons={controlButtons}
        onClickMenu={handleOpenMenu}
        // size={appbarSize}
      >
        {headerChildren}
      </BusOpenController>
      <BusOpenController
        bus={bus}
        selector='mainOpen'
        ControlledComponent={MainPanelComponent}
        // A partir de aquí son las props de <MainPanelComponent>
        width={2 * tercio}
        className='HapunaMainPanel'
        onClickMenu={handleOpenMain}
      >
        <ErrorBoundary>
          {mainPanelChildren || (
            details.layer
              ? (
                <CollectionView
                  Component={CollectionViewerComponent}
                  featureTypes={featureTypes}
                  details={details}
                  user={user}
                />
                )
              : (
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                  <Alert severity='info' sx={{ mb: 2 }}>
                    Selecciona un elemento para ver una colección
                  </Alert>
                  <Button onClick={handleOpenMain}>Cerrar este panel</Button>
                </div>
                )
          )}
        </ErrorBoundary>
      </BusOpenController>
      <BusOpenController
        bus={bus}
        selector='menuOpen'
        ControlledComponent={MenuPanel}
        // A partir de aquí son las props de <MenuPanel>
        logo={logo}
        width={tercio / 2}
        className='HapunaMenuPanel'
        onClickMenu={handleOpenMenu}
      >
        {menuPanelChildren}
      </BusOpenController>
      {/*
      Podríamos tener un componente intermedio para evitar tener el manejo del
      feature seleccionado a este nivel
      */}
      {SidePanelComponent && (
        <BusOpenController
          bus={bus}
          selector={isSideOpen}
          ControlledComponent={SidePanelComponent}
          // A partir de aquí son las props de <SidePanelComponent>
          width={tercio}
          className='HapunaSidePanel'
        >
          <ErrorBoundary>
            {(details.feature && (
              <FeatureView
                Component={ViewControlComponent}
                ControlledComponent={PanelViewerComponent}
                featureTypes={featureTypes}
                details={details}
                user={user}
                bus={bus}
              >
                {sidePanelChildren}
              </FeatureView>
            )) || sidePanelChildren || (
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Alert severity='info' sx={{ mb: 2 }}>
                  Selecciona un elemento para ver sus detalles
                </Alert>
                <Button onClick={handleCloseSide}>Cerrar este panel</Button>
              </div>
            )}
          </ErrorBoundary>
        </BusOpenController>
      )}
      {details.layer && (
        <BusOpenController
          bus={bus}
          selector={isKioskOpen}
          ControlledComponent={ModalComponent}
          className='HapunaKioskModal'
          dialogButtons={
            <>
              {user.isSudo && (
                <Button
                  onClick={() => { bus.toggleKiosk() }}
                >
                  Ver en Paneles
                </Button>
              )}
              <Button onClick={handleOpenSide}>Cerrar</Button>
            </>
          }
        >
          <ErrorBoundary sx={{ mt: 4 }}>
            <FeatureView
              Component={ViewControlComponent}
              ControlledComponent={ModalViewerComponent}
              featureTypes={featureTypes}
              details={details}
              user={user}
              bus={bus}
            />
          </ErrorBoundary>
        </BusOpenController>
      )}
      <BusOpenController
        bus={bus}
        selector={isSideOpen}
        ControlledComponent={FooterComponent}
        // A partir de aquí son las props de <FooterComponent>
        className='HapunaFooter'
        drawerWidth={tercio}
      >
        {footerChildren}
      </BusOpenController>
    </RooApp>
  )
}

HapunaLayout.propTypes = {
  bus: RooTypes.busWithMethods(
    'toggleMenuPanel',
    'toggleSidePanel',
    'setFeatureDetails',
    'getFeatureDetails',
    'setFeatureLoading'
  ),
  user: RooTypes.user,
  HeaderComponent: PropTypes.elementType,
  headerChildren: PropTypes.node,
  MenuPanelComponent: PropTypes.elementType,
  menuPanelChildren: PropTypes.node,
  SidePanelComponent: PropTypes.elementType,
  sidePanelChildren: PropTypes.node,
  FooterComponent: PropTypes.elementType,
  footerChildren: PropTypes.node,
  // featureTypes: FeatureViewerModal.propTypes.featureTypes
  CollectionViewComponent: PropTypes.elementType
}

/**
 * Controlador de estado que renderiza un componente injectando una prop "open"
 * cuyo valor se extrae del bus.
 * Su función es evitar re-renders de la App cuando se abre y cierra un menú,
 * panel, etcétera; encapsulando el useBusGetter
 */
function BusOpenController ({
  bus,
  selector,
  negated = false,
  ControlledComponent = ({ children }) => children,
  // facilitar la especificación de props cuyos nombres "chocan" con los de
  // las props de este "BusOpenController"
  controlledComponentProps = {},
  ...etc
}) {
  const selectorFunc = React.useMemo(() => {
    return (typeof selector === 'string')
      ? (cache) => cache.get(selector)
      : selector
  }, [selector])

  const open = useBusCache(bus, selectorFunc)

  return (
    <ControlledComponent
      bus={bus}
      open={negated ? !open : open}
      {...controlledComponentProps}
      {...etc}
    />
  )
}
BusOpenController.propTypes = {
  bus: RooTypes.bus.isRequired,
  selector: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  negated: PropTypes.bool,
  ControlledComponent: PropTypes.elementType
}

/**
 * FeatureView por defecto para el SidePanel
 */
function SidePanelFeatureView ({
  TitleComponent,
  ViewerComponent,
  children,
  ...etc
}) {
  return (
    <>
      <TitleComponent {...etc} />
      {children}
      <ViewerComponent {...etc} />
    </>
  )
}
