import Toolbar from '@mui/material/Toolbar/Toolbar'

import { Footer } from './AppBar'

export default function AppFooter ({
  bus,
  open,
  drawerWidth,
  children,
  ...etc
}) {
  return (
    <Footer
      open={open}
      drawerWidth={drawerWidth}
      elevation={0}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0)',
        position: 'fixed',
        top: 'auto',
        bottom: '2%',
        width: 'min-content',
        // TODO color por defecto a --marea-light?
        '& .MuiButtonBase-root.Mui-disabled': {
          color: 'var(--marea-dark)' // color: 'rgba(255, 255, 255, .5)'
        }
      }}
      {...etc}
    >
      <Toolbar variant='dense' sx={{ justifyContent: 'flex-end' }}>
        {children}
      </Toolbar>
    </Footer>
  )
}
