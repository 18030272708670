/**
 * Licensed under BSD-3-Clause © Lorenzo Garcia Rivera 2022
 */

import React from 'react'

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight
})

/**
 * Refactored from an excelent QA fount at
 * https://stackoverflow.com/a/63010184/1894803
 */
export function useWindowSize () {
  const [size, setSize] = React.useState(getSize)

  React.useLayoutEffect(() => {
    const resize = debounce(() => setSize(getSize), 100) // 100ms
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return size
}

export function debounce (fn, ms) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn(...args)
    }, ms)
  }
}

export const getWindowSize = getSize
