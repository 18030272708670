import React from 'react'

/**
 * Vistas que se usan en distintos featureTypes
 */

export const EDIFICIO = {
  label: 'Edificio',
  view: 'EDIFICIO',
  View: React.lazy(() => import('../Construcciones/VistaDatosEdificio'))
}
export const COMUN_USO = {
  label: 'Uso',
  view: 'COMUN_USO',
  View: React.lazy(() => import('../Construcciones/VistaDatosUso'))
}
export const RIESGO_1 = {
  label: 'Riesgo 1',
  view: 'GRUPO_1',
  View: React.lazy(() => import('../Calculos/VistaRiesgo1'))
}
export const RIESGO_2 = {
  label: 'Riesgo 2',
  view: 'GRUPO_2',
  View: React.lazy(() => import('../Calculos/VistaRiesgo2'))
}
export const RIESGO_3 = {
  label: 'Riesgo 3',
  view: 'GRUPO_3',
  View: React.lazy(() => import('../Calculos/VistaRiesgo3'))
}
export const RESUMEN = {
  label: 'Resumen',
  view: 'RESUMEN',
  View: React.lazy(() => import('../Calculos/VistaResumen'))
}
export const FORM_MENSAJES = {
  label: 'Enviar mensaje',
  hidden: true,
  view: 'FORM_MENSAJES',
  View: React.lazy(() => import('../Mensajes/VistaEnviarMensaje'))
}
export const MENSAJERIA = {
  label: 'Mensajes',
  view: 'MENSAJERIA',
  View: React.lazy(() => import('../Mensajes/VistaMensajesAdmin'))
}
