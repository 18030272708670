import XML2JSONFormat, {
  getDotted,
  keyvalObjectReducer
} from './XML2JSONFormat'

import QGISProjectLayer from './QGISProjectLayer'

export default class QGISProject extends XML2JSONFormat {
  constructor (options = {}) {
    super({ ROOT: 'WMS_Capabilities' })

    this.ORDER = 'Capability.LayerDrawingOrder'
    this.LAYERS = 'Capability.Layer.Layer'
    this.BOUNDS = 'Capability.Layer.BoundingBox'
    this.WFS_LAYERS = 'Capability.WFSLayers.WFSLayer'
    this.GET_STYLES = 'Capability.Request.qgs:GetStyles.DCPType.HTTP.Get.' +
      'OnlineResource.@xlink:href'

    this.projectUrl = new URL(options.url || '/?')
    this.projectMap = options.map || null
  }

  read (source) {
    const capabilities = super.read(source)

    // console.warn('experimental QGISProject.read', { capabilities })

    let wfs = getDotted(capabilities, 'Capability.WFSLayers')

    if (!wfs) {
      wfs = []
    } else {
      wfs = wfs.WFSLayer
      if (!wfs) {
        wfs = []
      } else {
        wfs = (Array.isArray(wfs) ? wfs : [wfs]).map(dto => dto['@name'])
      }
    }

    // Parser for layers
    const Layer = new QGISProjectLayer({
      projectUrl: this.projectUrl,
      projectMap: this.projectMap,
      WFSLayers: wfs
    })

    const order = getDotted(capabilities, this.ORDER)
    const layers = getDotted(capabilities, this.LAYERS)
    let bounds = getDotted(capabilities, this.BOUNDS)

    bounds = (Array.isArray(bounds) ? bounds : [bounds]).map(item => ({
      bbox: 'minx,miny,maxx,maxy'.split(',').map(key => item[`@${key}`] * 1),
      crs: item['@CRS']
    }))

    return {
      CRS: getDotted(capabilities, 'Capability.Layer.CRS'),
      bbox: bounds[0].bbox,
      bboxCRS: bounds[0].crs,
      bounds,
      order: order ? order.split(',') : [],
      layers: (
        layers
          ? (layers.map ? layers : [layers])
              .map(source => {
                const layer = Layer.read(source)
                return {
                  key: layer.name,
                  val: layer
                }
              })
              .reduce(keyvalObjectReducer, {})
          : []
      ),
      styles: new URL(
        `?${
          this.projectMap ? `map=${encodeURIComponent(this.projectMap)}&` : ''
        }service=WMS&request=GetStyles&layers=${encodeURIComponent(order)}`,
        this.projectUrl
      )
    }
  }
}
