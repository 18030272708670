/**
 * Ver https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
 * TODO Esta lógica sólo maneja códigos de 3 o 6 dígitos
 * TODO Actualmente pueden existir de 4 u 8 dígitos (con alpha)
 */
const HEX_COLOR_RE = /#([A-Fa-f0-9]{3}){1,2}$/
const isHexColor = (str) => HEX_COLOR_RE.test(str)
const isRgbaColor = (str) => (
  str.slice(0, 5) === 'rgba(' && str[str.length - 1] === ')'
)

export function hexToRGBA (hex, alpha = 1) {
  if (isRgbaColor(hex)) {
    const [r, g, b, a] = hex
      .replace(/^rgba\(/, '')
      .slice(0, -1).split(',')
      .map(s => Number(s.trim()))

    if ([r, g, b, a].some(n => !isFinite(n))) {
      throw new TypeError(`Invalid RGBA numbers: [${r}, ${g}, ${b}, ${a}]`)
    }

    return `rgba(${r}, ${g}, ${b}, ${a * alpha})`
  }
  if (!isHexColor(hex)) {
    throw new TypeError(`Invalid HEX color: '${hex}'`)
  }

  const [r, g, b] = (
    hex.length === 4
      ? hex.slice(1).split('')
      : [
          hex.slice(1, 3),
          hex.slice(3, 5),
          hex.slice(5, 7)
        ]
  ).map(str => parseInt(str, 16))

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
