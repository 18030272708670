// vim:ts=2:sw=2:et

export default async function init (bus) {
  const response = await window.fetch('/oauth2/acl', {
    headers: { Accept: 'application/json' }
  })

  if (response.status !== 200) {
    console.warn('No autenticado')
    setTimeout(() => window.location.reload(), 10)
    return null
  }

  let user = null
  try {
    user = await response.json()
    console.info('Autenticado como', user.email) // , user.ineMunicipio)
  } catch (err) {
    if (err instanceof SyntaxError) {
      console.warn('Falta configurar el sistema de autenticación', { err })
      return user
    } else {
      throw err
    }
  }

  user.isAnon = user.email === null
  user.isSudo = user.groups.includes('GisgalSudo')
  user.isTeam = user.isSudo || user.groups.includes('GisgalTeam')
  user.isArea = user.groups.includes('Area')
  user.isCaolin = user.groups.includes('Caolin')

  const rules = user.groups.reduce((acc, cur) => {
    const parts = cur.split(':')

    if (parts.length === 3) {
      const [field, value, code] = parts
      acc.push({ field, value, code })
    } else if (parts.length > 1) {
      console.warn('WFT', { parts })
    }

    return acc
  }, [])

  // TODO esto usará el último asignado, por el momento
  user.ineMunicipio = rules.reduce((no, rule) => {
    if (rule.field === 'ine_municipio') {
      return no ? [...no, rule.value] : [rule.value]
    }
    return no
  }, null)
  user.dgcMunicipio = rules.reduce((no, rule) => {
    if (rule.field === 'dgc_municipio') {
      return no ? [...no, rule.value] : [rule.value]
    }
    return no
  }, null)

  user.AreaAdmin = rules.reduce((acc, rule) => {
    if (rule.field === 'Area' && rule.value === 'Admin') {
      return [...acc, rule.code]
    }
    return acc
  }, [])

  user.hasAreaAdmin = user.AreaAdmin.length > 0

  // TODO setInterval(() => comprobarQueLaSesionVive)
  // TODO settear el user en el bus?

  return user
}
