import PropTypes from 'prop-types'

/**
 * Importante: Aquí vamos a depender directamente clases de openlayers, dado
 * que parece una locura abstraerse completamente de ellas, siendo esto un
 * selector para las capas de un mapa openlayers
 *
 * De cualquier modo esta estrategia es para implementarlo más rápido, tengo
 * claro que lo sensato es que este widget reciba un array con objetos de
 * un shape determinado y no dependa para nada de objetos de openlayers
 */

import Map from 'ol/Map'
import Layer from 'ol/layer/Base'

export const map = PropTypes.instanceOf(Map)

export const tipoCapa = PropTypes.oneOf(['base'])

export const shapeCapa = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: tipoCapa,
  layer: PropTypes.instanceOf(Layer).isRequired,
  visible: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
  description: PropTypes.string
})

export const shapeFondo = {
  ...shapeCapa,
  type: tipoCapa.isRequired
}
