import { View as _View } from 'ol'
import { getCenter } from 'ol/extent'
import { transformExtent } from 'ol/proj'

import { calculateScale, DPI } from './scale'

import { proj4, register } from './proj'

/**
 * Las utilidades de este módulo las desarrollé siguiendo ejemplos y docs
 * variados. Algunos relevantes:
 * - https://openlayers.org/en/latest/doc/tutorials/raster-reprojection.html
 * - https://openlayers.org/en/latest/examples/reprojection-by-code.html
 *
 * Este módulo define y registra el SRC EPSG:25829, dado que es el que usamos
 * por defecto en las Vistas para Galiza.
 */

proj4.defs(
  'EPSG:25829', // https://epsg.io/25829
  '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
)
proj4.defs(
  'EPSG:4326', // https://epsg.io/4326
  '+proj=longlat +datum=WGS84 +no_defs'
)
proj4.defs(
  'EPSG:3857', // https://epsg.io/3857
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 ' +
  '+k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
)

register(proj4)

// Esto es: [xmin, ymin, xmax, ymax]
const GZ_EXTENT = [474420, 4626910, 687390, 4849680]
const GZ_EPSG = 'EPSG:25829'

/**
 * Customización del View de OpenLayers para añadir funcionalidad relativa
 * al cálculo de escalas.
 *
 * Por defecto, el constructor inicializa la vista para:
 * - projection EPSG:27829 (Óptima para Galiza)
 * - center: centro de Galiza
 * - zoom: 8
 *
 * Estos cálculos de escala los basé en el conocimiento que encontré en
 * https://gis.stackexchange.com/q/242424/181317
 */
export default class View extends _View {
  constructor (options = {}) {
    if (!options.center) {
      if (typeof options.projection === 'undefined') {
        // Se utilizará EPSG:25829 centrado sobre Galiza
        options.projection = GZ_EPSG
        options.center = getCenter(GZ_EXTENT)
        options.zoom = 8
      } else {
        if (typeof options.projection !== 'string') {
          // Un uso tan avanzado debería hacerse especificando un centro
          console.warn('No se ha especificado un centro para la vista', options)
        }
        // Se calcula un centro sobre Galiza
        options.center = getCenter(
          // La proyección destino puede especificarse como string o projLike
          transformExtent(GZ_EXTENT, GZ_EPSG, options.projection)
        )
        options.zoom = 8
      }
    }
    /*
     * if (options.extent) options.showFullExtent = true
     */
    super(options)
  }

  getScale (dpi = DPI) {
    const unit = this.getProjection().getUnits()
    return calculateScale(this.getResolution(), unit, dpi)
  }

  getScaleForZoom (zoom, dpi = DPI) {
    const unit = this.getProjection().getUnits()
    return calculateScale(this.getResolutionForZoom(zoom), unit, dpi)
  }

  getScales () {
    const maxZ = this.getMaxZoom()
    const minZ = this.getMinZoom()

    return Array(maxZ - minZ)
      .fill(null)
      .map((v, idx) => idx + minZ)
      .map(zoom => ({ key: zoom, val: this.getScaleForZoom(zoom) }))
      .reduce((acc, cur) => ({ ...acc, [cur.key]: cur.val }), {})
  }

  async fitBounds (map, bounds, {
    padding,
    duration
  } = {}) {
    // const view = this
    const mapSize = map.getSize()

    // view.setMinZoom(0)
    const prevConstrain = this.getConstrainResolution()

    if (prevConstrain) {
      console.info('Se desactiva el constraint de resolución de la vista')
    }

    // OJO: Esto vino de hapuna
    this.setConstrainResolution(false)

    /* console.warn('Entra en fitbounds', {
      mapSize,
      padding,
      bounds,
      zoom: this.getZoom(),
      prevConstrain
    }) */

    // padding es [top, right, bottom, left]

    return await new Promise((resolve) => {
      this.fit(bounds, {
        size: mapSize,
        duration,
        padding: padding || Array(4).fill(35),
        // nearest: true,
        callback: (animationCompleted) => {
          // un false indica que la animación ha sido cancelada
          if (!animationCompleted) {
            console.info('Reajuste cancelado')
            return resolve({ target: this, bounds, padding, cancelled: true })
          }

          resolve({ target: this, bounds, padding })

          // const zoom = this.getZoom()

          // extent es [minx, miny, maxx, maxy]
          // const extent = view.calculateExtent(mapSize)

          // console.log({ zoom, extent })

          // Código antiguo de origen caolin-proto o quizá marosa
          /* map.qgisProjectViewProps = {
            ...map.qgisProjectViewProps,
            size: mapSize,
            projection: view.getProjection().getCode(),
            extent,
            // TODO esto no debería ser necesario, pero hay un bug en ol-qgis
            center: getCenter(extent),
            showFullExtent: true,
            minZoom: zoom,
            maxZoom: view.getMaxZoom(),
            constrainResolution: true
          } */

          /* map.setView(new View({
            ...map.qgisProjectViewProps,
            // TODO el padding parece buggeado (el orden de elementos)
            // TODO Además parece que se "acumula", hay algo que tener
            // en cuenta que aún no veo
            padding,
            zoom
          })) */
        }
      })
    })
  }
}
