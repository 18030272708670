import React from 'react'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

export { Alert }

export function Snack (props) {
  const { message, severity, ...other } = props
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open
      autoHideDuration={2000}
      message={severity ? undefined : message}
      key={new Date().getTime()}
      {...other}
    >
      {severity && (
        <Alert severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      )}
    </Snackbar>
  )
}

export function useSnackNotifier () {
  const [message, setMessage] = React.useState(null)

  return {
    message: (
      <Snack
        {...message}
        open={message !== null}
        onClose={() => setMessage(null)}
      />
    ),
    setMessage
  }
}
