import GeoJSON from 'ol/format/GeoJSON'

import JSONbig from 'json-bigint'

// Probé a usar GML pero observé más lentitud
// import GML from 'ol/format/GML'

import {
  all as allStrategy,
  tile as tileStrategy,
  bbox as bboxStrategy
} from 'ol/loadingstrategy'

import { createXYZ } from 'ol/tilegrid'

import QGISWFSSource from './source/QGISWFS'

const JSONstrict = JSONbig({ strict: true })

/**
 * Aquí falta refactor para mover ciertas partes a source/QGISWFS
 */
export function createWFS (layer, {
  overlaps,
  strategy, // Aquí existe, pero aún no se activó en ./layer.js
  firezneCompat // Compatibilidad para servicios QGIS 3.28
} = {}) {
  return new QGISWFSSource(layer, {
    // https://openlayers.org/en/latest/apidoc/module-ol_format_GeoJSON-GeoJSON.html
    format: new GeoJSON(
      firezneCompat
        ? {}
        // dataProjection: defaults to EPSG:4326
        // dataProjection: layer.bbox.crs
        : { dataProjection: layer.bbox.crs }
    ),
    overlaps: !!overlaps,
    strategy: (s => {
      switch (s) {
        case 'all': return allStrategy
        case 'bbox': return bboxStrategy
        case 'tile': return tileStrategy(createXYZ({ tileSize: 1024 }))
        default: return bboxStrategy
      }
    })(strategy),
    // url,
    loader (extent, resolution, projection, success, failure) {
      const limit = this.get('queryParams').maxFeatures

      const failed = (err) => {
        if (err) {
          err.response && console.log(err.response)
          console.error(err)
        }
        this.removeLoadedExtent(extent)
        failure()
      }

      const fetch = (offset) => {
        const url = this.getQgsUrl(extent, resolution, projection, offset)

        // TODO this.dispatchEvent()... ¿?
        // see https://github.com/openlayers/openlayers/blob/b04d542a3a8cf76dea5244322f8e285e49004b03/src/ol/source/Vector.js#L961

        return getFeatures(url)
          .then(text => JSONstrict.parse(text))
          .then(source => {
            return this.getFormat().readFeatures(source, {
              extent,
              // dataProjection: defaults to EPSG:4326
              // antes de QGIS 3.34, al ignorarse el SRSNAME, venían en 4326
              // dataProjection: projection,
              // si no se provee featureProjection, se devuelven en
              // dataProjection
              featureProjection: projection
            })
          })
          .then(features => {
            console.log('Recibidos', features.length, 'features', layer.name)
            this.addFeatures(features)

            if (features.length < limit) {
              console.info('Carga de features de', layer.name, 'completa')
              // TODO es necesario recuperar la lista entera?
              return success(features)
            }

            return fetch(offset + features.length)
          })
      }

      console.info('Cargando features de', layer.name)
      setTimeout(() => fetch(0).catch(failed), 0)
    } // */
  })
}

export async function getFeatures (url) {
  return await window
    .fetch(url, { method: 'GET' })
    .then(response => {
      if (response.status !== 200) {
        const err = new Error('Falló la carga')
        err.response = response
        return Promise.reject(err)
      }
      return response.text()
    })
}
