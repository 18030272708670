// vim:ts=2:sw=2:et:

import initMap from './map'
import initAuth from './services/auth'

export default function boot (bus) {
  const progress = new Progress(document.getElementById('progress'))

  bus.on('source:loadstart', () => {
    progress.addLoading()
  })
  bus.on('source:loadend', () => {
    progress.addLoaded()
  })
  bus.on('source:loaderror', () => {
    progress.addLoaded()
  })

  bus.on('app:feature:loadstart', () => progress.addLoading())
  bus.on('app:feature:loaderror', () => progress.addLoaded())
  bus.on('app:feature:loadend', () => progress.addLoaded())

  setTimeout(() => {
    initAuth(bus)
      .then(user => {
        bus.set('user', user)

        // OJO: user puede ser null
        // if (!user) return null
        const loadMap = () => {
          // bus.setMap(null)
          return initMap(bus, user)
            .catch(error => {
              if (!bus.listenerCount('map:init:error')) {
                console.error('Error al inicializar el mapa', error)
              }
              bus.emit('map:init:error', error)
              return null
            })
            .then(map => bus.setMap(map))
        }

        // Evito devolver una promesa en en handler intencionalmente
        bus.on('bus:set:view:proyecto', proyecto => {
          console.info('Ir al proyecto', bus.get('view:proyecto'))
          loadMap()
        })

        bus.set('app:sizes', { primary: 65, secondary: 35 })

        // TODO habrá que elaborar esto mejor
        if (user.isCaolin) {
          bus.toggleKiosk(true)
          bus.set('view:proyecto', 'CAOLIN')
        } else {
          if (user.isSudo) bus.toggleKiosk(false)
          loadMap()
        }
      })
      .catch(error => {
        if (!bus.listenerCount('app:init:error')) {
          console.error('Error al inicializar el visor', error)
        }
        bus.emit('app:init:error', error)
        return null
      })
  }, 10)

  if (window.Cypress) window.hapuna = bus

  return bus
}

// Ver https://openlayers.org/en/latest/examples/image-load-events.html
class Progress {
  constructor (element) {
    this._$ = element
    this._$.innerHTML = ''
    this._loading = 0
    this._loaded = 0
  }

  show () {
    this._$.style.visibility = 'visible'
  }

  hide () {
    if (this._loading === this._loaded) {
      this._$.style.visibility = 'hidden'
      this._$.style.width = 0
    }
  }

  addLoading () {
    if (this._loading === 0) this.show()
    ++this._loading
    this.update()
  }

  addLoaded () {
    setTimeout(() => {
      ++this._loaded
      this.update()
    }, 100)
  }

  update () {
    const width = ((this._loaded / this._loading) * 100).toFixed(1) + '%'
    this._$.style.width = width

    if (this._loading === this._loaded) {
      this._loading = this._loaded = 0
      setTimeout(() => { this.hide() }, 500)
    }
  }
}
