// vim:ts=2:sw=2:et
import React from 'react'

import Modal from '@mui/material/Modal'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

/**
 * Exportar un wrapper simple por si en el futuro necesitamos contexto
 */
export default function ErrorBounds (props) {
  return (
    <ErrorBoundary {...props} />
  )
}

export const DefaultErrorDetails = React.forwardRef(function ErrorDetails ({
  error,
  sx
}, ref) {
  return (
    <Alert severity='error' sx={sx} ref={ref} tabIndex={0}>
      <AlertTitle>Se ha producido un error</AlertTitle>
      <p>
        La acción solicitada ha producido un error inesperado, y es
        probable que vuelva a reproducirse al realizar la misma acción,
        aún en el caso de que intente recargar mediante el botón de
        actualizar del navegador.
      </p>
      <p>
        Por favor, contacte con el equipo técnico de Gisgal para que
        podamos solucionarlo a la mayor brevedad posible.
      </p>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        <summary style={{ cursor: 'pointer' }}>
          Detalles Técnicos
        </summary>
        <pre>{error && error.toString()}</pre>
        {/* this.state.errorInfo.componentStack */}
      </details>
      <p>
        Debido a este error la aplicación web está en un estado
        incierto que impide su normal funcionamiento, por lo que es
        imprescindible que recargue el visor mediante el botón de
        actualizar del navegador para poder seguir usándolo.
      </p>
      <p>
        Lamentamos las molestias.
      </p>
    </Alert>
  )
})

/**
 * La única forma de cazar errores, en la actualidad, es utilizar un Component
 * de clase
 *
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * @see https://es.reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch (error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render () {
    const { test } = this.props

    // Normally, just render children
    if (!this.state.errorInfo && !test) return this.props.children

    test && console.warn('Testing ErrorBoundary', this.props)

    return this.props.modal
      ? (
        <Modal open>
          <DefaultErrorDetails
            error={this.state.error}
            sx={statusModalStyle}
          />
        </Modal>
        )
      : (
        <DefaultErrorDetails
          error={this.state.error}
          sx={{ mx: 'auto', maxWidth: 'min(60em, 95%)', ...this.props.sx }}
        />
        )
  }
}

const statusModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4
}
