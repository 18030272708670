// vim:ts=2:sw=2:et:

import React from 'react'

import HapunaLayout from '@grupomarea/hapuna-www-base'

import { Copyright } from '@grupomarea/roo-www-abcs/AppLayout/Rights'
import MenuCapas from '@grupomarea/roo-www-abcs/MenuCapas'
import MenuCapasList from '@grupomarea/roo-www-abcs/MenuCapas/MenuCapasList'
import Button, {
  IconButton,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
  RightIcon,
  ClearIcon,
  DrawIcon,
  PickIcon,
  OffIcon,
  OnIcon,
  FullscreenIcon,
  FullscreenExitIcon
} from '@grupomarea/roo-www-abcs/MuiButton'
import Chip from '@grupomarea/roo-www-abcs/Chip'

import {
  MenuTrigger,
  MenuLoading,
  MenuCollapsible,
  Divider,
  // BackIcon,
  InfoIcon,
  InputIcon,
  LayersIcon,
  ChangeIcon,
  DetailsIcon,
  LogoutIcon,
  LoginIcon
} from '@grupomarea/roo-www-abcs/Menu/MenuUtils'

import { ReloadIcon } from '@grupomarea/roo-www-abcs/Icons'

/* import ErrorBoundary from './abcs/ErrorBoundary' */

import {
  useBusCache,
  useBusGetter,
  useBusSetter
} from '@grupomarea/roo-www-abcs/bus-hooks'

/**
 * Los índices ahora tienen sentido y han dejado de ser overkill
 */
import * as featTypesArea from '#area/index.jsx'
import * as featTypesCaolin from '#caolin/index.jsx'

import CustomPanelViewer from './CustomPanelViewer'
import CustomModalViewer from './CustomModalViewer'
import FeatureViewController from './FeatureViewController'

export default function App (props) {
  const { bus } = props

  const app = useBusGetter(bus, 'view:proyecto')
  const map = useBusGetter(bus, 'map')
  const user = useBusGetter(bus, 'user')

  const [
    DigitizeMenu = null,
    MainPanelContent = null,
    featureTypes = {},
    CollectionLayout = MainPanelListLayout,
    SearchComponent = null
  ] = React.useMemo(() => {
    // TODO esto ha de inyectarse de mejor modo
    const idx = (() => {
      switch (app) {
        case 'AREA': return featTypesArea
        case 'CAOLIN': return featTypesCaolin
        default: return {}
      }
    })()
    return [
      'DigitizeMenu',
      'MainPanelContent',
      'default',
      'CollectionLayout',
      'SearchComponent'
    ].map(k => idx[k])
  }, [app])

  if (!user) return null // Facilita el HMR

  console.info(`Render App con ${Object.keys(featureTypes).length} tipos`)

  return (
    <>
      <HapunaLayout
        bus={bus}
        user={user}
        // índice de componentes para el FeatureViewer
        featureTypes={featureTypes}
        //
        controlButtons={
          // TODO: esta prop debe desaparecer
          !user?.isSudo
            ? app !== 'AREA'
              ? null
              : !!user && !user.isAnon && (
                <>
                  <CloseMain bus={bus} text='Mapa' />
                  <OpenMain bus={bus} text='Listados' />
                </>
                )
            : <ToggleMain bus={bus} />
        }
        headerChildren={
          <>
            {SearchComponent && (
              <ButtonGroup sx={{ ml: 1 }}>
                <React.Suspense fallback='...'>
                  <SearchComponent bus={bus} />
                </React.Suspense>
              </ButtonGroup>
            )}
            <Copyright sx={{ ml: 'auto' }} />
            {user?.isSudo && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  right: '-1rem'
                }}
              >
                <ToggleKiosko bus={bus} sx={{ ml: 1 }} />
                <MapReload bus={bus} sx={{ ml: 1 }} />
                <ToolModes bus={bus} sx={{ ml: 1 }} />
                <ButtonGroup sx={{ ml: 1 }}>
                  <ClearSelection bus={bus} />
                  <ToggleSide bus={bus} />
                </ButtonGroup>
              </div>
            )}
          </>
        }
        menuPanelChildren={
          <>
            <MenuCapas map={map} />
            <MenuControls
              app={app}
              user={user}
              bus={bus}
              DigitizeComponent={DigitizeMenu}
            />
          </>
        }
        mainPanelChildren={MainPanelContent && (
          <React.Suspense fallback='...'>
            <MainPanelContent bus={bus} featureTypes={featureTypes} />
          </React.Suspense>
        )}
        CollectionViewerComponent={CollectionLayout}
        ViewControlComponent={FeatureViewController}
        PanelViewerComponent={CustomPanelViewer}
        ModalViewerComponent={CustomModalViewer}
        footerChildren={
          user && !user.isAnon && <Fullscreen bus={bus} />
        }
      />
    </>
  )
}

function MainPanelListLayout ({ CollectionComponent, ...etc }) {
  return (
    // <>
    // <h4>esto sería el toolbar por ejemplo</h4>
    <CollectionComponent {...etc} />
    // </>
  )
}

/**
 * Esto es una "trampa" para proveer "details" al CollectionView.
 * La otra opción es bajarlo al nivel de HapunaLayout, pero quiero mantenerlo
 * aquí arriba para ver hasta qué punto podemos flexibilizar el uso
 *
 * De seguir existiendo, es algo que pertenece a roo-www-abcs
 *
 * A efectos prácticos no es un Provider de React, y no se usa ahora mismo
 */
export function DetailsProvider ({ bus, details, Component, ...etc }) {
  // const details = useBusGetter(bus, 'view:details')
  return details.layer && <Component bus={bus} details={details} {...etc} />
}

function ToggleProject ({ bus }) {
  const proyecto = useBusGetter(bus, 'view:proyecto')
  const changeProject = useBusSetter(bus, 'view:proyecto')

  return (
    <MenuTrigger
      onClick={() => changeProject(proyecto === 'CAOLIN' ? 'AREA' : 'CAOLIN')}
      title={`Cambiar a ${proyecto === 'CAOLIN' ? 'AREA' : 'CAOLIN'}`}
      icon={<ChangeIcon />}
    />
  )
}

function ToggleMain ({ bus }) {
  const open = useBusGetter(bus, 'mainOpen')

  return (
    <div>
      <ToggleButtonGroup
        value={open ? 'list' : 'map'}
        onChange={() => bus.toggleMainPanel()}
        aria-label='Small sizes'
      >
        <ToggleButton value='map'><LayersIcon /> Mapa</ToggleButton>
        <ToggleButton value='list'><InfoIcon /> Listados</ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}

function ToggleKiosko ({ bus, sx }) {
  const toggleKiosk = () => bus.toggleKiosk()

  const enabled = useBusGetter(bus, 'kioskMode')

  return (
    <Button
      title='Activar/desactivar el modo "kiosko"'
      startIcon={enabled ? <OnIcon /> : <OffIcon />}
      variant={enabled ? 'contained' : 'outlined'}
      onClick={toggleKiosk}
      sx={sx}
    >
      Modo Kiosko
    </Button>
  )
}

function Fullscreen ({ bus }) {
  const [sideOpen, kioskMode] = useBusCache(bus, cache => [
    cache.get('sideOpen'), cache.get('kioskMode')
  ])

  const toggleKiosk = React.useCallback(() => {
    bus.toggleKiosk()

    // Necesario porque al no existir diseño de interacción con el botón
    // no es viable substituír el comportamiento del bus, y el toggleKiosk()
    // altera el valor de sideOpen en ciertas condiciones
    if (!kioskMode) {
      bus.toggleSidePanel(false)
    } else {
      // Es seguro no limpiar el timeout porque el estado destino siempre es el
      // mismo, si fuese condicional sería necesario más lógica
      !sideOpen && setTimeout(() => {
        bus.toggleSidePanel(true)
      }, 100)
    }
  }, [sideOpen, kioskMode])

  return (
    <IconButton
      className='HapunaKioskToggle'
      onClick={toggleKiosk}
      disableFocusRipple
      disableRipple
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }}
    >
      {(kioskMode ? true : !sideOpen)
        ? <FullscreenExitIcon sx={fullscreenStyle} />
        : <FullscreenIcon sx={fullscreenStyle} />}
    </IconButton>
  )
}

const fullscreenStyle = {
  fontSize: 60,
  color: 'rgba(255, 255, 255, 1)'
}

function ToggleSide ({ bus }) {
  const open = useBusGetter(bus, 'sideOpen')
  const kiosk = useBusGetter(bus, 'kioskMode')
  const toggleSide = () => bus.toggleSidePanel()

  const Icon = open ? RightIcon : DetailsIcon

  return (
    <Button
      title={(open ? 'Cerrar' : 'Abrir') + ' Panel Lateral'}
      disabled={kiosk}
      onClick={toggleSide}
      // usando IconButton
      // color='inherit'
      // edge='end'
    >
      <Icon size='small' />
    </Button>
  )
}

function ClearSelection ({ bus }) {
  const details = useBusGetter(bus, 'view:details')
  const handleClick = (event) => bus.clearSelection()

  return (
    <Button
      title='Limpiar la selección'
      onClick={handleClick}
      disabled={!details.feature}
      // usando IconButton
      // color='inherit'
      // edge='end'
    >
      <ClearIcon size='small' />
    </Button>
  )
}

function MapReload ({ bus, ...etc }) {
  const handleClick = (event) => bus.refreshMapSources()

  return (
    <Button
      title='Recargar las fuentes del mapa'
      onClick={handleClick}
      // usando IconButton
      // color='inherit'
      // edge='end'
      {...etc}
    >
      <ReloadIcon size='small' />
    </Button>
  )
}

function ToolModes ({ bus, ...etc }) {
  const mode = useBusGetter(bus, 'tool:mode')
  return (
    <ButtonGroup {...etc}>
      <Button
        title='Modo dibujo'
        onClick={() => bus.setToolMode('draw')}
        selected={mode === 'draw'}
      >
        <DrawIcon size='small' />
      </Button>
      <Button
        title='Modo selección'
        onClick={() => bus.setToolMode('info')}
        selected={mode === 'info'}
      >
        <PickIcon size='small' />
      </Button>
    </ButtonGroup>
  )
}

function OpenMain ({ bus, text = 'MainPanel' }) {
  const toggleMain = () => bus.openMainPanel()

  // const open = useBusGetter(bus, 'mainOpen')

  return (
    <Chip
      text={text}
      onClick={toggleMain}
      backcolor='#7fa1bd'
      textcolor='white'
      hoverbackgroud='white'
      hovertext='black'
    />
  )
}

function CloseMain ({ bus, text = 'MainPanel' }) {
  const toggleMain = () => bus.closeMainPanel()

  // const open = useBusGetter(bus, 'mainOpen')

  return (
    <Chip
      text={text}
      onClick={toggleMain}
      backcolor='#7fa1bd'
      textcolor='white'
      hoverbackgroud='white'
      hovertext='black'
    />
  )
}

function MenuControls ({ user, bus, DigitizeComponent }) {
  const [collapsed, setCollapsed] = React.useState(true)

  const toggleDigitize = () => {
    if (!collapsed) bus.setToolMode('info')
    setCollapsed(prev => !prev)
  }

  return (
    <MenuCapasList>
      {user?.isSudo && DigitizeComponent && (
        <>
          <Divider />
          <MenuCollapsible
            collapsed={collapsed}
            onCollapse={toggleDigitize}
            icon={<InputIcon />}
            title='Digitalizar'
          >
            <React.Suspense fallback={<MenuLoading />}>
              <DigitizeComponent bus={bus} />
            </React.Suspense>
          </MenuCollapsible>
        </>
      )}
      {user?.isSudo && (
        <>
          <Divider />
          <ToggleProject bus={bus} />
        </>
      )}
      <Divider />
      {!user?.isAnon && (
        <MenuTrigger
          className='HapunaLogout'
          icon={<LogoutIcon />}
          title='Logout'
          href='/oauth2/logout'
          target='_self'
        />
      )}
      {user?.isAnon && (
        <MenuTrigger
          className='HapunaLogin'
          icon={<LoginIcon />}
          title='Login'
          href='/oauth2/login'
          target='_self'
        />
      )}
    </MenuCapasList>
  )
}

// El "CustomPanelViewer" ahora está en un archivo aparte
// (en esta misma carpeta)
