// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton } from './UtilsLayout'
import Table from '@mui/material/Table'
// import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { TableVirtuoso } from 'react-virtuoso'

import { formatNumber } from './util'

// Función de formateo de valores por defecto
const f = value => `${value}`

/**
 * TODO: Toda esta mierda debe importarse de ./Table y desaparecer
 */
const stringStyle = { width: '300px' }
const numberStyle = { align: 'right', width: '300px' }

export const stringCol = ({
  title, field, bodyProps = {}, headProps = {}, ...etc
}) => ({
  title,
  field,
  bodyProps: { ...bodyProps, ...stringStyle, style: { ...bodyProps?.style } },
  headProps: { ...bodyProps, ...stringStyle, style: { ...headProps?.style } },
  ...etc
})
export const numberCol = ({
  title,
  field,
  format,
  places = 2,
  bodyProps = {},
  headProps = {}
}) => ({
  title,
  field,
  format: format || ((num) => num && num !== null
    ? formatNumber(num, places)
    : '0'),
  bodyProps: (
    typeof bodyProps === 'function'
      ? (...args) => ({ ...bodyProps(...args), ...numberStyle })
      : { ...bodyProps, ...numberStyle, style: { ...bodyProps?.style } }
  ),
  headProps: { ...bodyProps, ...numberStyle, style: { ...headProps?.style } }
})

export default function VirtualRooTable ({
  query,
  total,
  onClick,
  selected,
  columns = [
    { field: 'foo', title: 'Foo', headProps: undefined, bodyProps: undefined },
    { field: 'bar', title: 'Bar', format: v => v },
    { field: 'baz', title: 'Baz' },
    // ejemplos de uso de los helpers
    stringCol({ title: 'Example', field: 'example' }),
    numberCol({ title: 'Example number', field: 'numeric' })
  ],
  values = [
    { foo: 'Tarabela de gaitas', bar: 123, baz: 'tatarata' },
    { foo: 'Muiñeira de chantada', bar: 456, baz: 'totoroto' }
  ]
}) {
  const memoizedList = React.useMemo(() =>
    values.map((value, idx) =>
      <Row
        selected={selected}
        value={value}
        idx={idx}
        key={'column' + idx}
        columns={columns}
        handleSelect={onClick}
      />
    ), [columns, values]
  )

  return (
    <>
      {memoizedList &&
        <TableVirtuoso
          style={{ height: '100%', width: '100%' }}
          data={memoizedList}
          totalCount={total}
          endReached={() => {
            query.hasNextPage && query.fetchNextPage()
          }}
          components={{
            Table: (props) => (
              <Table
                size='small'
                style={{
                  tableLayout: 'auto'
                }}
                {...props}
              />
            ),
            TableRow: TableRowWrap,
            TableBody
          }}
          fixedHeaderContent={() => <TableHeader fixed columns={columns} />}
          fixedFooterContent={() => query?.isFetching && <Footer columns={columns} />}
          itemContent={(index, data) => <Row {...data.props} />}
        />}
    </>
  )
}

const TableRowWrap = React.forwardRef((props, ref) => {
  const { handleSelect, value, selected } = props.children.props
  return (
    <TableRow
      {...props}
      ref={ref}
      selected={selected === value}
      hover
      // Esto estaba provocando un error catastrófico
      // columns={props.children.props.columns}
      // value={value}
      onClick={() => {
        handleSelect && handleSelect(value)
      }}
    />
  )
})

function TableHeader (props) {
  const { columns } = props
  return (
    <TableRow sx={{ backgroundColor: 'white' }}>
      {columns.map((c) => (
        <TableCell key={'title-' + c.field} {...(c.headProps || {})}>
          {c.title}
        </TableCell>
      ))}
    </TableRow>
  )
}

function Row (props) {
  const { columns, value, idx } = props
  return (
    <>
      {columns.map((column) => {
        return (
          <TableCell
            key={'value-' + idx + '-' + column.field}
            {...(
              typeof column.bodyProps === 'function'
                ? column.bodyProps(value[column.field], column)
                : (column.bodyProps || {})
            )}
          >
            {(column.format || f)(value[column.field])}
          </TableCell>
        )
      })}
    </>
  )
}

function Footer (props) {
  const { columns } = props

  return (
    <TableRow sx={{ backgroundColor: 'white' }}>
      {columns.map((column, idx) => (
        <TableCell
          key={'value-' + idx + '-' + column.field}
          {...(column.bodyProps || {})}
        >
          <Skeleton loading height={30} />
        </TableCell>
      ))}
    </TableRow>
  )
}

VirtualRooTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    format: PropTypes.func,
    headProps: PropTypes.object,
    bodyProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  })),
  // TODO estaría bien validar que los objetos traen la forma que deben
  values: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  total: PropTypes.number
}
