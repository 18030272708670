import { styled } from '@mui/material/styles'

import List from '@mui/material/List'

/**
 * @function MenuCapasList
 * Ver https://mui.com/customization/default-theme/
 * para las utilidades que tiene un "theme"
 */
export default styled(List)(({ theme }) => ({
  '&': {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  '& .MuiListItem-root.LayerGroup .MuiListItem-root': {
    '& .MuiListItemText-root.LayerGroup .MuiListItemText-primary': {
      // backgroundColor: 'red',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(16)
    }
  },
  '& .MuiListItem-root': {
    paddingRight: theme.spacing(0),
    '& .MuiListItemButton-root': {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      // '&.Mui-selected + .MuiListItemSecondaryAction-root': {
      '&.expanded + .MuiListItemSecondaryAction-root': {
        // backgroundColor: 'red',
        'button svg:first-of-type': {
          transform: 'rotate(90deg) !important'
        }
      },
      '& .MuiListItemIcon-root': {
        // backgroundColor: 'purple',
        marginRight: theme.spacing(1.5),
        minWidth: 0
      },
      '& .MuiListItemText-root.LayerGroup .MuiListItemText-primary': {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(18)
      }
    },
    '& .MuiListItemSecondaryAction-root': {
      right: theme.spacing(2),
      // backgroundColor: 'green',
      'button svg:first-of-type': {
        transition: '0.2s',
        transform: 'rotate(0)'
      }
    }
  },
  '& .MuiListItem-root.LayerGroup': {
    // Este padding marca la "tabulación" de niveles
    paddingLeft: theme.spacing(2),
    '& > .MuiList-root': {
      paddingRight: 0
    }
  },
  '& .MuiListItem-root.LayerOptions': {
    paddingLeft: theme.spacing(8)
  }
  /* ,
  '& .MuiListItem-root .MuiListItemButton-root': {
    paddingLeft: 12 //,
    // paddingRight: 8
  },
  */
}))
