// vim:ts=2:sw=2:et

import PropTypes from 'prop-types'

import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

/**
 * La responsabilidad del componente "Panel" es abstraer los componentes mui,
 * facilitando la introducción de drawers
 */
export default function Panel ({
  bus,
  width,
  onClickMenu, // esta prop no tiene sentido alguno
  onTransitionEnd,
  children, // hay que cazarlos por cómo se altera el contenido
  ...etc
}) {
  /**
   * Toda la lógica de resize y repositioning no pertenece a este lugar
   *
   * En realidad aquellos que usan el componente (HapunaLayout, p.ej.)
   * deben decidir al respecto.
   *
   * La lógica original la creé para caolín y así sigue desde entonces
   */
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          maxWidth: '100vw',
          maxHeight: isSmall ? '50vh' : '100vh',
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      transitionDuration={{
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen
      }}
      SlideProps={{
        onExited: (node) => onTransitionEnd && onTransitionEnd()
      }}
      // anchor={anchor /* isSmall ? 'bottom' : 'right' */}
      // open y anchor se le pasan por extensión
      {...etc}
    >
      {/* este onClickMenu cheira a leguas */}
      {!!onClickMenu && <PanelWtfToolbar onClose={onClickMenu} />}
      {children}
    </Drawer>
  )
}

Panel.propTypes = {
  // https://mui.com/material-ui/api/drawer/
  open: PropTypes.bool,
  anchor: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
  width: PropTypes.number,
  onClickMenu: PropTypes.func, // → no tiene sentido
  onTransitionEnd: PropTypes.func
}

/**
 * Lo he extraído porque me cheira a leguas
 */
function PanelWtfToolbar ({ onClose, ...etc }) {
  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      alignItems='center'
      padding='8px'
    >
      <IconButton onClick={onClose}>
        <ChevronLeftIcon />
      </IconButton>
    </Stack>
  )
}

/**
 * TODO Debe quedar claro qué es responsabilidad de este componente y qué no
 *
 * → Lo he movido a este archivo porque tanto este como el "Panel" necesitan
 * refactor para cohesión
 *
 * TODO refactor, refactor, refactor
 */
export function MenuPanel (props) {
  const { open, onClickMenu, width, logo } = props

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          maxWidth: '100vw',
          maxHeight: isSmall ? '50vh' : '100vh',
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor={isSmall ? 'top' : 'left'}
      open={!!open}
      transitionDuration={{ enter: 200, exit: 200 }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        padding='8px'
      >
        <img
          src={logo}
          alt='Gisgal - Soluciones GIS Galicia'
          title='Gisgal - Soluciones GIS Galicia'
          className='logo'
          style={{ maxWidth: '100px', alignSelf: 'center' }}
        />
        <IconButton onClick={onClickMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </Stack>
      {props.children}
    </Drawer>
  )
}

MenuPanel.propTypes = {
  open: PropTypes.bool,
  onClickMenu: PropTypes.func,
  width: PropTypes.number
}
