import React from 'react'
import PropTypes from 'prop-types'

import { shapeCapa } from './propTypes'

import { useGetLayers } from './hooks'

import Capa from './Capa'

export default function CapasList (props) {
  const { capas, expanded, expandHandler, exclusiveSource } = props

  return capas.map(capa =>
    <Capa
      key={capa.name}
      capa={capa}
      expanded={
        Array.isArray(expanded)
          ? expanded.includes(capa.name)
          : expanded === capa.name
      }
      onExpand={capa.isGroup ? expandHandler(capa.name) : null}
      exclusiveSource={exclusiveSource}
      expandedState={expanded}
      expandHandler={expandHandler}
    />
  )
}

CapasList.propTypes = {
  capas: PropTypes.arrayOf(shapeCapa).isRequired,
  expanded: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  expandHandler: PropTypes.func.isRequired
}

/**
 * Este contenedor maneja un estado "expanded" propio
 */
export function CapasListContainer ({ source, ...props }) {
  const [expanded, setExpanded] = React.useState(false)

  const expandHandler = (layer) => (event, sense) => {
    // console.log('handleando expanded de un contenedor', layer, sense)
    setExpanded(state => sense ? layer : state === layer ? false : state)
  }

  const capas = useGetLayers(source)

  let exclusiveSource = null
  if (source.get && source.get('isGroup') && source.get('mutuallyExclusive')) {
    exclusiveSource = source
  }

  return (
    <CapasList
      {...{ capas, expanded, expandHandler, exclusiveSource }}
      {...props}
    />
  )
}
