// vim:ts=2:sw=2:et

import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'

import CriticalErrorModal from './CriticalErrorModal'

import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'

// see also https://mui.com/components/icons/#font-icons

/**
 * Este es el componente raíz de cualquier aplicación "montada con roo"
 */
export default function RooApp ({
  error = null,
  ...props
}) {
  return (
    <>
      <CssBaseline />
      <Stack direction='row' sx={{ flexGrow: 1 }}>
        {props.children}
      </Stack>
      <CriticalErrorModal error={error} />
    </>
  )
}

RooApp.propTypes = {
  // error: RooTypes.nullable(PropTypes.instanceOf(Error))
}
