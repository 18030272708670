/**
 * Este módulo provee un diccionario que ya no existe en el paquete ol
 *
 * En el futuro, no debería ser necesario
 *
 * La versión 6.15 de openlayers rompe con los diccionarios
 * → https://github.com/openlayers/openlayers/releases/tag/v6.15.0
 *
 * Ahora hay que usar type-safe strings
 * → https://openlayers.org/en/latest/apidoc/module-ol_geom_Geometry.html#~Type
 **/

const GeometryType = {
  POINT: 'Point',
  LINE_STRING: 'LineString',
  // 'LinearRing',
  POLYGON: 'Polygon',
  MULTI_POINT: 'MultiPoint',
  MULTI_LINE_STRING: 'MultiLineString',
  MULTI_POLYGON: 'MultiPolygon',
  GEOMETRY_COLLECTION: 'GeometryCollection'
  // 'Circle'
}

export default GeometryType
