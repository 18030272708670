import { assertDTO } from './format/XML2JSONFormat'

import GeometryType from './geom/GeometryType'

import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import RegularShape from 'ol/style/RegularShape'

import { calculateScale } from './scale'

/**
 * Colores corporativos del grupo marea [R, G, B]
 */
export const MAREA_RED = [212, 43, 41]
export const MAREA_DARK = [0, 84, 120]
export const MAREA_LIGHT = [112, 168, 194]

const {
  POLYGON,
  MULTI_POLYGON,
  LINE_STRING,
  MULTI_LINE_STRING,
  POINT,
  MULTI_POINT,
  GEOMETRY_COLLECTION
} = GeometryType

/** Interna, se usa para computar los estilos predefinidos */
const WIDTH = 3

// TODO aquí hay mucho refactor pendiente
export const STYLES = {
  // Estilo de capas cuando falta el estilo
  missing: new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 255, 1.0)',
      width: 2
    })
  }),
  [POINT]: [
    new Style({
      image: new Circle({
        radius: WIDTH * 2,
        fill: new Fill({
          color: [...MAREA_DARK, 1]
        }),
        stroke: new Stroke({
          color: [...MAREA_LIGHT, 1],
          width: WIDTH / 2
        })
      }),
      zIndex: Infinity
    })
  ],
  [POLYGON]: [
    new Style({
      zIndex: Infinity,
      fill: new Fill({
        color: [...MAREA_DARK, 1]
      }),
      stroke: new Stroke({
        color: [...MAREA_LIGHT, 1],
        width: 5
      })
    })
  ],
  [LINE_STRING]: [],
  // Estilo de interaccion {StyleFunction}
  selected (feature, resolution) {
    const geom = feature.getGeometry().getType()
    return STYLES.SELECTED[geom] || STYLES[geom]
  },
  // Estilo de interaccion {StyleFunction}
  hover (feature, resolution) {
    const geom = feature.getGeometry().getType()
    // console.log('stylize hover', { geom })

    if (geom === POINT) {
      console.warn('stylize hover point', feature.getStyle())
    }

    return STYLES.HOVER[geom] || STYLES[geom]
  },
  SELECTED: {
  },
  HOVER: {
    [POLYGON]: new Style({
      zIndex: Infinity,
      fill: new Fill({
        color: [...MAREA_LIGHT, 0.5] // Marea Light
      }),
      stroke: new Stroke({
        color: [...MAREA_DARK, 1], // Marea Dark
        width: 5
      })
    })
  }
}

STYLES[MULTI_POINT] = STYLES[POINT]
STYLES[MULTI_POLYGON] = STYLES[POLYGON]
STYLES[MULTI_LINE_STRING] = STYLES[LINE_STRING]
STYLES[GEOMETRY_COLLECTION] = STYLES[POINT].concat(
  STYLES[POLYGON], STYLES[LINE_STRING]
)

STYLES.HOVER[MULTI_POLYGON] = STYLES.HOVER[POLYGON]

export async function createStyleFromSLD (sld, conf) {
  assertDTO(sld)

  // Cuando existen filtros, implica "estilo condicional"
  // P. ej. "Categorías de estilo"
  if (sld.rules.some(rule => !!rule.filter)) {
    return await _styleFunctionFromSLD(sld, conf)
  }

  // Cuando no existen filtros, hay que combinar todas las reglas de estilo
  const styles = sld.rules.flatMap(rule => rule.styles)
  const opts = await createOptionsFromRules(styles, {
    iconSize: sld.iconSize
  })

  const fields = []
  const style = new Style(opts)

  if (opts.text) {
    const textStyle = styles.find(item => item.type === 'Text')
    const { field, label, maxScale, minScale } = textStyle

    if (field && !fields.includes(field)) fields.push(field)

    const resolutionDependant = (
      typeof maxScale === 'number' || typeof minScale === 'number'
    )

    return [
      function textStyleFunction (feature, resolution) {
        let text = null
        const scale = calculateScale(resolution, conf.UNIT)

        if (resolutionDependant && (scale > maxScale || scale < minScale)) {
          text = ''
        } else {
          text = stringDivider(
            field
              ? (feature.get(field) || feature.getId())
              : (label || field)
          )
        }

        style.getText().setText(`${text}`)
        return style
      },
      fields
    ]
  }

  return [style, fields]
}

function getFilterProperties (filter) {
  if (filter.or || filter.not) {
    return (filter.or || filter.not).flatMap(getFilterProperties)
  }
  if (filter.isNull) {
    return [filter.isNull.property]
  }
  if (filter.equality) {
    return [filter.equality.property]
  }
  if (filter.fallback || filter.functionName) {
    return []
  }
  console.warn('No sé determinar el campo de', { filter })
  return null
}

async function _styleFunctionFromSLD (sld, conf) {
  const fields = sld.rules.reduce((fields, rule) => {
    let property = null

    if (!rule.filter) {
      const text = rule.styles.find(s => s.type === 'Text')
      if (text) {
        property = [text.field]
      } else {
        console.info('ignorar rule sin filtro ni estilo de texto', { rule })
        return fields
      }
    } else {
      property = getFilterProperties(rule.filter)
    }

    return fields.includes(property)
      ? fields
      : [...fields, ...(Array.isArray(property) ? property : [property])]
  }, [])

  const _texts = sld.rules.map(rule => {
    const text = rule.styles.find(s => s.type === 'Text')
    return text ? text.field : null
  })

  const styles = await Promise.all(sld.rules.map(async rule => {
    return new Style(await createOptionsFromRules(rule.styles, {
      iconSize: sld.iconSize
    }))
  }))

  const _tests = sld.rules.map((rule) => {
    const { maxScale, minScale } = rule

    const zoomTest = [maxScale, minScale].some(n => typeof n === 'number')
      ? (resolution) => {
          const scale = calculateScale(resolution, conf.UNIT)
          return scale <= maxScale && scale >= minScale
        }
      : (resolution) => true

    if (!rule.filter) {
      return (feature, resolution) => zoomTest(resolution)
    }

    const itemTest = getFilterTestFn(rule.filter)

    return (feature, resolution, acc) => {
      return itemTest(feature, acc) && zoomTest(resolution)
    }
  })

  const tupples = Object.entries(_tests)

  return [
    (feature, resolution) => {
      const _styles = tupples.reduce((acc, [idx, test]) => {
        if (!test(feature, resolution, acc)) return acc

        const field = _texts[idx]
        const style = styles[idx]

        if (field) {
          style.getText().setText(
            stringDivider(feature.get(field) || feature.getId())
          )
        }

        return [...acc, style]
      }, [])

      if (!_styles.length) {
        console.warn('Ningún test determina el estilo', {
          featureProps: feature.getProperties(),
          rules: sld.rules,
          styles
        })
        return null
      }

      /* console.log('Estilos determinados', {
        probes: _tests.map(fn => fn(feature, resolution)),
        _styles
      }) */

      return _styles.length > 1 ? _styles : _styles[0]
    },
    fields
  ]
}

function getFilterTestFn (filter) {
  if (filter.or) {
    const filters = filter.or.map(getFilterTestFn)
    return (feature) => filters.every(f => f(feature))
  }
  if (filter.not) {
    const filters = filter.not.map(f => getFilterTestFn(f))
    return (feature) => !filters.every(f => f(feature))
  }

  if (filter.isNull) {
    return (feature) => feature.get(filter.property) === null
  }

  if (filter.equality) {
    const f = filter.equality
    return (feature) => feature.get(f.property) === f.value
  }

  if (filter.selected) {
    console.info('El filtro is_selected está en desarrollo aún', filter)
    return (feature) => feature.get('isSelected')
  }

  if (filter.fallback) {
    // El fallback necesita saber si ha matcheado algún otro filtro,
    // este acc es el array acumulador del reducer que decide el estilo
    return (feature, acc) => Array.isArray(acc) && acc.length === 0
  }

  console.warn('Falta implementar la lógica del filtro', { filter })
  return () => true
}

export async function createOptionsFromRules (rules = [], { iconSize }) {
  if (!Array.isArray(rules)) {
    console.error({ actual: rules, expected: 'Array.isArray' })
    throw new TypeError('Se esperaba un array')
  }
  if (!Array.isArray(iconSize) || iconSize.length !== 2) {
    console.error({ actual: iconSize, expected: 'Array.isArray' })
    throw new TypeError('Se esperaba un array de longitud 2')
  }

  const options = {}

  for (const item of rules) {
    const {
      type: styleType,
      StyleOpts,
      [`${item.type}Opts`]: ClassOpts,
      // Las eliminamos de "etcs" porque se usa para detectar missimplementation
      maxScale,
      minScale,
      ...etcs
    } = item

    if (StyleOpts) {
      console.warn('Asignar StyleOpts', { options, StyleOpts })
      Object.assign(options, StyleOpts)
    }
    if (!ClassOpts) {
      console.warn('Falta refactor:', styleType, item)
    }

    switch (styleType) {
      case 'Text': {
        const {
          anchor: [anchorX, anchorY],
          offset: [offsetX, offsetY],
          field,
          priority,
          placementStrategy: placement,
          ...etc
        } = etcs
        if (Object.keys(etc).length) {
          console.warn('Implementación incompleta etiquetas:', etc)
        }
        // https://openlayers.org/en/latest/apidoc/module-ol_style_Text.html
        const { ...TextOpts } = ClassOpts
        for (const [word, Klass] of [['Fill', Fill], ['Stroke', Stroke]]) {
          for (const key of [word.toLowerCase(), `background${word}`]) {
            if (ClassOpts[key]) TextOpts[key] = new Klass(ClassOpts[key])
          }
        }
        if (typeof priority === 'number') {
          TextOpts.declutterMode = 'obstacle'
        }

        // defaults "sanos"
        TextOpts.text = 'Lorem ipsum'
        // padding es [top, right, bottom, left] y afecta al decluttering
        TextOpts.padding = Array(4).fill(2)
        TextOpts.offsetX = offsetX
        TextOpts.offsetY = offsetY

        // Hay un bug en qgis que dificulta obtener dos offsets negativos
        // https://github.com/qgis/QGIS/issues/58862
        // Y lo que sigue es un work-around mientras lo arreglan para
        // separar el texto "un poco" del ancla
        if (offsetY === 0.1 && anchorY === 1) TextOpts.offsetY += 5
        if (offsetX === 0.1 && anchorX === 1) TextOpts.offsetX += -5
        if (offsetY === 0.1 && anchorY === 0) TextOpts.offsetY += -5
        if (offsetX === 0.1 && anchorX === 0) TextOpts.offsetX += 5

        options.text = new Text(TextOpts)

        // Cálculo "antiguo" del placement
        // const [anchorSizeX, anchorSizeY] = iconSize
        // anchorSize se calcula en base al "símbolo más grande"
        // anchorX y anchorY son [-1 / 0 / 1] e indican los cuadrantes
        // offsetX: anchorX * (anchorSizeX / 3) + offsetX,
        // offsetY: anchorY * (anchorSizeY / 3) + offsetY
        break
      }
      case 'Fill':
        // Aquí hay que tener en cuenta rellenos de patrón via canvas
        // https://github.com/openlayers/openlayers/pull/4632#issuecomment-328328634
        // https://openlayers.org/en/latest/apidoc/module-ol_colorlike.html#~PatternDescriptor

        options.fill = (typeof ClassOpts.color !== 'object')
          ? new Fill(ClassOpts)
          : await new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            const img = new window.Image()

            img.onerror = (event) => {
              const e = new Error('Error al cargar el patrón de relleno')
              e.event = event
              e.ClassOpts = ClassOpts
              reject(e)
            }
            img.onload = () => {
              const color = context.createPattern(img, 'repeat')
              console.info('patrón cargado, lets go', { color })
              console.warn('Han perfeccionado el API de rellenos', ClassOpts)
              resolve(new Fill({ color }))
              // vectorLayer.setStyle(style)
            }
            img.src = ClassOpts.color.src
          })
        break
      case 'Stroke':
        options.stroke = new Stroke(ClassOpts)
        break
      case 'Icon': {
        const CopyOpts = { ...ClassOpts }
        if (typeof etcs.priority === 'number') {
          CopyOpts.declutterMode = 'obstacle'
        }
        options.image = new Icon(CopyOpts)
        break
      }
      case 'RegularShape':
      case 'Circle': {
        const { ...CopyOpts } = ClassOpts
        for (const [word, Klass] of [['fill', Fill], ['stroke', Stroke]]) {
          if (ClassOpts[word]) CopyOpts[word] = new Klass(ClassOpts[word])
        }
        if (options.image) {
          console.info('Openlayers no soporta múltiples imágenes!', { item })
          break
        }
        options.image = new (
          styleType === 'Circle' ? Circle : RegularShape
        )(CopyOpts)
        break
      }
      default:
        console.warn('Falta la implementación de', styleType, item)
    }
  }

  return options
}

// Taken from https://openlayers.org/en/latest/examples/vector-labels.html
// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
export function stringDivider (str, width = 15, spaceReplacer = '\n') {
  if (typeof str !== 'string') {
    // TODO avisar en el primero de cada X tiempo
    // console.warn(str, 'no es un string')
    str = `${str}`
  }
  if (str.length > width) {
    let p = width
    while (p > 0 && str[p] !== ' ' && str[p] !== '-') {
      p--
    }
    if (p > 0) {
      let left
      if (str.substring(p, p + 1) === '-') {
        left = str.substring(0, p + 1)
      } else {
        left = str.substring(0, p)
      }
      const right = str.substring(p + 1)
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer)
    }
  }
  return str
}
