import ListItemButton from '@mui/material/ListItemButton'

export default function SidebarListItemButton (props) {
  return (
    <ListItemButton
      sx={{
        '& .MuiListItemIcon-root': { minWidth: 44 },
        ...props.sx
      }}
      {...props}
    />
  )
}
