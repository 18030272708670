// vim:ts=2:sw=2:et

import { Map } from 'ol'

import { extend } from 'ol/extent'

import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'

/* import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
*/

import { defaults as defaultControls } from 'ol/control'
import ScaleLine from 'ol/control/ScaleLine'
// import ZoomSlider from 'ol/control/ZoomSlider'
import OverviewMap from 'ol/control/OverviewMap'

import View from '@grupomarea/ol-qgis/View'
import { loadProject } from '@grupomarea/ol-qgis'

import { getWindowSize, debounce } from '@grupomarea/roo-www-abcs/size-hooks'

import 'npm:ol/ol.css'

async function getConfig (proyecto) {
  switch (proyecto) {
    case 'AREA': return await import('./features-area/map-config.js')
    case 'CAOLIN': return await import('./features-caolin/map-config.js')
    default: throw new Error('Falta la configuración del mapa')
  }
}

/**
 * Crea el objeto Map de forma asíncrona.
 *
 * Debe especificarse un bus (EventEmitter) para comunicar eventos.
 */
export default async function initMap (bus, user) {
  const $map = document.getElementById('map')

  /* for (const $element of $map.children) { $element.remove() } */

  const proyecto = bus.get('view:proyecto')
  // carga dinámica del generador de configuración del proyecto
  const configure = (await getConfig(proyecto)).default

  if (!user.isAnon && !user.isSudo) bus.toggleKiosk(false)

  console.info('Reconfigurar mapa para codename', proyecto)

  const mapConfig = await configure(bus, user)

  const { dgcMunicipio } = user

  // Esto es: [xmin, ymin, xmax, ymax]
  // const GZ_EXTENT = [474420, 4626910, 687390, 4849680]
  const DEF_EPSG = 'EPSG:3857' // 'EPSG:4326' //

  const view = new View({
    maxZoom: 28,
    projection: DEF_EPSG
    // constrainResolution: true
  })

  // Acceso a la info del proyecto
  const QGISPROJECT = window.location.origin + `/gis/${proyecto}/?`
  const project = await loadProject(QGISPROJECT, {
    // No parece lógico que la vista y el bus se configuren por proyecto
    ...mapConfig, view, bus
  })

  // console.log({ project })

  const { layers, crs } = project

  // console.warn('Se va a crear el mapa', layers, view)

  const map = new Map({
    target: $map,
    layers,
    controls: defaultControls().extend([
      // new ZoomSlider(),
      new OverviewMap({
        // collapsed: false,
        layers: [new TileLayer({ source: new OSM() })]
      }),
      new ScaleLine({ units: 'metric' })
    ]),
    view
  })

  console.info('Mapa creado')

  if (crs !== DEF_EPSG) {
    // TODO en el futuro habrá que ver de cargar cualquier crs
    console.warn('EL SRC del proyecto', crs, 'no coincide con', DEF_EPSG, {
      project
    })

    const view = new View({
      maxZoom: 28,
      projection: crs
      // constrainResolution: true
    })
    map.setView(view)
  }

  const calculatePadding = (sizes, fullScreen) => {
    if (!sizes) return

    const { width: vw /*, height: vh */ } = getWindowSize()

    // const h = document.getElementById('primary-drawer')
    // ?.getBoundingClientRect()
    // ?.height

    // console.warn('Recalcular', { sizes, vh, vw, view })

    // padding es [top, right, bottom, left]
    return [
      55, // + (typeof bus.get('view:primary') === 'string' ? 0 : h),
      35 + (
        fullScreen
          ? 0
          : (sizes.secondary ? (sizes.secondary * vw / 100) : 0)
      ),
      35,
      35
    ]
  }

  // Límites por defecto de la vista
  let bounds = project.bounds.map(Number)

  // Ajusta el mapa para ver todo el proyecto según los límites que anuncie
  fitBounds(map, bounds, {
    padding: calculatePadding(bus.get('app:sizes'), bus.get('kioskMode'))
  })
  const FIT_DURATION = undefined

  bus.on('bus:set:app:sizes', sizes => {
    const center = view.getCenter()
    view.padding = calculatePadding(sizes, bus.get('kioskMode'))
    // console.warn('Cambio de tamaño', { center, zoom })
    view.animate({
      center,
      duration: 225
    })
  })

  if (!dgcMunicipio || user.isAnon) {
    const resize = debounce(() => {
      // console.warn('El tamaño ha cambiado')
      fitBounds(map, bounds, {
        padding: calculatePadding(bus.get('app:sizes')),
        duration: FIT_DURATION
      })
    }, 100) // ms
    const clean = (_map) => {
      if (_map !== map) {
        console.warn('Se cambia el mapa, limpiar listeners')
        bus.removeListener('bus:set:map', clean)
      }
    }
    map.on('change:size', resize)
    bus.on('bus:set:map', clean)
  } else {
    const extLayerName = 'municipios'

    // const extGroup = layers.find(layer => layer.get('name') === 'g_base')

    const extLayer = layers
      .find(layer => layer.get('name') === extLayerName)

    if (!extLayer) {
      console.warn('Falta la capa de extensión')
      return map
    }

    // So no es visible, no cargará features
    // extGroup.setVisible(true)
    extLayer.setVisible(true)

    const extSource = extLayer.getSource()

    const features = extSource.getFeatures()

    if (!features.length) {
      // NOTA @lorenzogrv: Empleo una estrategia lazy exec vía timeout,
      // para evitar recalcular la extensión múltiples veces si se añaden
      // varios features en un periodo corto de tiempo (p.ej, al inicializar)
      let recalculateTimeout = null
      const recalculateBounds = () => {
        const features = extSource.getFeatures()

        const extent = features.shift().getGeometry().getExtent().slice(0)
        features.forEach(feature => {
          // OJO: extend() modifica el primer argumento que recibe
          extend(extent, feature.getGeometry().getExtent())
        })

        bounds = extent
        fitBounds(map, extent, {
          padding: calculatePadding(bus.get('app:sizes')),
          duration: FIT_DURATION
        })
      }
      // console.warn('No se han cargado municipios todavía')
      extSource.on('addfeature', () => {
        // console.warn('Se ha añadido un feature a la capa de extensión')
        recalculateTimeout && clearTimeout(recalculateTimeout)
        recalculateTimeout = setTimeout(recalculateBounds, 10)
      })
      extSource.once('featuresloadend', () => {
        // console.warn('Se ha terminado de cargar features en', extSource)
        if (dgcMunicipio.length < 2) {
          console.info('Se elimina la capa municipios')
          map.removeLayer(extLayer)
          // TODO otra opción también sería: extLayer.setVisible(false)
        }
      })
    } else {
      console.warn('La capa de extensión ya tiene features', features)
    }
  }

  // let to = null
  // TODO
  /*
  map.on('change:size', event => {
    if (to) return
    console.info('Ha cambiado el tamaño del mapa')
    to = setTimeout(() => {
      map.updateSize()
      fitProject()
      to = null
    }, 200)
  })
  */

  return map
}

const fitBounds = (map, bounds, { padding, duration }) => {
  const view = map.getView()
  const mapSize = map.getSize()

  // view.setMinZoom(0)
  view.setConstrainResolution(false)

  /* console.warn('Entra en fitbounds', {
    mapSize,
    padding,
    bounds,
    zoom: view.getZoom()
  }) */

  // padding es [top, right, bottom, left]
  view.fit(bounds, {
    size: mapSize,
    duration,
    padding: padding || Array(4).fill(35),
    // nearest: true,
    callback () {
      const zoom = view.getZoom()

      // extent es [minx, miny, maxx, maxy]
      const extent = view.calculateExtent(mapSize)

      // console.log({ zoom, extent })

      map.qgisProjectViewProps = {
        ...map.qgisProjectViewProps,
        size: mapSize,
        projection: view.getProjection().getCode(),
        extent,
        showFullExtent: true,
        minZoom: zoom,
        maxZoom: view.getMaxZoom(),
        constrainResolution: true
      }

      /* map.setView(new View({
        ...map.qgisProjectViewProps,
        // TODO el padding parece buggeado (el orden de elementos)
        // TODO Además parece que se "acumula", hay algo que tener
        // en cuenta que aún no veo
        padding,
        zoom
      })) */

      console.info('Reajustada la vista para encajar en', { bounds, padding, zoom })
    }
  })
}
