// vim:ts=2:sw=2:et
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'

// import Typography from '@mui/material/Typography'
// import IconButton from '@mui/material/IconButton/IconButton'
// import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import Collapse from '@mui/material/Collapse'

import MuiDivider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'

export { CircularProgress }

export { default as BackIcon } from '@mui/icons-material/ChevronLeft'
export { default as InfoIcon } from '@mui/icons-material/BarChart'
export { default as InputIcon } from '@mui/icons-material/Input'
export { default as LayersIcon } from '@mui/icons-material/Layers'
export { default as ChangeIcon } from '@mui/icons-material/ModeOfTravel'
export { default as LocateIcon } from '@mui/icons-material/MyLocation'
export { default as LogoutIcon } from '@mui/icons-material/Logout'
export { default as LoginIcon } from '@mui/icons-material/Login'
export { default as DetailsIcon } from '@mui/icons-material/NotListedLocation'
export { default as MailIcon } from '@mui/icons-material/Email'

export const MenuContent = styled(ListItem)(({ theme }) => ({
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export function MenuLoading (props) {
  return (
    <MenuContent component='div'>
      <CircularProgress sx={{ m: 1 }} />
    </MenuContent>
  )
}

export function MenuListItemButton (props) {
  return (
    <ListItemButton
      sx={{
        '& .MuiListItemIcon-root': { minWidth: 44 }
      }}
      {...props}
    />
  )
}

export function Divider (props) {
  return (
    <MuiDivider {...props} />
  )
}

export function MenuCollapsible (props) {
  const {
    icon,
    title,
    collapsed,
    onCollapse,
    children,
    ...other
  } = props

  return (
    <>
      <MenuTrigger
        icon={icon}
        title={title}
        selected={!collapsed}
        onClick={event => onCollapse(event, collapsed)}
      />
      <Collapse in={!collapsed} timeout='auto' unmountOnExit>
        <ListItem disablePadding {...other}>
          {children}
        </ListItem>
      </Collapse>
    </>
  )
}

MenuCollapsible.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired
}

export function MenuTrigger (props) {
  const { icon, title, selected, onClick, ...other } = props
  return (
    <MenuListItemButton
      selected={selected}
      onClick={onClick}
      {...other}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </MenuListItemButton>
  )
}

MenuTrigger.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export function MenuTriggerIago ({ secondaryAction, ...props }) {
  return (
    <ListItem
      secondaryAction={secondaryAction}
    >
      <MenuTrigger {...props} />
    </ListItem>
  )
}
