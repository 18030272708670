// vim:ts=2:sw=2:et

import React from 'react'

import { FieldAlone, Option } from '@grupomarea/roo-www-abcs/Field'

export const UsoContext = React.createContext([null, () => null])

export function useTipologia () {
  return React.useContext(UsoContext)
}

export function TipologiaProvider ({ initial, children }) {
  const [state, setState] = React.useState(initial)

  return (
    <UsoContext.Provider value={[state, setState]}>
      {children}
    </UsoContext.Provider>
  )
}

export const UsosContext = React.createContext([])

export function useTipologias () {
  return React.useContext(UsosContext)
}

export function TipologiasProvider ({ usos, initial, children }) {
  return (
    <UsosContext.Provider value={usos}>
      {children}
    </UsosContext.Provider>
  )
}

export function UsosProvider ({ usos, initial, children }) {
  return (
    <TipologiaProvider initial={initial || usos[0]?.tipologia || ''}>
      <TipologiasProvider usos={usos}>
        {children}
      </TipologiasProvider>
    </TipologiaProvider>
  )
}

export function useUso () {
  const usos = useTipologias()
  const [uso] = useTipologia()
  return React.useMemo(() => [
    usos.find(vo => vo.tipologia === uso),
    usos,
    uso
  ], [usos, uso])
}

export function SelectorUso ({ uso, usos, disabled, onChange, sx }) {
  return (
    <FieldAlone
      size='small'
      margin='none'
      variant='outlined'
      fullWidth
      // sx={{ maxWidth: '50%' }}
      select
      // helperText='Selector Tipologia Constructiva'
      defaultValue={uso || ''}
      label='Uso constructivo'
      value={uso || ''}
      disabled={disabled}
      onChange={onChange}
      sx={sx}
    >
      {usos.map(vo => (
        <Option key={vo.tipologia} value={vo.tipologia}>
          {vo.desc_tipologia}
        </Option>
      ))}
    </FieldAlone>
  )
}
