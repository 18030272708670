import Collection from 'ol/Collection'

import View from './View'

import { loadQGISProject } from './www'
import { createLayers } from './layer'

/**
 * No exportar "por comodidad" aligera los bundles
 * Lo que sí se usa en este archivo tiene sentido exportarlo, especialmente
 * aquello que se diseña para usar del lado del consumer
 */
export { View }

export async function loadProject (url, options = {}) {
  const { project, styles } = await loadQGISProject(url, options.map)

  // TODO tiene sentido que sea asíncrono?
  return await generateMapOptions(project, styles, options)
}

export async function generateMapOptions (project, styles, options = {}) {
  const {
    bus,
    view = new View(),
    overlaps = (/* layer */) => true,
    declutter = [],
    clusterize = {},
    layerSetup = (layer) => layer,
    firezneCompat = false
  } = options

  if (!bus) {
    throw new ReferenceError('Falta el bus de comunicación con el exterior')
  }

  const clusterized = Object.keys(clusterize)

  for (
    const [key, list] of Object.entries({
      declutter, clusterize: clusterized
    })
  ) {
    if (list.some(name => !project.order.includes(name))) {
      console.warn('No se pueden configurar capas que no existen', {
        [`Listado capas opción ${key}`]: list,
        'Capas del proyecto': project.order,
        'Capas inexistentes': list.filter(
          name => !project.order.includes(name)
        )
      })
    }
  }

  const projection = view.getProjection()
  const conf = {
    bus,
    EPSG: projection.getCode(),
    UNIT: projection.getUnits(),
    SCALES: view.getScales(),
    MAX_ZOOM: view.getMaxZoom(),
    MIN_ZOOM: view.getMinZoom(),
    firezneCompat
  }

  return {
    crs: project.bboxCRS,
    bounds: project.bbox,
    layers: (
      await createLayers({
        order: project.order,
        layers: Object.values(project.layers),
        layerStyles: styles
      }, {
        ...conf,
        overlaps,
        clusterize,
        decluttered: declutter
      })
    )
      .map(function configure (layer) {
        if (layer.get('isGroup')) {
          layer.setLayers(
            new Collection(
              layer.getLayers().getArray()
                .map(layer => {
                  return configure(layer)
                })
                .filter(isNotNull)
            )
          )
          // Esto oculta por defecto los grupos de capas que están vacíos
          return layer.getLayers().getLength() ? layer : null
        }
        // TODO comprobar que devuelve instanceof BaseLayer ?
        return layerSetup(layer)
      })
      .filter(isNotNull)
  }
}

const isNotNull = (thing) => thing !== null
