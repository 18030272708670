import QGISProject from './format/QGISProject'
import SLD from './format/SLD'

// TODO esto pertence

const TIMEOUT = 10 * 1000 // milisegundos

async function fetchCapabilities (url, parser) {
  const control = new AbortController()

  const to = setTimeout(() => control.abort(), TIMEOUT)

  return await window.fetch(url, { signal: control.signal })
    .then(response => {
      clearTimeout(to)
      if (response.status >= 500) {
        const error = new Error('Error de comunicación con el servidor')
        error.response = response
        error.statusCode = response.status
        throw error
      }
      return response.text()
    })
    .then(text => {
      const caps = parser.read(text)

      if (!caps) {
        console.log(url, { dto: caps })
        console.error('No se obtuvieron capabilities')
        return null
      }

      return caps
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        const err = new Error('El servicio GIS no está disponible')
        err.statusCode = 503
        err.description = (
          'El servidor que provee los datos necesarios para el mapa está ' +
          'tardando más de lo normal en responder.\nEsto puede ocurrir ' +
          'cuando estamos realizando tareas de mantenimiento en la ' +
          'infraestructura. Debería volver a la normalidad con brevedad.\n' +
          'Intente recargar la página mediante el botón "refrescar" del ' +
          'navegador, transcurridos unos minutos.\n Si el problema persiste' +
          ', contacte con el equipo técnico de Gisgal.'
        )
        err.originalError = error
        throw err
      }
      throw error
    })
}

export async function loadQGISProject (url, map) {
  if (typeof url === 'string') {
    url = new URL(url)
  }

  const format = new QGISProject({ url, map })

  let getProject = '?service=WMS&request=GetProjectSettings'

  if (map) {
    console.warn('Deprecado: El parámetro extra "map" no debe usarse')
    getProject += `&map=${encodeURIComponent(map)}`
  }

  getProject = new URL(getProject, url)

  const project = await fetchCapabilities(getProject, format)

  const styles = await fetchCapabilities(project.styles, new SLD())

  return { url, project, styles }
}

export function computeQueryString (query) {
  return (new URLSearchParams(query)).toString()
}

export function parseQueryString (query) {
  return Object.fromEntries((new URLSearchParams(query)).entries())
}
