// vim:ts=2:sw=2:et

import AbstractModel from './AbstractModel'

import { api, computeQueryString as qs } from '../service/wfs3'
import { Readable } from 'stream'

import JSONbig from 'json-bigint'

const JSONparser = JSONbig({ strict: true })

export default class WFS3Model extends AbstractModel {
  static get project () {
    throw new Error(`<${this}#project> static get project no definido`)
  }

  // typename sería un mejor nombre que collection, creo
  static get collection () {
    throw new Error(`<${this}#collection> static get collection no definido`)
  }

  static getListUrl (ctx, query) {
    return `/${this.project}/wfs3/collections/${this.collection}/items${
      query ? '?' + qs(query) : ''
    }`
  }

  static getItemUrl (ctx, vo) {
    // Especificar la extensión evita problemas cuando el valor de la clave
    // primaria incluye puntos
    return `${this.getListUrl(ctx)}/${vo.pk}.json`
  }

  /*
   * Utilidades para lógica genérica de request/response
   */
  static async loadList (qs = {}, ctx = null) {
    return await api.get(this.getListUrl(ctx), qs, { signal: ctx?.signal })
  }

  static async postList (vo, ctx = null) {
    if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    }
    return await api.post(
      this.getListUrl(ctx), vo.toGeoJSON(), { signal: ctx?.signal }
    )
  }

  static async saveItem (vo, ctx = null) {
    if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    }
    return await api.put(this.getItemUrl(ctx, vo), vo.toGeoJSON(), {
      signal: ctx?.signal
    })
  }

  static async wipeItem (vo, ctx = null) {
    if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    }
    return await api.delete(this.getItemUrl(ctx, vo), { signal: ctx?.signal })
  }

  static async parseResponse (response, expectedStatus = 200) {
    const mime = response.headers.get('content-type')
    let body = await response.text()
    if (/json/.test(mime || '')) {
      try {
        body = JSONparser.parse(body)
      } catch (error) {
        console.warn('Imposible parsear el response', { response })
      }
    }

    const { status: code } = response
    if (code !== expectedStatus) {
      const error = new Error(
        typeof body === 'object' && body !== null
          ? body.message || body[0]?.description || `Error ${code}`
          : `Error ${code}`
      )
      error.severity = 'error' // TODO errores 4xx serían warnings?
      error.method = response.requestMethod || 'desconocido'
      error.requestUrl = response.url
      error.requestBody = response.sentData || 'desconocido'
      error.statusCode = code
      error.responseBody = body
      error.responseType = response.type
      error.response = response

      throw error
    }

    return body
  }
  // postItem

  /**
   * Interfaz CRUD (aka. DAO)
   */
  static async create (vo, opts = {}) {
    // TODO qué carallo hacemos con el ctx ?
    const response = await this.postList(vo, null, opts)

    const body = await this.parseResponse(response, 201)

    return new this(body)
  }

  static async update (vo, opts = {}) {
    const response = await this.saveItem(vo, opts)

    const body = await this.parseResponse(response, 200)

    return body.properties
      ? new this(body.properties, body.geometry)
      : new this(body)
  }

  static async delete (vo, opts = {}) {
    const response = await this.wipeItem(vo, opts)

    const body = await this.parseResponse(response, 200)
    return body
  }

  static async findAll (filters = {}, options = {}) {
    const response = await this.loadList(filters, options)

    const body = await this.parseResponse(response, 200)

    return body.features.map(vo => new this(vo.properties, vo.geometry))
  }

  static async findOne (filters = {}, options = {}) {
    if (Object.keys(filters).length < 1) {
      throw new TypeError('Falta proporcionar filtros')
    }

    const found = await this.findAll(filters, options)

    if (found.length !== 1) {
      throw new Error('Se esperaba exactamente 1 resultado')
    }

    return found.pop()
  }

  static async streamAll (filters = {}, opts = {}) {
    filters = { limit: 1000, offset: 0, ...filters }

    let response = await this.loadList(filters, opts)

    console.warn('Utilizando un parseo nativo que no soporta BigInt')
    response = await this.parseResponse(response, 200)

    const mapFeatures = (feature) => new this(feature.properties)
    let features = response.features.map(mapFeatures)
    let next = response.links.find(link => link.rel === 'next')
    let last = !next

    const that = this
    const stream = new Readable({
      objectMode: true,
      read (size) {
        // Como estamos en object mode hay que ignorar el parámetro size
        if (features.length > 0) {
          while (features.length > 0 && this.push(features.shift())) {
            continue
          }
          if (features.length > 0) return true
        }

        if (!next) return last ? this.push(null) : false

        // console.warn('Ahora tocaría cargar', next)
        response = fetch(next.href.replace(/^http:/, 'https:'))
          .then(async response => that.parseResponse(response, 200))
          .then(response => {
            next = response.links.find(link => link.rel === 'next')
            if (!next) last = true
            features = response.features.map(mapFeatures)
            // console.warn('Nuevo response', { next, features, response })
            this.push(features.shift())
          })
          .catch(error => this.destroy(error))

        next = null
        return false // facilita el manejo del back-pressure
      }/* ,
      destroy () {
      } */
    })

    return stream
  }

  // TODO el findOne debería atacar al endpoint de 1 elemento?

  constructor (dto, geom = null) {
    super(dto)

    this.geom = geom?.coordinates || null

    // console.log('Crear VO', this.constructor.name, dto)

    this._meta = dto._meta || {
      layer: this.constructor.collection,
      layername: this.constructor.collection,
      layertitle: this.constructor.collection, // TODO el título de verdad
      primary: this.constructor.primary
    }

    this.modified_user = dto.modified_user || null
    this.modified_date = dto.modified_date || null
  }
}

export class WFS3AdapterModel extends WFS3Model {
  /**
   * Esta clase adapta la lógica del "update" para las colecciones que
   * acualmente no podemos "actualizar" en QGIS
   */

  static async update (vo) {
    let response = await this.delete(vo)

    console.log('Deleted', response)

    if (response.code !== 200) return response

    console.log('Creando', { vo })
    response = await this.create(vo)

    if (response.code !== 201) return response

    return { ...response, code: 200 }
  }
}
