/**
 * Author: Lorenzo García Rivera <lorenzogrv@mareagestion.com>.
 *
 * This code was based on original work by Stefan Goessener's xml2json.
 * Original work is licensed under Creative Commons GNU LGPL License.
 *
 *  License: http://creativecommons.org/licenses/LGPL/2.1/
 *   Version: 0.9
 *  Author:  Stefan Goessner/2006
 *  Web:     http://goessner.net/
 *
 *  Author notes:
 *
 *  Things I've done are:
 *  - Refactor to fit maximum 80 chars per line (still in progress)
 *  - Refactor according to standardjs style guidelines
 *  - Handle parse of comments (nodeType === 8)
 *  - Remove the custom JSON encoding logic (use JSON.stringify)
 */
export default function xml2json (xml, tab) {
  const X = {
    toObj: function (xml) {
      let o = {}
      if (xml.nodeType === 1) {
        // element node ..
        if (xml.attributes.length) {
          // element with attributes  ..
          for (let i = 0; i < xml.attributes.length; i++) {
            o['@' + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || '').toString()
          }
        }
        if (xml.firstChild) {
          // element has child nodes ..
          let textChild = 0
          let cdataChild = 0
          let hasElementChild = false
          for (let n = xml.firstChild; n; n = n.nextSibling) {
            if (n.nodeType === 1) hasElementChild = true
            else if (n.nodeType === 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++ // non-whitespace text
            else if (n.nodeType === 4) cdataChild++ // cdata section node
          }
          if (hasElementChild) {
            if (textChild < 2 && cdataChild < 2) {
              // structured element with evtl. a single text or/and cdata node ..
              X.removeWhite(xml)
              for (let n = xml.firstChild; n; n = n.nextSibling) {
                if (n.nodeType === 3) {
                  // text node
                  o['#text'] = X.escape(n.nodeValue)
                } else if (n.nodeType === 4) {
                  // cdata node
                  o['#cdata'] = X.escape(n.nodeValue)
                } else if (o[n.nodeName]) {
                  // multiple occurence of element ..
                  if (o[n.nodeName] instanceof Array) {
                    o[n.nodeName][o[n.nodeName].length] = X.toObj(n)
                  } else {
                    o[n.nodeName] = [o[n.nodeName], X.toObj(n)]
                  }
                } else {
                  // first occurence of element..
                  o[n.nodeName] = X.toObj(n)
                }
              }
            } else { // mixed content
              if (!xml.attributes.length) {
                o = X.escape(X.innerXml(xml))
              } else {
                o['#text'] = X.escape(X.innerXml(xml))
              }
            }
          } else if (textChild) {
            // pure text
            if (!xml.attributes.length) {
              o = X.escape(X.innerXml(xml))
            } else {
              o['#text'] = X.escape(X.innerXml(xml))
            }
          } else if (cdataChild) {
            // cdata
            if (cdataChild > 1) {
              o = X.escape(X.innerXml(xml))
            } else {
              for (let n = xml.firstChild; n; n = n.nextSibling) {
                o['#cdata'] = X.escape(n.nodeValue)
              }
            }
          }
        }
        if (!xml.attributes.length && !xml.firstChild) o = null
      } else if (xml.nodeType === 9) {
        // document.node
        o = X.toObj(xml.documentElement)
      } else if (xml.nodeType === 8) {
        // Comment node
        o = xml.textContent
      } else {
        console.error('unhandled node type: ', xml.nodeType, xml)
      }
      return o
    },
    innerXml: function (node) {
      let s = ''
      if ('innerHTML' in node) {
        s = node.innerHTML
      } else {
        const asXml = function (n) {
          let s = ''
          if (n.nodeType === 1) {
            s += '<' + n.nodeName
            for (let i = 0; i < n.attributes.length; i++) {
              s += ' ' + n.attributes[i].nodeName + '="' + (n.attributes[i].nodeValue || '').toString() + '"'
            }
            if (n.firstChild) {
              s += '>'
              for (let c = n.firstChild; c; c = c.nextSibling) {
                s += asXml(c)
              }
              s += '</' + n.nodeName + '>'
            } else {
              s += '/>'
            }
          } else if (n.nodeType === 3) {
            s += n.nodeValue
          } else if (n.nodeType === 4) {
            s += '<![CDATA[' + n.nodeValue + ']]>'
          }
          return s
        }
        for (let c = node.firstChild; c; c = c.nextSibling) {
          s += asXml(c)
        }
      }
      return s
    },
    escape: function (txt) {
      return txt.replace(/[\\]/g, '\\\\')
        .replace(/["]/g, '\\"')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
    },
    removeWhite: function (e) {
      e.normalize()
      for (let n = e.firstChild; n;) {
        if (n.nodeType === 3) { // text node
          if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
            const nxt = n.nextSibling
            e.removeChild(n)
            n = nxt
          } else { n = n.nextSibling }
        } else if (n.nodeType === 1) { // element node
          X.removeWhite(n)
          n = n.nextSibling
        } else {
          // any other node
          n = n.nextSibling
        }
      }
      return e
    }
  }
  if (xml.nodeType === 9) { // document node
    xml = xml.documentElement
  }
  return JSON.stringify({ [xml.nodeName]: X.toObj(X.removeWhite(xml)) })
}
