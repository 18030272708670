// vim:ts=2:sw=2:et
/**
 * Licensed under BSD-3-Clause © Lorenzo Garcia Rivera 2023
 */

import React from 'react'

/**
 * Eases using a Storage-backed state
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
 */
export function useStoredState (storage, key, initial) {
  const [state, setState] = React.useState(() => {
    try {
      const item = storage.getItem(key)
      return item ? JSON.parse(item) : initial
    } catch (error) {
      console.error(error)
      return initial
    }
  })

  const storeState = (value) => {
    try {
      const newState = typeof value === 'function' ? value(state) : value
      if (typeof newState === 'function') {
        throw new TypeError(`Can't store a function as ${key}`)
      }
      storage.setItem(key, JSON.stringify(newState))
      setState(newState)
    } catch (error) {
      console.error(error)
    }
  }

  return [state, storeState]
}

/**
 * Eases using a localStorage-backed state
 */
export function useLocalStorage (key, initial) {
  return useStoredState(window.localStorage, key, initial)
}

/**
 * Eases using a sessionStorage-backed state
 */
export function useSessionStorage (key, initial) {
  return useStoredState(window.sessionStorage, key, initial)
}
