// vim:ts=2:sw=2:et
import React from 'react'

import { map } from './propTypes'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'

// import GroupIcon from '@mui/icons-material/ArrowRight'
// TODO import OptionsIcon from '@mui/icons-material/Settings'
// import OptionsIcon from '@mui/icons-material/Architecture'
import ShowFondosIcon from '@mui/icons-material/Wallpaper'
import HideFondosIcon from '@mui/icons-material/ExpandLess'
// import WarnSourceIcon from '@mui/icons-material/RunningWithErrors'

import SidebarListItemButton from './SideBarListItemButton'

import CapasList from './CapasList'
import MenuCapasList from './MenuCapasList'
import { CapaFondo } from './Capa'

import { useGetLayers } from './hooks'

const _fondos = 'capas-base'

const isBaseLayer = (capa) => capa.get('type') === 'base'

export default function MenuCapas (props) {
  const { map } = props

  const [expanded, setExpanded] = React.useState([])

  // Este handler es específico del primer nivel, y permite expandir más de
  // un grupo a la vez
  const expandHandler = (layer) => (event, sense) => {
    // sense indica el estado destino de expansión
    if (layer === _fondos) {
      console.warn('Falta la lógica de cambio a fondos')
      return
    }
    // console.warn('Grupo principal', 'toggleExpand', layer, sense)
    setExpanded(
      sense
        ? prev => prev.includes(layer) ? prev : prev.concat(layer)
        : prev => prev.includes(layer) ? prev.filter(l => l !== layer) : prev
    )
  }

  const _capas = useGetLayers(map)
  const [capas, fondos] = React.useMemo(() => {
    return [
      // Las capas "base" son las que marcamos como "anunciar capa de fondo" en
      // el proyecto QGIS. Las que no son, no se marcan con ningún tipo
      // Esto también permite indicar un tipo cualquiera para evitar que la capa
      // salga en el menú
      _capas.filter(capa => typeof capa.type === 'undefined'),
      _capas.filter(capa => capa.type === 'base')
    ]
  }, [_capas])

  const fondoChanger = (_capa) => event => {
    const layers = map.getLayers().getArray().filter(isBaseLayer)

    let actual = layers.find(capa => capa.getVisible())
    actual && actual.setVisible(false)

    actual = layers.find(capa => capa.get('name') === _capa.name)
    actual && actual.setVisible(true)
  }

  // console.info('Render menú capas', { capas, fondos, _fondos, expanded })

  return (
    <MenuCapasList>
      <Collapse in={expanded !== _fondos} timeout='auto'>
        <CapasList
          capas={capas}
          expanded={expanded}
          expandHandler={expandHandler}
        />
      </Collapse>
      {fondos.length > 1 && (
        <>
          <SidebarListItemButton
            selected={expanded === _fondos}
            onClick={e => expandHandler(_fondos)(e, expanded !== _fondos)}
            // disableGutters
          >
            <ListItemIcon>
              {expanded === _fondos ? <HideFondosIcon /> : <ShowFondosIcon />}
            </ListItemIcon>
            <ListItemText primary='Fondos' />
          </SidebarListItemButton>
          <Collapse in={expanded === _fondos} timeout='auto'>
            <ListItem>
              <MenuCapasList>
                {fondos.map(capa =>
                  <CapaFondo
                    key={capa.name}
                    capa={capa}
                    onClick={fondoChanger(capa)}
                  />
                )}
              </MenuCapasList>
            </ListItem>
          </Collapse>
        </>
      )}
    </MenuCapasList>
  )
}

MenuCapas.propTypes = {
  map
}
