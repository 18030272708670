import {
  assertDTO,
  getDotted,
  keyvalObjectReducer
} from './XML2JSONFormat'

/*
* Leeremos extents de capas según define OpenLayers, es decir
* [minx, miny, maxx, maxy]
* @see https://openlayers.org/en/latest/apidoc/module-ol_extent.html#~Extent
*/
const EXTENT_KEYS = ['minx', 'miny', 'maxx', 'maxy']

/**
 * No encontré si existe un estándar (no busqué mucho), por lo que establezco
 * un orden equivalente a EXTENT_KEYS
 */
const EXTENT_GBOX = [
  'westBoundLongitude',
  'southBoundLatitude',
  'eastBoundLongitude',
  'northBoundLatitude'
]

/**
 * En QGGIS pueden existir capas sin geometría, y este string lo indica
 */
const NO_GEOMETRY = 'NoGeometry'

export default class QGISProjectLayer {
  constructor (options = {}) {
    this.WFSLayers = options.WFSLayers || []
    this.projectUrl = options.projectUrl || '/?'
    this.projectMap = options.projectMap || null
  }

  read (source) {
    assertDTO(source)

    const isGroup = !!source.Layer
    const geometryType = source['@geometryType']

    const descriptor = {
      name: source.Name,
      title: source.Title,
      treename: source.TreeName,
      // en las capas WMS no existe el dato, y escapamos de los "undefined"
      ...(typeof geometryType !== 'undefined' ? { geometryType } : {}),
      isWFS: this.WFSLayers.includes(source.TreeName),
      isWMS: !!source.WMSBackgroundLayer,
      isBase: !!Number(source.WMSBackgroundLayer),
      source: null,
      isGroup,
      projectUrl: this.projectUrl,
      projectMap: this.projectMap,
      attributions: (
        source.Attribution ? this.readAttribution(source.Attribution) : null
      ),
      isQueryable: !!Number(source['@queryable']),
      opacity: Number(source['@opacity']),
      qgisVisible: !!Number(source['@visible']),
      visible: !!Number(source['@visibilityChecked']),
      description: (
        source.Abstract
          ? source.Abstract.replace(/\\"/g, '"')
          : undefined
      ),
      ...(
        // Los grupos de capas no tienen bounds
        // las capas WMS no tienen geometryType, pero sí que tienen bounds
        !isGroup && geometryType !== NO_GEOMETRY
          ? {
              crs: source.CRS,
              bbox: this.readBbox(source.BoundingBox),
              gbox: this.readGbox(source.EX_GeographicBoundingBox)
            }
          : {}
      ),
      styles: []
    }

    if (isNaN(descriptor.opacity)) descriptor.opacity = 1

    if (isGroup) {
      descriptor.layers = (
        Array.isArray(source.Layer) ? source.Layer : [source.Layer]
      ).map(l => this.read(l))

      descriptor.mutuallyExclusive = !!Number(source['@mutuallyExclusive'])
      // console.warn('Readed group', descriptor.name, { descriptor, source })
      return descriptor
    }

    const style = source.Style
    if (style) {
      descriptor.styles = Array.isArray(style)
        ? style.map(dto => this.readStyle(dto))
        : [this.readStyle(style)]
    }

    if (source.MaxScaleDenominator) {
      descriptor.maxScale = Number(source.MaxScaleDenominator)
    }
    if (source.MinScaleDenominator) {
      descriptor.minScale = Number(source.MinScaleDenominator)
    }

    // TODO deprecate?
    if (Number(source.WMSBackgroundLayer)) {
      descriptor.type = 'base'
    }

    if (source.WMTSDataSource) {
      descriptor.source = this.readWMTSSource(source.WMTSDataSource)
    }
    if (source.WMSDataSource) {
      descriptor.source = this.readWMSSource(source.WMSDataSource)
    }
    if (descriptor.source) {
      descriptor.source.zmax = Number(descriptor.source.zmax)
      descriptor.source.zmin = Number(descriptor.source.zmin)
    }

    if (descriptor.isWMS && !descriptor.source) {
      console.warn(descriptor.name, 'no define source', { source, descriptor })
    }

    if (source.Attributes) {
      const { Attribute } = source.Attributes
      descriptor.attributes = (
        Array.isArray(Attribute) ? Attribute : [Attribute]
      ).map(attr => this.readAttribute(attr))
    }
    if (source.PrimaryKey) {
      descriptor.pk = source.PrimaryKey.PrimaryKeyAttribute
    }

    return descriptor
  }

  readAttribution (dto) {
    assertDTO(dto)

    const title = dto.Title

    return dto.OnlineResource
      ? `<a href="${
           getDotted(dto, 'OnlineResource.@xlink:href')
         }" target="_blank">${title}</a>`
      : `${title || ''}`
  }

  readBbox (dto) {
    if (Array.isArray(dto)) {
      const bbox = this.readBbox(dto[0])
      bbox.other = dto.slice(1).map(dto => this.readBbox(dto))
      return bbox
    }

    assertDTO(dto)

    const extent = EXTENT_KEYS.map(k => Number(dto[`@${k}`]))

    if (extent.some(num => !isFinite(num))) {
      console.warn('El bbox produce números no finitos', {
        source: dto, parsed: extent
      })
      return
    }

    return { crs: dto['@CRS'], extent }
  }

  readGbox (dto) {
    assertDTO(dto)

    const extent = EXTENT_GBOX.map(k => Number(dto[k]))

    if (extent.some(num => !isFinite(num))) {
      console.warn('El Gegraphic bbox produce números no finitos', {
        source: dto, parsed: extent
      })
      return
    }

    return { crs: 'EPSG:4326', extent }
  }

  readStyle (dto) {
    assertDTO(dto)

    return {
      name: dto.Name,
      title: dto.Title,
      legend: dto.LegendURL
        ? {
            url: getDotted(dto, 'LegendURL.OnlineResource.@xlink:href'),
            format: getDotted(dto, 'LegendURL.Format')
          }
        : null
    }
  }

  readAttribute (dto) {
    assertDTO(dto)
    return {
      name: dto['@name'],
      type: dto['@type'],
      length: dto['@length'],
      comment: dto['@comment'],
      typeName: dto['@typeName'],
      editType: dto['@editType'],
      precision: dto['@precision']
    }
  }

  readSource (str) {
    return str
      .split('&amp;')
      .map(param => {
        const [key, val] = param.split('=').map(decodeURIComponent)
        return { key, val }
      })
      .reduce(keyvalObjectReducer, { anouncedUrl: str })
  }

  readWMSSource (str) {
    return {
      ...this.readSource(str),
      tipo: 'WMS'
    }
  }

  readWMTSSource (str) {
    return {
      ...this.readSource(str),
      tipo: 'WMTS'
    }
  }
}
