import Chip from '@mui/material/Chip'

export default function MuiChip (props) {
  const { text, textcolor, backcolor, hoverbackgroud, hovertext, onClick } = props
  return (
    <Chip
      onClick={onClick}
      label={text}
      sx={{
        color: textcolor,
        backgroundColor: backcolor,
        fontWeight: 'bold',
        '&:hover': {
          color: hovertext,
          backgroundColor: hoverbackgroud
        },
        ...props.sx
      }}
      {...props}
    />
  )
}
