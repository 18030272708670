// vim:ts=2:sw=2:et:

import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import RooTypes from '../prop-types'

import { toPascalCase } from '../FeatureViewer'

import {
  DefaultCollectionLoader,
  DefaultCollectionLayout,
  DefaultFeatureCollection
} from './DefaultComponents'

import { ViewerContainer } from '../UtilsLayout'

import LinearProgress from '@mui/material/LinearProgress'

export default function CollectionView ({
  Component = DefaultCollectionLayout,
  fallback = (
    <ViewerContainer>
      <LinearProgress sx={{ mt: 10, mb: 10 }} />
    </ViewerContainer>
  ),
  ...props
}) {
  const {
    CollectionLoader,
    ProviderComponent,
    inject,
    ...components
  } = useCollectionComponents({ fallback, ...props })

  return (
    <ProviderComponent {...components}>
      <CollectionLoader Component={Component} {...props} {...components} />
    </ProviderComponent>
  )
}

CollectionView.propTypes = {
  Component: PropTypes.elementType,
  fallback: PropTypes.node,
  // featureTypes: => Objecto que viene del "features/index.jsx"
  featureTypes: RooTypes.featureTypes.isRequired,
  details: RooTypes.selectionDetailsOptionalFeature
}

export function useCollectionComponents ({
  limit = 100,
  featureTypes = {},
  toCodename = toPascalCase,
  details,
  fallback,
  ...props
}) {
  const { Collection, Provider, Loader, ...etc } = React.useMemo(() => {
    const cname = toCodename(details.layer.name)
    const type = featureTypes[cname] || {}

    return {
      ...type,
      codename: cname,
      Provider: type?.ToolsProvider || (({ children }) => children),
      Loader: type?.CollectionLoader || DefaultCollectionLoader,
      Collection: type?.Collection || DefaultFeatureCollection,
      Model: type?.Model || details.layer.name
    }
  }, [featureTypes, details])

  const inject = React.useMemo(() => {
    return { details, fallback, ...etc, ...props }
  }, [details, fallback, etc, props])

  return {
    // Este componente carga la lista de features a mostrar
    CollectionLoader ({ children, ...props }) {
      return <Loader {...props} {...inject}>{children}</Loader>
    },
    // Este provider es específico para las "Tools" de la vista "Collection"
    ProviderComponent ({ children, ...props }) {
      return (
        <Provider {...props} {...inject}>{children}</Provider>
      )
    },
    CollectionComponent (props) {
      return (
        <Suspense fallback={fallback}>
          <Collection {...props} {...inject} />
        </Suspense>
      )
    },
    inject,
    ...etc
  }
}
