import React from 'react'
import PropTypes from 'prop-types'

import { shapeCapa, shapeFondo } from './propTypes'

import Collapse from '@mui/material/Collapse'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import Radio from '@mui/material/Radio'

import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

import GroupIcon from '@mui/icons-material/ArrowRight'
// TODO import OptionsIcon from '@mui/icons-material/Settings'
import OptionsIcon from '@mui/icons-material/Architecture'
import ShowFondosIcon from '@mui/icons-material/Wallpaper'
// import HideFondosIcon from '@mui/icons-material/ExpandLess'
import WarnSourceIcon from '@mui/icons-material/RunningWithErrors'

import MenuCapasList from './MenuCapasList'
import { CapasListContainer } from './CapasList'

import { useOpenlayersEvents } from './hooks'

// Los estados son duplas [elementosTotales, elementosCargados]
const moreLoading = prev => !prev ? [1, 0] : [prev[0] + 1, prev[1]]
const lessLoading = prev =>
  !prev
    ? prev // Así al menos no explota, pero el spinner no funciona bien
    : (prev[1] || 0) + 1 === prev[0] ? null : [prev[0], prev[1] + 1]

export default function Capa (props) {
  const {
    capa,
    expanded,
    onExpand,
    exclusiveSource,
    onClick,
    variant = 'auto'
  } = props

  const [visible, setVisible] = React.useState(capa.visible)
  const [loading, setLoading] = React.useState(null)
  const [warning, setWarning] = React.useState(null)

  useOpenlayersEvents(capa.layer, {
    'change:visible' (event) {
      // capa.isGroup && console.warn('Modificar visible grupal', capa.layer)
      const newVisible = event.target.get(event.key)
      setVisible(newVisible)
      // console.warn('la visibilidad de', capa.name, 'pasa a', newVisible)
      onExpand && onExpand(event, newVisible)
      !newVisible && setWarning(false)
    }
  }, [capa, setVisible, onExpand])

  useOpenlayersEvents(capa.layer.getSource && capa.layer.getSource(), {
    'error' (event) {
      setWarning(true)
      console.warn('El source de', capa.name, 'ha tenido un error', { event })
    },
    'featuresloadstart' (event) { setLoading(true) },
    'featuresloaderror' (event) { setLoading(false); setWarning(true) },
    'featuresloadend' (event) { setLoading(false) },
    'imageloadstart' (event) { setLoading(moreLoading) },
    'imageloaderror' (event) { setLoading(lessLoading); setWarning(true) },
    'imageloadend' (event) { setLoading(lessLoading) },
    'tileloadstart' (event) { setLoading(moreLoading) },
    'tileloaderror' (event) { setLoading(lessLoading); setWarning(true) },
    'tileloadend' (event) { setLoading(lessLoading) }
  }, [capa])

  const toggleVisible = (event) => {
    if (!visible && exclusiveSource) {
      exclusiveSource.getLayers().forEach(layer => layer.setVisible(false))
      // P.O. Fernando, no expandimos por defecto
      // La opinión ha cambiado
      // onExpand && onExpand(event, visible)
    }
    capa.layer.setVisible(!visible)
  }

  const isBase = capa.type === 'base'
  const labelId = `capa-${capa.name}-desc`

  return (
    <>
      <ListItem
        dense
        disableGutters
        secondaryAction={
          <>
            {(
              <IconButton
                // edge='end'
                // size={'small'}
                disabled={!!loading || !onExpand}
                aria-label='opciones de capa'
                onClick={event => onExpand(event, !expanded)}
              >
                {
                  capa.isGroup
                    ? <GroupIcon />
                    : warning
                      ? <WarnSourceIcon color='warning' />
                      : isBase
                        ? <ShowFondosIcon />
                        : <OptionsIcon sx={{ color: 'transparent' }} />
                }
              </IconButton>
            )}
            {!!loading && (
              <CircularProgress
                size='100%'
                sx={{
                  // color: green[500],
                  position: 'absolute',
                  transform: 'scale(.8)',
                  top: '50%',
                  left: '50%',
                  mt: '-50%',
                  ml: '-50%',
                  zIndex: 1
                }}
                variant={
                  (Array.isArray(loading) ? '' : 'in') + 'determinate'
                }
                value={
                  Array.isArray(loading)
                    ? ((loading[1] || 1) * 100) / loading[0]
                    : undefined
                }
                // https://mui.com/material-ui/react-progress/#limitations
                disableShrink={Array.isArray(loading) ? undefined : true}
              />
            )}
          </>
        }
      >
        <ListItemButton
          role={undefined}
          disableGutters
          onClick={onClick || toggleVisible}
          className={expanded ? 'expanded' : undefined}
          selected={visible} // expanded}
          // disabled={loading}
          title={capa.description}
        >
          <ListItemIcon
            className={capa.isGroup ? 'LayerGroup' : undefined}
          >
            {((isBase &&
            !exclusiveSource &&
            variant === 'auto') ||
            variant === 'checkbox') && (
              <Checkbox
                // edge='start'
                // size='small'
                checked={visible}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
            {((isBase &&
            exclusiveSource &&
            variant === 'auto') ||
            variant === 'radio') && (
              <Radio
                size='small'
                sx={{ p: '2px' }}
                checked={visible}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
            {((!isBase &&
            !exclusiveSource &&
            variant === 'auto') ||
            variant === 'switch') && (
              <Switch
                size='small'
                checked={visible}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
            {(!isBase &&
            exclusiveSource &&
            (variant === 'auto' ||
            variant === 'radio')) && (
              <Radio
                size='small'
                sx={{ p: '2px' }}
                checked={visible}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
          </ListItemIcon>
          <ListItemText
            className={capa.isGroup ? 'LayerGroup' : undefined}
            primary={capa.title}
            id={labelId}
          />
        </ListItemButton>
      </ListItem>
      {!isBase && (
        <Collapse in={expanded} timeout='auto'>
          <ListItem
            dense // ={!capa.isGroup}
            disableGutters // ={capa.isGroup}
            disablePadding
            className={capa.isGroup ? 'LayerGroup' : 'LayerOptions'}
            // sx={capa.isGroup ? { pl: 4 } : undefined}
          >
            {capa.isGroup && (
              <MenuCapasList disablePadding>
                <CapasListContainer
                  source={capa.layer}
                  // expanded={props.expandedState}
                  // expandHandler={props.expandHandler}
                />
              </MenuCapasList>
            )}
            {!capa.isGroup && (
              <ListItemText secondary={`Opciones de la capa ${capa.name}`} />
            )}
          </ListItem>
        </Collapse>
      )}
    </>
  )
}

Capa.propTypes = {
  capa: shapeCapa.isRequired,
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func,
  onClick: PropTypes.func
}

export function CapaFondo (props) {
  const { onClick, ...other } = props

  return (
    <Capa expanded={false} onClick={onClick} {...other} />
  )
}

CapaFondo.propTypes = {
  capa: shapeFondo.isRequired,
  onClick: PropTypes.func.isRequired
}
