// vim:ts=2:sw=2:et

import React from 'react'

import {
  ViewerContainer,
  GridItem,
  GridContainer,
  Skeleton
} from '../UtilsLayout'

import { QueryError } from '../UtilsQuery'

import { Field } from '../Field'

/**
 * Este componente es un construct para facilitar el manejo de estados
 * complejos que afectan a múltiples componentes del feature type
 *
 * Por defecto sólo devuelve sus hijos, dado que no provee ningún contexto
 */
export function DefaultFeatureProvider (props) {
  return props.children
}

/**
 * Este componente construye un título por defecto para cualquier feature
 */
export function DefaultFeatureTitle (props) {
  const { fid, details } = props

  return (
    <span>
      Objeto
      {' '}
      <code style={{ padding: 4, backgroundColor: 'lightgray' }}>
        {`${fid}`}
      </code>
      {' '}
      de la capa
      {' '}
      <em>{details.layer.title}</em>
    </span>
  )
}

/**
 * Este componente muestra un formulario de sólo lectura para cualquier feature
 * Por el momento es una implementación muy simplista, e incluye un visor json
 * de todos los datos del feature
 */
export function DefaultFeatureViewer (props) {
  const { loading, errored, query, details, feature, codename } = props

  if (loading) {
    // TODO esto obviamente no es definitivo
    return (
      <ViewerContainer>
        <Skeleton
          loading={loading}
          width='30vw'
          height={30}
        />
      </ViewerContainer>
    )
  }

  if (errored) {
    return (
      <ViewerContainer>
        <QueryError title='Error al cargar el feature' query={query} />
      </ViewerContainer>
    )
  }

  return (
    <ViewerContainer>
      <GridContainer spacing={2} justifyContent='center' alignItems='center'>
        <PropView
          prop={`Identificador (${details.layer.pk})`}
          value={feature[details.layer.pk]}
        />
        {Object.entries(feature).map(([prop, value]) => (
          <PropView key={prop} {...{ prop, value, primary: details.layer.pk }} />
        ))}
        <GridItem xs={12}>
          <JSONView title={codename} vo={feature} />
        </GridItem>
      </GridContainer>
    </ViewerContainer>
  )
}

function PropView ({ prop, value, primary }) {
  if (prop === primary) return null

  if (typeof value === 'object' && value !== null) {
    return (
      <GridItem xs={12}>
        <JSONView title={prop} vo={value} />
      </GridItem>
    )
  }

  return (
    <Field
      xs={12}
      sm={6}
      lg={3}
      label={prop}
      name={prop}
      value={value === null ? 'NULL' : `${value}`}
      readOnly
    />
  )
}

export function JSONView ({ title, vo }) {
  return (
    <details>
      <summary
        style={{
          cursor: 'pointer',
          fontFamily: 'monospace',
          padding: '.5em .4em',
          background: 'lightgray',
          borderRadios: '.2em'
        }}
      >
        {title}
      </summary>
      <pre
        style={{ padding: '0 2em', maxWidth: '100%', overflow: 'auto' }}
      >
        {JSON.stringify(vo, null, 4)}
      </pre>
    </details>
  )
}
