// vim:ts=2:sw=2:et

/**
 * Formatea un número para mostrarlo en la interfaz
 * @param {number} number - Número a formatear
 * @param {number} places=2 - Número de decimales a mostrar
 * @returns {string} número formateado
 */
export const formatNumber = (number, places = 2) =>
  // Se usa el locale aleman porque el español no añade puntos en los millares
  Number(number).toLocaleString('de-DE', {
    minimumFractionDigits: places,
    maximumFractionDigits: places
  })
