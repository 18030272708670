// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Chip from '@mui/material/Chip'

import { ViewsPropType } from './prop-types.js'

export default function ViewSwitchToolBar ({
  view = null,
  views = [],
  onSelect = ({ event, prev, dest }) => null,
  reverse = false,
  children,
  sx = {},
  ...etc
}) {
  return (
    <Toolbar
      disableGutters
      variant='dense'
      sx={{
        flexDirection: reverse ? 'row-reverse' : 'row',
        justifyContent: 'space-between',
        ...sx
      }}
      {...etc}
    >
      {children}
      <Stack
        direction='row'
        spacing={1}
        className='viewSwitch'
        sx={{ ml: 1, maxWidth: '100%', overflow: 'auto' }}
      >
        {views.map((item) =>
          <Chip
            key={item.view}
            sx={
              view === item.view
                ? {
                    backgroundColor: 'primary.light',
                    fontWeight: 'bold',
                    color: 'white',
                    '&:hover': { color: 'black' }
                  }
                : { color: 'black' }
            }
            size='small'
            variant={view === item.view ? 'filled' : 'outlined'}
            onClick={(event) => onSelect({ event, prev: view, next: item.view })}
            label={item.label}
          />
        )}
      </Stack>
    </Toolbar>
  )
}

ViewSwitchToolBar.propTypes = {
  view: PropTypes.string,
  views: PropTypes.arrayOf(ViewsPropType).isRequired,
  onSelect: PropTypes.func.isRequired,
  reverse: PropTypes.bool,
  sx: PropTypes.object
}
