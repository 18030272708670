// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import { ViewerContainer, Stack } from '@grupomarea/roo-www-abcs/UtilsLayout'
import ViewSwitchToolBar from '@grupomarea/roo-www-abcs/ViewSwitch'

import { ModalTitle } from '@grupomarea/roo-www-abcs/Modal'

/*
 * Este es el FeatureViewer por defecto para el "Modo Kiosko" (Modal)
 */
export default function CustomModalViewer ({
  user,
  // Las props que siguen son comunes a todos los FeatureViewer
  TitleComponent,
  ViewerComponent,
  MenuComponent,
  views,
  viewKioskFilter,
  // Las props que siguen las inyecta el FeatureViewController
  view,
  ViewComponent,
  handleChangeView,
  kioskMode,
  ...props
}) {
  const kioskViews = React.useMemo(() => {
    if (user.isSudo) return views
    return viewKioskFilter ? views.filter(viewKioskFilter) : views
  }, [user, views])

  return (
    <>
      <ModalTitle><TitleComponent /></ModalTitle>
      <ViewerContainer>
        <ViewSwitchToolBar
          view={view}
          views={kioskViews}
          onSelect={handleChangeView}
          sx={{ width: '100%' }}
          reverse
          className='viewSwitchModal'
        >
          <Stack
            direction='row'
            spacing={2}
            sx={{
              py: 1,
              ml: 2,
              alignItems: 'start',
              justifyContent: 'flex-end',
              flexGrow: 1,
              maxWidth: '70%'
            }}
          >
            <MenuComponent
              user={user}
              view={view}
              setView={handleChangeView}
              kioskMode={kioskMode}
            />
          </Stack>
        </ViewSwitchToolBar>
      </ViewerContainer>
      <ViewerContainer
        className='modalContent'
        sx={{
          overflowY: 'auto',
          maxHeight: '600px',
          minHeight: '600px',
          px: 0
        }}
      >
        {!props.loading && (
          <ViewerComponent
            user={user}
            view={view}
            Vista={ViewComponent}
            kioskMode={kioskMode}
            {...props}
          />
        )}
      </ViewerContainer>
    </>
  )
}

CustomModalViewer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTransitionEnd: PropTypes.func
}
