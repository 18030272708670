import React from 'react'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Modal from '@mui/material/Modal'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

// const error = { description: '', message: 'error gordaco' }

export default function CriticalErrorModal (props) {
  const { error } = props
  return (
    <Modal
      open={!!error}
      // aria-labelledby='status-modal-title'
      // aria-describedby='status-modal-text'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxWidth: '100vw',
          outline: 'none',
          p: 1
        }}
      >
        {/* En caolín se utiliza como info también */}
        {/* (!props.map && !error) && (
          <Alert severity='info'>Cargando recursos</Alert>
        ) */}
        {error && (
          <Alert severity={error.description ? 'warning' : 'error'}>
            {error.description && (
              <>
                <AlertTitle>{error.message}</AlertTitle>
                {error.description.split('\n').map(p =>
                  <p key={p}>{p}</p>
                )}
              </>
            )}
            {!error.description && (
              <>
                <AlertTitle>Error al iniciar</AlertTitle>
                <p>
                  Se ha producido un error crítico irrecuperable
                </p>
                <p>
                  Por favor, contacte inmediatamente con el equipo técnico de
                  {' '}<Link href='https://gisgal.com#contacto'>Gisgal</Link>
                </p>
                <p>Lamentamos las molestias</p>
              </>
            )}
          </Alert>
        )}
      </Box>
    </Modal>
  )
}
