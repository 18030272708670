import { METERS_PER_UNIT } from 'ol/proj/Units'

/**
 * @function
 * Calcula la escala en base a una resolución y una unidad
 * Su uso principal es interno en View
 *
 * @param resolution {Number} resolución de la vista
 * @param unit {String} unidad de la vista
 * @param dpi {String} desidad de píxeles de la pantalla
 * @returns {Number}
 */
export const calculateScale = (resolution, unit, dpi = DPI) =>
  resolution * METERS_PER_UNIT[unit] * INCH_PER_METER * dpi

/**
 * Densidad de píxeles de la pantalla del dispositivo
 *
 * TODO: Detectar mejor los DPI
 * see https://stackoverflow.com/q/476815/1894803
 */
export const DPI = (window ? window.devicePixelRatio : 1) * 96

/**
 * Equivalencia entre metros y pulgadas (pulgadas por metro)
 */
export const INCH_PER_METER = 39.3701

// Calcula el nivel de zoom mínimo para una escala máxima dada
export function getMinZoomForMaxScale (maxScale, initial, SCALES = {}) {
  let minZoom = initial

  while (SCALES[minZoom] > maxScale) {
    if (typeof SCALES[++minZoom] !== 'number') {
      console.warn('Indeterminable minZoom', { maxScale, minZoom, SCALES })
    }
  }

  return minZoom
}

// Calcula el nivel de zoom máximo para una escala mínima dada
export function getMaxZoomForMinScale (minScale, initial, SCALES = {}) {
  let maxZoom = initial

  while (SCALES[maxZoom - 1] <= minScale) {
    if (typeof SCALES[--maxZoom] !== 'number') {
      console.warn('Indeterminable maxZoom', { minScale, maxZoom, SCALES })
    }
  }

  return maxZoom
}
