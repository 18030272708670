// vim:ts=2:sw=2:et

import React from 'react'

// OJO: Aún estoy investigando el tema del Context, no tengo claro cómo va
import {
  // TipologiaProvider,
  // TipologiasProvider,
  UsosProvider,
  useTipologia,
  SelectorUso
} from './ContextoTipologia'

import {
  EDIFICIO,
  COMUN_USO,
  RIESGO_1,
  RIESGO_2,
  RIESGO_3,
  RESUMEN,
  FORM_MENSAJES,
  MENSAJERIA
} from './common/views'

/**
 * Este componente reemplaza la vista "normal" tipo tabla con el
 * listado específico que usa AREA para mostrar el censo.
 *
 * Esto anula por completo el CollectionViewer
 */
export const MainPanelContent = React.lazy(
  () => import('./VmCenso/ListadoConstrucciones')
)

export const SearchComponent = React.lazy(
  () => import('./SearchComponent')
)

const MunicipioParcelaTitler = ({ feature }) => (
  feature ? `RC: ${feature.rc_parcela}` : 'Oops!'
)

const kioskoFilter = (view) => ([EDIFICIO, COMUN_USO, RESUMEN].includes(view))

// Vistas que usan los featureTypes principales
const views = [
  EDIFICIO, COMUN_USO,
  RESUMEN, RIESGO_1, RIESGO_2, RIESGO_3,
  FORM_MENSAJES, MENSAJERIA
]

const viewsFilter = (view, user) => (
  !(!user.hasAreaAdmin && (view === MENSAJERIA || view === FORM_MENSAJES))
)

/**
 * Índice de "featureTypes"
 */
export default {
  MapaMensajes: {
    // El feature MapMensajes incluye rcParcela y dgcMunicipio
    // Sólo es seleccionable por sudo
    Viewer: React.lazy(() => import('./Mensajes/ViewerMapaMensajes'))
  },
  Municipios: {
    Titler: ({ feature }) => <b>Municipio de {feature.nombre}</b>,
    Viewer: React.lazy(() => import('./Territorio/MunicipioViewer'))
  },
  Construcciones: {
    Titler: MunicipioParcelaTitler,
    // ({ feature: vo }) => `Construciones en ${vo.direccion}`,
    views,
    // Esto es provisional, y solo afecta de momento en <CustomModalViewer>
    viewKioskFilter: kioskoFilter,
    MenuProvider ({ feature, children }) {
      return (
        <UsosProvider usos={feature.usos}>
          {children}
        </UsosProvider>
      )
    },
    // Menu: () => 'Aquí estaría el menú (Construcciones)',
    Menu: ({ view, feature }) => {
      const [uso, setUso] = useTipologia()
      return (
        <>
          <SelectorUso
            uso={uso}
            usos={feature.usos}
            disabled={view === EDIFICIO.view}
            onChange={event => setUso(event.target.value)}
          />
        </>
      )
    },
    Viewer: React.lazy(() => import('./Construcciones/ViewerConstruccion'))
  },
  EdificiosSinRiesgo: {
    Titler: ({ feature }) => (
      <MunicipioParcelaTitler feature={{ rc_parcela: feature.localId }} />
    ),
    views: [
      EDIFICIO,
      {
        label: 'WS Catastro',
        view: 'WCF_CATASTRO',
        View: React.lazy(() => import('./Construcciones/VistaWebservicesCatastro'))
      },
      {
        label: 'Inspire BU',
        view: 'INSPIRE_BU',
        View: React.lazy(() => import('./Construcciones/VistaInspireBuildings'))
      }
    ],
    Viewer: React.lazy(() => import('./Construcciones/ViewerEdificioSinRiesgo'))
  },
  VnRiesgo: {
    Titler: MunicipioParcelaTitler,
    views,
    // Esto es provisional, y solo afecta de momento en <CustomModalViewer>
    viewKioskFilter: kioskoFilter,
    viewsFilter,
    MenuProvider: React.lazy(() => import('./VnRiesgo/ItemProviderRiesgo')),
    // Menu: () => 'Aquí estaría el menú (VnRiesgo)',
    Menu: React.lazy(() => import('./VnRiesgo/MenuRiesgo')),
    Viewer: React.lazy(() => import('./VnRiesgo/ViewerRiesgo'))
  }
  // NOTA @lorenzogrv: he cambiado la estrategia de uso
  /* ,
  Matrices: {
    Collection: React.lazy(() => import('./VmCenso/ListadoConstrucciones'))
  } */
}
