// vim:ts=2:sw=2:et

import WFS3Model from '@grupomarea/roo-www-abcs/model/AbstractWFS3Model'

export default class PuntoVolumen extends WFS3Model {
  static get project () { return 'CAOLIN' }
  static get primary () { return 'fid' } // en la vista, es recipiente:ogc_fid
  static get collection () { return 'punto_volumen' }

  constructor (dto = {}) {
    super(dto)

    // console.log('Crear VO', this.constructor.name, dto)

    this.fid = dto.fid || null
    this.ogc_fid = dto.ogc_fid || null // OJO (BigInt)
    this.id_recipiente = Number(dto.id_recipiente || null) || null
    this.uds_recipiente = Number(dto.uds_recipiente)
    this.vol_recipiente = Number(dto.vol_recipiente)
    this.id_residuo = Number(dto.id_residuo)
    this.estado = dto.estado
    this.ine_municipio = Number(dto.ine_municipio)
    this.slug_residuo = dto.slug_residuo
  }

  toGeoJSON () {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: this.geom
      },
      properties: {
        fid: this.fid,
        ogc_fid: `${this.ogc_fid}`,
        id_recipiente: this.id_recipiente,
        estado: this.estado,
        uds_recipiente: this.uds_recipiente,
        ine_municipio: this.ine_municipio,
        modified_user: this.modified_user
      }
    }
  }
}
