import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/material'

import { RooProvider } from '@grupomarea/roo-www-abcs'

import client from './services/query'

import theme from './theme'
import App from './App'
import init from './init'

import { HapunaBus } from './services/bus.js'

const bus = init(new HapunaBus())

/**
* Este componente debe estar en su propio fichero según recomienda
* la doc de parcel, para facilitar el hot reloading
*/
export default function Root () {
  /**
   * Ojo al dato: Elimino "map" como prop y estado,
   * para evitar un re-render completo cuando el mapa termina de inicializarse,
   * permitiendo que las vistas funcionen por su cuenta
   */

  // IMPORTANTE: Nunca comentes este console.info; importa mucho este render
  console.info('Render (g)root 🌳')

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '@keyframes mui-auto-fill': { from: { display: 'block' } },
          '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } }
        }}
      />
      <RooProvider bus={bus} queryClient={client}>
        <App bus={bus} />
      </RooProvider>
    </ThemeProvider>
  )
}
