import WFS3Model from '@grupomarea/roo-www-abcs/model/AbstractWFS3Model'

export default class MediaTrack extends WFS3Model {
  static get project () { return 'CAOLIN' }
  static get primary () { return 'fid_media' }
  static get collection () { return 'media_tracks' }

  constructor (dto = {}, geom = null) {
    super(dto, geom)

    this.url = dto.url
    this.estado = dto.estado
    this.stamp = dto.stamp
    this.is_av = dto.is_av
    this.is_360 = dto.is_360

    this.metadata = dto.metadata

    this.pathname = dto.pathname
    this.basename = dto.basename
    // Chapuza para no tocar la vista en bbdd
    this.protocol = dto.protocol || new URL(dto.url).protocol
  }

  toGeoJSON () {
    const primary = this.constructor.primary
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: this.geom
      },
      properties: {
        [primary]: this[primary],
        url: this.url,
        estado: this.estado,
        stamp: this.stamp,
        is_av: this.is_av,
        is_360: this.is_360,
        metadata: this.metadata,
        basename: this.basename,
        modified_user: this.modified_user
      }
    }
  }
}
