import PropTypes from 'prop-types'

import Avatar from '@mui/material/Avatar'

/**
 * IMPORTANTE: Prohibido añadir iconos aquí a lo loco
 *
 * Este set de iconos ha de mantenerse lo más pequeño posible, y disponer de su
 * propio naming (nuestro naming)
 */

// Actionable: Indican una "acción que se puede hacer"
export { default as CreateIcon } from '@mui/icons-material/Add'
export { default as RemoveIcon } from '@mui/icons-material/Remove'
// Marosa usa para RemoveIcon '@mui/icons-material/DeleteOutline'
export { default as ReloadIcon } from '@mui/icons-material/Replay'

// Feedback / State: Indican estado y/o proveen feedback sobre "qué es esto"
export { default as InfoIcon } from '@mui/icons-material/InfoOutlined'
export { default as DoneIcon } from '@mui/icons-material/Check'
export { default as PendingIcon } from '@mui/icons-material/PendingActions'
export { default as DocumentIcon } from '@mui/icons-material/DescriptionOutlined'

export function Icon ({ Icon, IconProps = {}, sx, ...etc }) {
  return (
    <Avatar
      sx={{
        display: 'inline-flex',
        bgcolor: 'transparent',
        color: 'text.primary',
        p: 1, // Este padding imita a un IconButton
        width: 28,
        height: 28,
        ...sx
      }}
      {...etc}
    >
      <Icon {...IconProps} />
    </Avatar>
  )
}

Icon.propTypes = {
  Icon: PropTypes.elementType
}
