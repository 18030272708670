// vim:ts=2:sw=2:et

export const MediaTitler = ({ feature: f }) => (
  <>
    {f.is_av ? 'Audiovisual del' : 'Recurso obtenido el'}
    {' '}
    {new Date(f.stamp).toLocaleString()}
    {f.is_360 ? ' en 360 grados' : ''}
  </>
)
