import { hexToRGBA } from '../color'
import GeometryType from '../geom/GeometryType'

import XML2JSONFormat, {
  keyvalObjectReducer,
  assertDTO,
  getDotted
} from './XML2JSONFormat'

// import { toSize } from 'ol/size'

// SLD Spec: Default size for Marks without Size should be 6 pixels.
const DEFAULT_MARK_SIZE = 6 // pixels
// SLD Spec: Default size for ExternalGraphic with an unknown native size,
// like SVG without dimensions, should be 16 pixels.
const DEFAULT_EXTERNALGRAPHIC_SIZE = 16 // pixels

/**
 * Esta clase permite parsear una respuesta XML conforme a lo
 * definido en el estándar Styled Layer Descriptor y Symbology Encoding
 *
 * Ver https://www.ogc.org/standards/sld
 * Ver https://www.ogc.org/standards/symbol
 *
 * Por el momento es un work in progress
 *
 * TODO fusilar https://github.com/NieuwlandGeo/SLDReader
 */

export default class SLD extends XML2JSONFormat {
  constructor (/* options = {} */) {
    super({ ROOT: 'StyledLayerDescriptor' })
    this.LAYERS = 'NamedLayer'
    this.SE_NAME = 'se:Name'
    this.LAYER_STYLE = 'UserStyle'
    this.FEATURE_STYLE = 'se:FeatureTypeStyle'
    this.FEATURE_STYLE_RULE = 'se:Rule'
  }

  read (source) {
    const dto = super.read(source)

    const layers = getDotted(dto, this.LAYERS)
    return layers
      ? (layers.map ? layers : [layers])
          .map(layerDTO => {
            const layer = this.readLayer(layerDTO)
            return { key: layer.name, val: layer.style }
          })
          .reduce(keyvalObjectReducer, {})
      : []
  }

  readLayer (dto) {
    assertDTO(dto)

    const style = dto[this.LAYER_STYLE]

    return {
      name: dto[this.SE_NAME],
      style: (
        style
          ? Array.isArray(style)
            ? style.map(dto => this.readStyle(dto))
            : [this.readStyle(style)]
          : []
      ),
      // .reduce(keyvalObjectReducer, {}),
      dto
    }
  }

  readStyle (dto) {
    assertDTO(dto)

    const style = dto[this.FEATURE_STYLE]

    if (Array.isArray(style) || !style) {
      console.error({ dto, style })
      throw new Error('wait here')
    }

    const rule = style[this.FEATURE_STYLE_RULE]
    const rules = Array.isArray(rule)
      ? rule.map(dto => this.readRule(dto))
      : [this.readRule(rule)]

    // if (rule.length > 2) console.warn({ dto, rule, rules })

    const icons = rules.flatMap(rule => rule.styles.filter(rule =>
      rule.type === 'Icon' ||
      rule.type === 'Circle' ||
      rule.type === 'RegularShape'
    ))

    return {
      name: dto[this.SE_NAME],
      iconSize: [
        Math.max(0, ...icons.map(
          rule => rule.type === 'Icon' ? rule.IconOpts.width : rule.size
        )),
        Math.max(0, ...icons.map(
          rule => rule.type === 'Icon' ? rule.IconOpts.height : rule.size
        ))
      ],
      rules
    }
  }

  readOgcFilter (dto) {
    assertDTO(dto)

    const descriptor = { ogc: dto }

    for (const key of Object.keys(dto)) {
      switch (key) {
        case '@xmlns:ogc': continue
        case 'ogc:Or':
        case 'ogc:Not': {
          const prop = key.slice(4).toLowerCase()
          descriptor[prop] = Object.entries(dto[key]).map(([key, val]) => {
            return this.readOgcFilter({ [key]: val })
          })
          continue
        }
        case 'ogc:PropertyIsEqualTo':
          descriptor.equality = {
            property: dto[key]['ogc:PropertyName'],
            value: dto[key]['ogc:Literal'] || ''
          }
          continue
        case 'ogc:PropertyIsNull':
          descriptor.isNull = {
            property: dto[key]['ogc:PropertyName']
          }
          continue
        case 'ogc:Function': {
          const functionName = dto[key]['@name']
          if (!functionName) {
            console.warn('Implementación incompleta de', key, dto[key])
            break
          }
          descriptor.functionName = functionName
          break
        }
        default:
          console.warn('OgcFilter sin implementar:', key, dto[key])
      }
    }

    return descriptor
  }

  readRule (dto) {
    assertDTO(dto)

    const descriptor = { styles: [] }

    if (dto[this.SE_NAME]) descriptor.name = dto[this.SE_NAME]

    for (const key of Object.keys(dto)) {
      // valores que se usan para debugging si falla el readNumber
      const debug = { dto, key, value: dto[key] }
      switch (key) {
        case this.SE_NAME: continue
        case 'ogc:Filter':
          descriptor.filter = this.readOgcFilter(dto[key])
          continue
        case 'se:ElseFilter':
          descriptor.filter = { fallback: true }
          continue
        case 'se:Description':
          descriptor.title = dto[key]['se:Title']
          // TODO comprobar bien el resto de keys
          continue
        case 'se:MaxScaleDenominator':
          descriptor.maxScale = this.readNumber(dto[key], debug)
          continue
        case 'se:MinScaleDenominator':
          descriptor.minScale = this.readNumber(dto[key], debug)
          continue
        case 'se:TextSymbolizer': {
          const {
            anchorX,
            anchorY,
            offsetX,
            offsetY,
            fill,
            font,
            stroke,
            textAlign,
            textBaseline,
            // pueden haber "se:VendorOption"
            ...etc
          } = this.readTextSymbolizer(dto[key])
          descriptor.styles.push({
            type: 'Text',
            anchor: [anchorX, anchorY],
            offset: [offsetX, offsetY],
            TextOpts: { fill, font, stroke, textAlign, textBaseline },
            ...etc
          })
          continue
        }
        case 'se:PointSymbolizer': {
          const {
            graphic: {
              size, opacity, displacement, mark, external, fallback, ...etc
            }
          } = this.readPointSymbolizer(dto[key])

          if (!external && !mark) break // esto avisa por console.warn

          if (Object.keys(etc).length) {
            console.warn('implementación incompleta de', key, etc)
          }

          if (external) {
            // console.log('OJO: Aquí se forzaba scale: 0.05')
            descriptor.styles.push({
              type: 'Icon',
              size: size || DEFAULT_EXTERNALGRAPHIC_SIZE,
              // TODO: Refactor ("params" lo usa el clusterizer)
              params: external.params,
              /* StyleOpts: {
                // OJO: Si lo especifico no renderiza el icono ¿porqué?
                geometry: GeometryType.POINT
              }, */
              // Estas son las "opts" que recibirá new Icon()
              // https://openlayers.org/en/latest/apidoc/module-ol_style_Icon.html
              IconOpts: {
                src: getParametricGraphicSource(external),
                width: size || DEFAULT_EXTERNALGRAPHIC_SIZE,
                height: size || DEFAULT_EXTERNALGRAPHIC_SIZE,
                // ojo: "size" es [width, height] y es para hacer "crop"
                // "offset" y "offsetOrigin" permiten hacer "crop" (sprites)
                opacity,
                // QGIS no devuelve el "anchor" aún en GetStyles
                // pero "displacement" sí (se:Displacement)
                displacement
              }
            })
          }

          if (mark) {
            const { fill, stroke } = mark
            const type = mark.type === 'circle' ? 'Circle' : 'RegularShape'
            const _size = size || DEFAULT_MARK_SIZE

            // https://openlayers.org/en/latest/apidoc/module-ol_style_Image.html
            const shapeOpts = {
              fill: { ...fill, color: hexToRGBA(fill.color, opacity) },
              // scale, // OJO: esto es un array
              stroke: { ...stroke, color: hexToRGBA(stroke.color, opacity) },
              opacity,
              displacement
            }
            switch (mark.type) {
              case 'circle':
                // El w/h del square que contiene un circle es su diámetro
                shapeOpts.radius = _size / 2
                break
              case 'star':
                shapeOpts.points = 5
                shapeOpts.radius = _size / 2
                shapeOpts.radius2 = _size / 3.3
                shapeOpts.angle = 0
                break
              case 'square':
                shapeOpts.points = 4
                shapeOpts.radius = size * 2 // SEGURO ??
                break
              default:
                console.error('RegularShape incompleto! →', mark.type)
            }

            descriptor.styles.push({
              type,
              size: _size,
              name: mark.type,
              [`${type}Opts`]: shapeOpts
            })
          }
          continue
        }
        case 'se:LineSymbolizer': {
          // TODO: refactor
          const { stroke } = this.readLineSymbolizer(dto[key])

          if (stroke) {
            descriptor.styles.push({
              type: 'Stroke',
              geom: GeometryType.LINE_STRING,
              StrokeOpts: stroke
            })
          }
          continue
        }
        case 'se:PolygonSymbolizer': {
          const {
            fill: { color, opacity, graphic: { external } = {}, ...etc },
            stroke,
            ...opts
          } = this.readPolygonSymbolizer(dto[key])

          if (Object.keys(opts).length || Object.keys(etc).length) {
            console.warn('REFACTORING', opts, etc)
          }

          if (external) {
            // Hay que usar un canvas para renderizarlo como imagen
            // → https://github.com/openlayers/openlayers/pull/4632#issuecomment-328328634
            descriptor.styles.push({
              type: 'Fill',
              geom: GeometryType.POLYGON,
              // Opciones que recibe el new Fill()
              FillOpts: {
                color: {
                  src: getParametricGraphicSource(external),
                  color: color ? hexToRGBA(color, opacity) : undefined
                  // Aquí también existen "size" y "offset" para sprites
                }
              }
            })
          } else {
            descriptor.styles.push({
              type: 'Fill',
              geom: GeometryType.POLYGON,
              FillOpts: { color: hexToRGBA(color, opacity) }
            })
          }

          if (stroke) {
            descriptor.styles.push({
              type: 'Stroke',
              geom: GeometryType.POLYGON,
              StrokeOpts: {
                color: hexToRGBA(stroke.color, stroke.opacity),
                width: stroke.width,
                lineJoin: stroke.lineJoin
              }
            })
          }
          continue
        }
        default:
          console.warn('se:Rule sin implementar:', key, dto[key])
      }
    }

    if (!descriptor.styles.length) {
      console.warn('se:Rule sin estilos!', { dto, descriptor })
    }

    return descriptor
  }

  readPointSymbolizer (dto) {
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:Graphic':
          descriptor.graphic = this.readGraphic(dto[key])
          continue
        default:
          descriptor[key.replace('se:', '').toLowerCase()] = dto[key]
          console.warn('se:PointSymbolizer sin implementar:', key, dto[key])
      }
    }
    return descriptor
  }

  readLineSymbolizer (dto) {
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:Stroke':
          descriptor.stroke = this.readStroke(dto[key])
          continue
        default:
          console.warn('se:LineSymbolizer sin implementar:', key, dto[key])
      }
    }
    return descriptor
  }

  // Ver https://openlayers.org/en/latest/apidoc/module-ol_style_Text-Text.html
  readTextSymbolizer (dto) {
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      const debug = { dto, key, value: dto[key] }
      switch (key) {
        case 'se:Fill':
          descriptor.fill = this.readFill(dto[key])
          continue
        case 'se:Font': {
          const { weight, size, family, ...etc } = this.readSvgParameters(
            getDotted(dto[key], 'se:SvgParameter'), 'font'
          )
          descriptor.font = (
            // font: weight + ' ' + size + '/' + height + ' ' + family
            // i.e.: "normal 10px / 1.4 'sans-serif'"
            // TODO por el momento no pude obtener un family diferente a
            // "Sans Serif" de qgis-server. Tampoco el weight o line-height
            `${weight || ''}` +
            ` ${typeof size !== 'number' ? 10 : size}px` +
            ' sans-serif' // ` '${family || 'Ubuntu'}','Roboto',sans-serif`
          )
          if (Object.keys(etc).length) {
            console.warn('Falta Font refactor', key, dto[key], etc)
          }
          continue
        }
        case 'se:Halo':
          descriptor.stroke = {
            width: getDotted(dto[key], 'se:Radius') * 1 + 1,
            opacity: 0.6, // back-compat (opacidad por defecto)
            ...this.readSvgParameters(
              getDotted(dto[key], 'se:Fill.se:SvgParameter'), 'fill'
            )
          }
          continue
        case 'se:Graphic':
          descriptor.graphic = this.readGraphic(dto[key])
          continue
        case 'se:Label':
          if (typeof dto[key] === 'string') {
            console.warn(key, 'expressions no implementadas:', dto[key])
            break // para mostrar otro warning en consola
          }
          descriptor.field = dto[key]['ogc:PropertyName']
          continue
        case 'se:LabelPlacement': {
          // OJO: Aquí falta implementar "line"
          descriptor.placementStrategy = 'point'
          const {
            point: {
              anchor: [anchorX, anchorY] = [0, 0],
              displacement: [offsetX, offsetY] = [0, 0]
            }
          } = this.readLabelPlacement(dto[key])
          descriptor.anchorX = anchorX
          descriptor.anchorY = anchorY
          descriptor.offsetX = offsetX
          descriptor.offsetY = offsetY
          switch (String(anchorX)) {
            case '0': // Anclar por la izquierda  (label a derecha del punto)
              descriptor.textAlign = 'left'
              break
            case '0.5': // Centro
              descriptor.textAlign = 'center'
              break
            case '1': // Anclar por la derecha (label a izquierda del punto)
              descriptor.textAlign = 'right'
              break
            default:
              descriptor.anchorX = 0.5
              descriptor.textAlign = 'center'
              console.warn(`Ancla horizontal desconocida: ${anchorX}`)
          }
          switch (String(anchorY)) {
            case '0': // Anclar por abajo (label por encima del punto)
              descriptor.textBaseline = 'bottom'
              break
            case '0.5': // Centro
              descriptor.textBaseline = 'middle'
              break
            case '1': // Anclar por arriba (label por debajo el punto)
              descriptor.textBaseline = 'top'
              break
            default:
              descriptor.anchorY = 0.5
              descriptor.textBaseline = 'middle'
              console.warn(`Ancla vertical desconocida: ${anchorY}`)
          }
          continue
        }
        case 'se:Priority':
          descriptor.priority = this.readNumber(dto[key], debug)
          continue
        case 'se:VendorOption': {
          for (const { '@name': option, '#text': value } of (
            Array.isArray(dto[key]) ? dto[key] : [dto[key]]
          )) {
            if (!option) {
              console.warn('Implemementación incompleta:', key, dto[key])
              break
            }
            descriptor[option] = value
            console.info('La opción', option, 'no afectará a los estilos')
          }
          continue
        }
      }
      console.warn('se:TextSymbolizer sin implementar:', key, dto[key])
    }
    return descriptor
  }

  readLabelPlacement (dto) {
    // Hay una buena guía de opciones en
    // https://docs.geoserver.org/2.25.x/en/user/styling/sld/reference/labeling.html
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:PointPlacement':
          descriptor.point = this.readPointPlacement(dto[key])
          continue
        // TODO: case 'se:LinePlacement'
        default:
          console.warn('readLabelPlacement sin implementar', key, dto[key])
      }
    }
    return descriptor
  }

  readPointPlacement (dto) {
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:AnchorPoint':
          descriptor.anchor = this.readAnchorPoint(dto[key])
          continue
        case 'se:Displacement':
          descriptor.displacement = this.readDisplacement(dto[key])
          continue
        default:
          console.warn('readPointPlacement sin implementar', key, dto[key])
      }
    }
    return descriptor
  }

  readAnchorPoint (dto) {
    assertDTO(dto)
    const descriptor = [0, 0]
    for (const key of Object.keys(dto)) {
      const debug = { dto, key, value: dto[key] }
      switch (key) {
        // anchorX y anchorY son [-1 / 0 / 1] e indican los cuadrantes
        case 'se:AnchorPointX':
          descriptor[0] = this.readNumber(dto[key], debug)
          continue
        case 'se:AnchorPointY':
          descriptor[1] = this.readNumber(dto[key], debug)
          continue
        default:
          console.warn('readPointPlacement sin implementar', key, dto[key])
      }
    }
    return descriptor
  }

  readPolygonSymbolizer (dto) {
    assertDTO(dto)
    const descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:Fill':
          descriptor.fill = this.readFill(dto[key])
          continue
        case 'se:Stroke':
          descriptor.stroke = this.readStroke(dto[key])
          continue
        // case 'se:Graphic': ??
        //   descriptor.graphic = this.readGraphic(dto[key])
        //   continue
        // TODO case 'se:GraphicFill':
        default:
          console.warn('se:PolygonSymbolizer sin implementar:', key, dto[key])
      }
    }
    assertDTO(dto)
    return descriptor
  }

  readNumber (value, debug) {
    const num = Number(value)
    if (isFinite(num)) return num
    debug && console.error({ DEBUG: debug })
    throw new Error(`Valor inválido (número no finito): '${num}'`)
  }

  readGraphic (dto) {
    assertDTO(dto)

    const descriptor = { size: null, opacity: 1, displacement: [0, 0] }

    for (const key of Object.keys(dto)) {
      // valores que se usan para debugging si falla el readNumber
      const debug = { dto, key, value: dto[key] }
      switch (key) {
        case 'se:Size':
          descriptor.size = this.readNumber(dto[key], debug)
          continue
        case 'se:Opacity':
          descriptor.opacity = this.readNumber(dto[key], debug)
          continue
        case 'se:Mark':
          descriptor.mark = this.readMark(dto[key])
          continue
        case '#comment': continue
        case 'se:ExternalGraphic':
          if (!Array.isArray(dto[key]) || dto[key].length !== 2) {
            console.warn(`readGraphic ${key}: implementación incompleta`)
            break
          }
          descriptor.external = this.readExternalGraphic(dto[key][0])
          descriptor.fallback = this.readExternalGraphic(dto[key][1])
          continue
        case 'se:Displacement':
          descriptor.displacement = this.readDisplacement(dto[key])
          // En QGIS el offset "Y" negativo "sube" y en Openlayers "baja"
          descriptor.displacement[1] *= -1
          continue
        // TODO se:Rotation, se:AnchorPoint
      }
      console.warn('readGraphic: implementación incompleta', key, dto[key])
    }

    if (descriptor.external && descriptor.mark) {
      // descriptor.markFallback = descriptor.mark
      delete descriptor.mark
    }

    return descriptor
  }

  readDisplacement (dto) {
    assertDTO(dto)
    const descriptor = [0, 0]
    for (const key of Object.keys(dto)) {
      // valores que se usan para debugging si falla el readNumber
      const debug = { dto, key, value: dto[key] }
      switch (key) {
        case 'se:DisplacementX':
          descriptor[0] = this.readNumber(dto[key], debug)
          continue
        case 'se:DisplacementY':
          descriptor[1] = this.readNumber(dto[key], debug)
          continue
        default:
          console.warn('readDisplacement sin implementar', key, dto[key])
      }
    }
    return descriptor
  }

  readMark (dto) {
    assertDTO(dto)

    const type = dto['se:WellKnownName']

    if (!WELL_KNOWN.includes(type)) {
      throw new Error(`Forma well-known desconocida: ${type}`)
    }

    const descriptor = { type }
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:WellKnownName': continue
        case 'se:Fill':
          descriptor.fill = this.readFill(dto[key])
          continue
        case 'se:Stroke':
          descriptor.stroke = this.readStroke(dto[key])
          continue
        default:
          console.warn('readMark sin implementar', key, dto[key])
      }
    }
    return descriptor
  }

  readFill (dto) {
    assertDTO(dto)
    let descriptor = {}
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:SvgParameter':
          descriptor = {
            ...descriptor, ...this.readSvgParameters(dto[key], 'fill')
          }
          continue
        case 'se:GraphicFill':
          // En caso que no sea así, saltará y avisará
          if (!dto[key]['se:Graphic']) break
          descriptor.graphic = this.readGraphic(dto[key]['se:Graphic'])
          continue
      }
      console.warn('readFill sin implementar', key, dto[key])
    }
    return descriptor
  }

  readStroke (dto) {
    assertDTO(dto)
    let descriptor = { color: '#000' } // se usará negro por defecto
    for (const key of Object.keys(dto)) {
      switch (key) {
        case 'se:SvgParameter':
          descriptor = {
            ...descriptor, ...this.readSvgParameters(dto[key], 'stroke')
          }
          continue
        default:
          console.warn('readStroke sin implementar', key, dto[key])
      }
    }
    if (descriptor.opacity) {
      // OpenLayers no tiene una propiedad "opacity" para Stroke,
      // hay que utilizar un RGBA
      descriptor.color = hexToRGBA(descriptor.color, descriptor.opacity)
      delete descriptor.opacity
    }
    return descriptor
  }

  readSvgParameters (dto, type) {
    return (
      Array.isArray(dto)
        ? dto.map(dto => this.readSvgParameter(dto, type))
        : [this.readSvgParameter(dto, type)]
    ).reduce(keyvalObjectReducer, {})
  }

  readSvgParameter (dto, type) {
    assertDTO(dto)

    let [key, val] = [dto['@name'], dto['#text']]

    switch (type) {
      case 'font':
        switch (key) {
          case 'font-family':
          case 'font-weight':
          case 'font-size':
            key = key.slice('font-'.length)
            if (key === 'size') val = Number(val)
            break
          default:
            console.warn({ type, key, val, dto })
            throw new Error(`Missing font implementation for ${key}`)
        }
        break
      case 'fill':
        switch (key) {
          case 'fill':
            key = 'color'
            break
          case 'fill-opacity':
            key = 'opacity'
            val = Number(val)
            break
          default:
            console.warn({ type, key, val, dto })
            throw new Error(`Missing fill implementation for ${key}`)
        }
        break
      case 'stroke':
        switch (key) {
          case 'stroke':
            key = 'color'
            // val = hexToRGBA(val)
            break
          case 'stroke-width':
            key = 'width'
            val = Number(val)
            break
          case 'stroke-linejoin':
            key = 'lineJoin'
            break
          case 'stroke-opacity':
            key = 'opacity'
            val = Number(val)
            break
          case 'stroke-linecap':
            key = 'lineCap'
            break
          // TODO lineDash (Array<Number>)
          // TODO lineDashOffset (Number)
          // TODO miterLimit (Number)
          default:
            console.warn({ type, key, val, dto })
            throw new Error(`Missing stroke implementation for ${key}`)
        }
        break
      default:
        console.warn({ type, key, val, dto })
        throw new Error(`Missing readSvgParameter implementation for ${type}`)
    }

    return { key, val }
  }

  readExternalGraphic (dto) {
    try {
      assertDTO(dto)
      const uri = new URL(dto['se:OnlineResource']['@xlink:href'])
      return {
        format: dto['se:Format'],
        uri,
        url: uri.toString().slice(0, -uri.search.length),
        params: Object.fromEntries(uri.searchParams.entries())
      }
    } catch (error) {
      console.error(error.message, { DEBUG: dto, problem: error })
      throw new Error('Descriptor de ExternalGraphic inválido')
    }
  }
}

// Estos son los valores válidos para los denominados Mark
const WELL_KNOWN = ['square', 'circle', 'triangle', 'star', 'cross', 'x']

/**
 * Utilidad para transformar un SLD de imagen parametrica en un data uri (base64)
 */
function getParametricGraphicSource ({ format, url, params }) {
  if (!url || url.slice(0, 7) !== 'base64:') {
    console.error({ DEBUG: { url, format, params } })
    throw new Error('Solo soporto imágenes incrustadas!')
  }
  // Parámetros substituídos
  // OJO: Puede especificar un valor por defecto
  const re = /param\(([a-z-]+)\)(?: ([a-z0-9#.]+))?/g
  return `data:${format};base64,${window.btoa(
    window.atob(url.slice(7)).replace(re, (match, param, defaultValue) => {
      return params[param] || defaultValue
    })
  )}`
}
