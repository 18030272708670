// vim:ts=2:sw=2:et

import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import Slide from '@mui/material/Slide'

import { ViewerContainer } from '@grupomarea/roo-www-abcs/UtilsLayout'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Modal ({
  // Las props que siguen son específicas de este FeatureViewer
  open,
  onClose,
  onTransitionEnd,
  dialogButtons, // botones de acción
  dialogTitle,
  sx,
  children,
  ...etc
}) {
  return (
    <Dialog
      scroll='body'
      maxWidth='xl'
      fullWidth
      open={open}
      // onClose={event, reason) => { reason !== 'backdropClick' && onClose() }
      sx={sx}
      keepMounted
      closeAfterTransition
      TransitionComponent={Transition}
      TransitionProps={{
        // mountOnEnter: true,
        // unmountOnExit: true,
        onExited: (node) => {
          onTransitionEnd && onTransitionEnd()
        }
      }}
      {...etc}
    >
      {dialogTitle && <ModalTitle>{dialogTitle}</ModalTitle>}
      <ViewerContainer
        maxWidth='xl'
      >
        {children}
      </ViewerContainer>
      <ViewerContainer maxWidth='xl'>
        <ModalButtons dialogButtons={dialogButtons} />
      </ViewerContainer>
    </Dialog>
  )
}

export function ModalTitle ({ children }) {
  return (
    <DialogTitle style={{ textAlign: 'center' }}>
      {children}
    </DialogTitle>
  )
}

export function ModalButtons ({ dialogButtons }) {
  return (
    <DialogActions style={{ textAlign: 'center' }}>
      {dialogButtons}
    </DialogActions>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTransitionEnd: PropTypes.func
}
