// vim:ts=2:sw=2:et

import React from 'react'

import { MediaTitler } from './MediaTracks/MediaComponents'

// No está "bien" importar modelos aquí, pero busco evitar un race condition
// entre la carga del menú digitalizar y la del feature viewer
import MediaTrackModel from './MediaTracks/model/MediaTrack'
import PuntoVolumen from '#caolin/PuntoRecollida/model/PuntoVolumen.js'

export {
  DefaultCollectionLayout as CollectionLayout
} from '@grupomarea/roo-www-abcs/CollectionView/DefaultComponents'

const MediaViewer = React.lazy(() => import('./MediaTracks/MediaViewer'))
const PtoWrap = React.lazy(() => import('./PuntoRecollida/VisorPuntoRecollidaResiduo'))
const PtoResiduo = (title) => ({ Titler: () => title, Viewer: PtoWrap, Model: PuntoVolumen })

/**
 * Índice de "featureTypes" del codename CAOLIN
 */
export default {
  Municipios: {
    Titler: ({ feature }) => feature.nombre,
    Viewer: React.lazy(() => import('./Territorio/MunicipioViewer.jsx'))
  },
  MunicipioPendiente: {
    Titler: ({ feature }) => `${feature.nombre} (INE ${feature.ine_code})`
    /* views: [
      EDIFICIO, COMUN_USO, RIESGO_1, RIESGO_2, RIESGO_3, RESUMEN
    ], */
    /* MenuProvider ({ feature, loading, children }) {
      return (
        <UsosProvider usos={feature.usos}>
          {children}
        </UsosProvider>
      )
    }, */
    // Menu: () => 'Aquí estaría el menú (Construcciones)',
    /* Menu: ({ view, feature }) => {
      const [uso, setUso] = useTipologia()
      return (
        <ViewerContainer sx={{ pt: 1, pb: 1 }}>
          <SelectorUso
            uso={uso}
            usos={feature.usos}
            disabled={view === EDIFICIO.view}
            onChange={event => setUso(event.target.value)}
          />
        </ViewerContainer>
      )
    }, */
    // Viewer: React.lazy(() => import('./Construcciones/ViewerConstruccion'))
  },
  MediaPoints: {
    Titler: MediaTitler,
    Viewer: MediaViewer
  },
  MediaTracks: {
    Titler: MediaTitler,
    Viewer: MediaViewer,
    Model: MediaTrackModel
  },
  PuntoRecollida: {
    Titler: ({ feature }) => 'Punto de recogida',
    Viewer: React.lazy(() => import('./PuntoRecollida/VisorPuntoRecollida'))
  },

  // Esto es estúpido y tiene que haber una manera mejor de hacerlo
  PuntoVolumenPc: PtoResiduo('Punto de recogida papel y cartón'),
  PuntoVolumenVi: PtoResiduo('Punto de recogida vidrio'),
  PuntoVolumenEl: PtoResiduo('Punto de recogida envases ligeros'),
  PuntoVolumenFr: PtoResiduo('Punto de recogida fracción resto'),
  PuntoVolumenFo: PtoResiduo('Punto de recogida fracción orgánica'),
  PuntoVolumenAc: PtoResiduo('Punto de recogida aceite de cocina'),
  PuntoVolumenRu: PtoResiduo('Punto de recogida ropa usada')
}

export const DigitizeMenu = React.lazy(() => import('./MenuDigitalizar'))
