import React from 'react'

import PropTypes from 'prop-types'
import RooTypes from '@grupomarea/roo-www-abcs/prop-types'

import { useBusGetter } from '@grupomarea/roo-www-abcs/bus-hooks'
import { useLocalStorage } from '@grupomarea/roo-www-abcs/lgrv-hooks'

/**
 * Este componente actúa como wraper de control para un viewer de feature que
 * necesita navegacíón basada en vistas.
 *
 * Únicamente provee el manejo del estado de la vista actual, e inyecta las
 * props en el "ControlledComponent" que se indique
 */
export default function FeatureViewController ({
  ControlledComponent,
  views,
  codename,
  details,
  bus,
  ...props
}) {
  const { map: project } = details

  const key = React.useMemo(() => {
    return `${project}:${codename}:view`
  }, [project, codename])

  const [view, setView] = useLocalStorage(key, views[0]?.view || null)

  const kioskMode = useBusGetter(bus, 'kioskMode')

  const handleChangeView = React.useCallback(({ next }) => {
    setView(next)
  }, [key, setView])

  const ViewComponent = React.useMemo(() => {
    return views.find(item => item.view === view)?.View
  }, [views, view])

  // console.log({ props, bus, view, views, codename, details })

  return (
    <ControlledComponent
      // feature={feature}
      // MenuComponent={MenuComponent}
      // TitleComponent={TitleComponent}
      // ViewerComponent={ViewerComponent}
      bus={bus}
      view={view}
      views={views.filter(view => !view.hidden)}
      codename={codename}
      handleChangeView={handleChangeView}
      ViewComponent={ViewComponent}
      details={details}
      kioskMode={kioskMode}
      {...props}
    />
  )
}

FeatureViewController.propTypes = {
  ControlledComponent: PropTypes.func.isRequired,
  views: PropTypes.array.isRequired,
  codename: PropTypes.string.isRequired,
  details: RooTypes.selectionDetailsOptionalFeature.isRequired,
  bus: PropTypes.object.isRequired
}
