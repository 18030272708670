import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'

/**
 * @const
 * Referencia a la librería proj4 y a ol/proj/proj4#register
 */
export { proj4, register }
export { get as getProjectionObject } from 'ol/proj'

/**
 * Accede a la información de SRC de https://epsg.io
 * @param code {string} código numérico del SRC (sin el prefijo 'EPSG:')
 * @returns {Promise<ProjectionDTO>}
 */
export async function fetchProjection (code) {
  return await window
    .fetch(`https://epsg.io/?format=json&q=${code}`)
    .then(response => response.json())
    .then(json => {
      if (json.number_result !== 1) {
        console.error(json)
        throw new Error(`Se ha encontrado más de un resultado para ${code}`)
      }

      const projection = json.results[0]
      const epsg = `EPSG:${code}`

      if (projection.code !== code) {
        console.error(epsg, projection)
        throw new ReferenceError('Los códigos no coinciden')
      }

      projection.epsgCode = epsg

      // console.info(code, projection)

      return projection
    })
}

/**
 * Descarga y define información de proyecciones en proj4.
 * Una vez descargadas y definidas las proyecciones, actualiza la referencia
 * registrada en OpenLayers. Si no se especifica al menos un código, lanza
 * un error.
 * @param codes {Array<string>} códigos de SRC a registrar (sin prefijo)
 * @returns {Promise<>}
 */
export async function registerProjections (codes = []) {
  if (!Array.isArray(codes) || !codes.length) {
    throw new TypeError('Se esperaba un array con al menos un código')
  }

  return await Promise
    .all(codes.map(code => fetchProjection(code)))
    .then(projections => {
      for (const proj of projections) {
        // console.log(proj.epsgCode, proj.proj4)
        proj4.defs(proj.epsgCode, proj.proj4)
      }

      // console.info('Registrar PROJ4')
      register(proj4)

      // Despues de muchos problemas para reproyectar, mejor no utilizar
      // for (const proj of projections) { ... }
      // projection.setWolrdExtent()
      // projection.setExtent()

      console.info('Registradas', projections.map(p => p.epsgCode))
    })
}
